var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "申请预付款",
        visible: _vm.dialogVisible,
        width: "580px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "prepayment-box" }, [
        _vm.canApply
          ? _c(
              "div",
              { staticClass: "prepayment-confirm" },
              [
                _c("h3", { staticClass: "text-primary" }, [
                  _vm._v("预付款职位提醒"),
                ]),
                _c("p", [
                  _vm._v("1、确定申请后，您有机会获得"),
                  _c("span", [_vm._v(_vm._s(_vm.prepaymentAmount))]),
                  _vm._v("元预付款。"),
                ]),
                _c("p", [_vm._v("2、猎必得将在3个工作日内支付给您")]),
                _c(
                  "a",
                  {
                    staticClass: "prepayment-rule",
                    attrs: { href: _vm.prepaymentRuleUrl, target: "_blank" },
                  },
                  [_vm._v("阅读详细规则")]
                ),
                _c(
                  "el-checkbox",
                  {
                    staticClass: "prepayment-check",
                    model: {
                      value: _vm.confirmed,
                      callback: function ($$v) {
                        _vm.confirmed = $$v
                      },
                      expression: "confirmed",
                    },
                  },
                  [_vm._v("我已阅读规则，并愿意申请")]
                ),
              ],
              1
            )
          : _c("div", { staticClass: "prepayment-alert" }, [
              _c(
                "h3",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cannotReason,
                      expression: "cannotReason",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.cannotReason))]
              ),
              _c(
                "h2",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isSucceeded,
                      expression: "isSucceeded",
                    },
                  ],
                  staticClass: "prepayment-success",
                },
                [_c("i", { staticClass: "icon-success" }), _vm._v("已提交申请")]
              ),
              _c("p", [_vm._v("您可以：")]),
              _c("p", [
                _vm._v("1.了解"),
                _c(
                  "a",
                  { attrs: { href: _vm.prepaymentRuleUrl, target: "_blank" } },
                  [_vm._v("预付款规则")]
                ),
              ]),
              !_vm.permissionDenied
                ? _c("p", [
                    _vm._v("2.查看"),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.prepaymentJobsUrl,
                          target: "_blank",
                        },
                      },
                      [_vm._v("其他预付款职位")]
                    ),
                  ])
                : _vm._e(),
            ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("不申请")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v(_vm._s(_vm.canApply ? "确定申请" : "我知道了"))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }