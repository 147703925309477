<template>
    <div class="resume-info" v-loading="loading">
        <!-- <div class="resume-info-title"><font-icon href="#icon-baocun" class="candidate-icon"></font-icon>简历详情</div> -->
        <div class="resume-info-content">
            <!-- <span class="is-fold-btn" @click="handleClikeIsFold">
                <span v-if="!unFold">
                    <i class="unfold">展开</i>
                    <i class="el-icon-d-arrow-right"></i>
                </span>
                <span v-else>
                    <i class="fold">收起</i>
                    <i class="el-icon-d-arrow-left"></i>
                </span>
            </span> -->


            <div class="resume-head">
                <div class="resume-row">
                    <div class="rr-l">
                        <span class="name ell" :title="resumeDetailInfo.realName">{{ resumeDetailInfo.realName }}</span>
                    </div>
                    <div class="rr-r">
                        <span class="back ell" :title="resumeDetailInfo.school">{{ resumeDetailInfo.school }}</span>
                        <span class="back ell" :title="resumeDetailInfo.major">{{ resumeDetailInfo.major }}</span>
                        <span class="back ell">{{ resumeDetailInfo.degree | getDegreeTextById }}</span>
                        <span class="light" v-if="firstEducationExperiences.startDate != '0001-01-01T00:00:00'">{{firstEducationExperiences.startDate | formatDateWithDotMonth}}-{{firstEducationExperiences.endDate | formatDateWithDotMonth}}</span>
                    </div>
                </div>
                <div class="resume-row">
                    <div class="rr-l">
                        <span class="light">
                            <span 
                                class="spacer">{{ resumeDetailInfo.gender == '1' ? '男' : (resumeDetailInfo.gender == '2' ? '女' : '未知') }}</span>
                            <span class="spacer" title="年龄">{{ resumeDetailInfo.birthday | birthdayToAge }}</span>
                            <span class="spacer" title="工作经验">{{ resumeDetailInfo.yearOfExperience }}年</span>
                            {{ resumeDetailInfo.provinceName }}
                        </span>
                    </div>
                    <div class="rr-r">  
                        <span class="back ell" :title="resumeDetailInfo.company">{{ resumeDetailInfo.company }}</span>
                        <span class="back ell" :title="resumeDetailInfo.title">{{ resumeDetailInfo.title }}</span>
                        <span class="light"  v-if="firstWorkExperiences.startDate != '0001-01-01T00:00:00'">{{ firstWorkExperiences.startDate | formatDateWithDotMonth }}-{{ firstWorkExperiences.endDate | formatDateWithDotMonth }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="candidate-base-info">
                <div class="headline-base">
                    <span class="name">{{ resumeDetailInfo.realName }}</span>
                    <span>
                        <span>{{ resumeDetailInfo.gender == '1' ? '男' : (resumeDetailInfo.gender == '2' ? '女' : '未知') }}</span>
                        <span class="line">|</span>
                        <span>{{ resumeDetailInfo.yearOfExperience }} 年</span>
                        <span class="line">|</span>
                        <span>{{ resumeDetailInfo.birthday | birthdayToAge }}</span>
                        <span class="line">|</span>
                        <span>{{ resumeDetailInfo.provinceName }}</span>
                    </span>
                </div>
                <div class="headline-info">
                    <p class="school"><i class="headline-title">学校：</i>{{ resumeDetailInfo.school }}</p>
                    <p><i class="headline-title">学历：</i>{{ resumeDetailInfo.degree | getDegreeTextById }}</p>
                    <p class="company"><i class="headline-title">公司：</i>{{ resumeDetailInfo.company }}</p>
                    <p class="current-title"><i class="headline-title">职位：</i>{{ resumeDetailInfo.title }}</p>
                </div>
            </div> -->
            <!-- v-show="unFold" -->
            <div class="resume-wrapper" v-if="unFold">
                <div class="module-main" v-if="workExperiences.length > 0">
                    <div class="module-bar">工作经历</div>
                    <div class="module-item" v-for="item in workExperiences" :key="item.id">
                        <div class="item-header">
                            <p>
                                <span class="header-text" v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">
                                    <i>{{ item.startDate | formatDateWithDotMonth }}</i>
                                    <i>-</i>
                                    <i>{{ item.endDate | formatDateWithDotMonth }}</i>
                                </span>
                                <span class="header-text">{{ item.company }}</span>
                                <span class="header-text work-time" v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">{{ item.startDate + '|' + item.endDate | getMonthDiff }}</span>
                            </p>
                            <p>
                                <span class="header-text">{{ item.title }}</span>
                                <span class="header-text">{{ item.location | getCityTextById }}</span>
                            </p>
                        </div>
                        <div class="item-para">
                            <span class="des-title">工作描述：</span>
                            <span class="text" v-html="item.description"></span>
                        </div>
                    </div>
                </div>
                <div class="module-main" v-if="projectExperiences.length > 0">
                    <div class="module-bar">项目经历</div>
                    <div class="module-item" v-for="item in projectExperiences" :key="item.id">
                        <div class="item-header">
                            <p>
                                <span class="header-text" v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">
                                    <i>{{ item.startDate | formatDateWithDotMonth }}</i>
                                    <i>-</i>
                                    <i>{{ item.endDate | formatDateWithDotMonth }}</i>
                                </span>
                                <span class="header-text">{{ item.projectName }}</span>
                            </p>
                            <p>
                                <span class="header-text">项目角色：{{ item.projectRole }}</span>
                            </p>
                        </div>
                        <div class="item-para">
                            <span class="des-title">项目描述：</span>
                            <span class="text" v-html="item.description"></span>
                        </div>
                        <div class="item-para">
                            <span class="des-title">项目职责：</span>
                            <span class="text" v-html="item.responsibility"></span>
                        </div>
                    </div>
                </div>
                <div class="module-main" v-if="educationExperiences.length > 0">
                    <div class="module-bar">教育经历</div>
                    <div class="module-item" v-for="item in educationExperiences" :key="item.id">
                        <div class="item-header">
                            <p>
                                <span class="header-text" v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">
                                    <i>{{ item.startDate | formatDateWithDotMonth }}</i>
                                    <i>-</i>
                                    <i>{{ item.endDate | formatDateWithDotMonth }}</i>
                                </span>
                                <span class="header-text">{{ item.school }}</span>
                                <span class="header-text work-time" v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">{{ item.startDate + '|' + item.endDate | getMonthDiff }}</span>
                            </p>
                            <p>
                                <span class="header-text">{{ item.major }}</span>
                                <span class="header-text">{{ item.degree | getDegreeTextById }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="module-main" v-if="selfEvaluation">
                    <div class="module-bar">自我评价</div>
                    <div class="module-item">
                        <div class="item-para self-evaluation" v-html="selfEvaluation"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { candidate as candidateConfig } from '#/js/config/api.json';
import { getDegreeTextById } from '#/js/filters/degreeInfo.js';
import { getCityTextById } from '#/js/util/provinceCity.js';

export default {
    props: {
        resumeId: String
    },

    data() {
        return {
            unFold: false,
            loading: false,
            resumeDetailInfo: {},
            workExperiences: [],
            educationExperiences: [],
            projectExperiences: [],
            selfEvaluation: '',
        };
    },

    filters: {
        birthdayToAge(birthday) {
            if(birthday) {
                return ((new Date).getFullYear() - birthday?.slice(0, 4)) + '岁';
            } else {
                return '未知';
            }
        },
        getCityTextById(id) {
            return getCityTextById(id);
        }
    },

    watch: {
        resumeId(value) {
            this.getResumeInfo();
        }
    },

    computed: {
        firstWorkExperiences() {
            return this.workExperiences[0] || {};
        },
        firstEducationExperiences() {
            return this.educationExperiences[0] || {};
        },
    },

    mounted() {
        
    },

    methods: {
        handleClikeIsFold() {
            this.unFold = !this.unFold;
        },
        getResumeInfo() {
            this.loading = true;
            _request({
                url: candidateConfig.url_candidateDetail.replace('%p', this.resumeId),
                method: 'GET',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
            }).then((res) => {
                // console.log(res);
                this.resumeDetailInfo = res;
                this.workExperiences = res.workExperiences;
                this.projectExperiences = res.projectExperiences;
                this.educationExperiences = res.educationExperiences;
                this.selfEvaluation = res.selfEvaluation;
                this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.resume-info {
    margin-bottom: 20px;
    min-height: 50px;
    .resume-info-title {
        margin-bottom: 15px;
        color: #444;
    }
    .resume-info-content {
        // padding: 25px 12px;
        // border: 1px solid #EEEEEE;
        // position: relative;
        .is-fold-btn {
            cursor: pointer;
            position: absolute;
            right: 12px;
            top: 12px;
            color: #38BC9D;
        }

        .resume-head{
            .resume-row{
                display: flex;
                .rr-l{
                    width: 252px;
                    min-width: 252px;
                }
                .rr-r{
                    flex: 1;
                    display: inline-flex;
                    align-items: center;
                }
                .name{
                    font-size: 16px;
                    font-weight: 600;
                    color: #333;
                    line-height: 20px;
                    display: inline-block;
                    max-width: 200px;
                }
                .back{
                    font-size: 14px;
                    font-weight: 500;
                    color: #333;
                    line-height: 20px;
                    margin-right: 6px;
                    display: inline-block;
                    max-width: 140px;
                }
                .light{
                    font-size: 14px;
                    color: #999;
                }
                .spacer{
                    &::after{
                        display: inline-block;
                        width: 1px;
                        height: 15px;
                        background: #DCDCDC;
                        content: '';
                        margin: 0 4px;
                        vertical-align: -2px;
                    }
                    
                }

            }

        }

        .candidate-base-info {
            color: #444;
            .headline-base {
                margin-bottom: 25px;
                .name {
                    margin-right: 20px;
                    font-size: 18px;
                    font-weight: bold;
                    color: #434343;
                }
                .line {
                    margin: 0 5px;
                }
            }
            .headline-info {
                display: flex;
                color: #000;
                p {
                    margin: 0 50px 0 0;
                }
                .headline-title {
                    color: #666666;
                }
            }
        }

        .resume-wrapper {
            .module-main {
                box-sizing: border-box;
                padding: 20px 0;
                .module-bar {
                    margin-bottom: 10px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #444;
                }
                .module-item {
                    // width: 80%;
                    color: #333;
                    font-size: 14px;
                    margin-bottom: 40px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .item-header {
                        p {
                            line-height: 30px;
                            margin-bottom: 0;
                            position: relative;
                            .header-text {
                                margin-right: 30px;
                                display: inline-block;
                                max-width: 300px;
                                vertical-align: middle;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                    .item-para {
                        padding: 5px 0;
                        word-wrap: break-word;
                        font-size: 14px;
                        line-height: 22px;
                        display: flex;

                        .des-title {
                            white-space: nowrap;
                            display: inline-block;
                        }

                        .text {
                            flex: 1;
                            white-space: pre-line;
                        }

                        &.self-evaluation {
                            display: block;
                        }
                    }
                }
                .expect-item {
                    padding: 5px 0;
                    color: #333;
                    font-size: 14px;
                    .line {
                        margin: 0 20px;
                    }
                }
            }
        }
    }
}
</style>