<template>
  <div class="detail-item-header" :class="hasBottomBorder ? 'header-bottom-border' : ''">
      <span class="header-title">
        <span class="text-primary" v-if="isOrderHeader">
            {{headerDetail}}
        </span>
        {{title}}
        <span v-if="headerDetail >= 0 && !isOrderHeader">
            ({{headerDetail}})
        </span>
      </span>
      <span class="header-slot">
        <slot></slot>
      </span>
  </div>
</template>

<script>
export default {
    props: {
        title: String,
        hasBottomBorder: {
            type: Boolean,
            default: true
        },
        isOrderHeader: {
            type: Boolean,
            default: false
        },
        headerDetail: [Number, String]
    }
}
</script>

<style lang="scss" scope>
    .detail-item-header {
        font-size: 18px;
        color: #333;
        // padding: 10px 0;
        padding: 10px 28px 10px 0;
        display: flex;
        justify-content: space-between;
        &.header-bottom-border {
            border-bottom: 1px solid #ececec;
        }
        .header-title {
            &::before {
                content: " ";
                display: inline-block;
                border-left: 8px solid #38bc9d;
                height: 20px;
                margin-right: 5px;
                vertical-align: middle;
            }
        }
    }
</style>