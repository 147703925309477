var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "media-item",
    },
    [
      _c(
        "div",
        { staticClass: "media-header" },
        [
          _c("avatar", {
            staticClass: "publisher-avatar",
            attrs: {
              size: "md",
              src: _vm.avatarUrl,
              userId: _vm.item.userId,
              enableCard: true,
              enableLink: true,
              shareData: _vm.shareData,
            },
          }),
          _c("div", { staticClass: "media-header-middle" }, [
            _c("span", { staticClass: "publisher-name" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.item.realName) +
                  "\n                "
              ),
              _c("span", { staticClass: "publisher-nick-name" }, [
                _vm._v("@" + _vm._s(_vm.item.nickName)),
              ]),
            ]),
            _c("span", { staticClass: "media-description" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.description) +
                  "\n            "
              ),
            ]),
          ]),
          _c("div", { staticClass: "media-header-right" }, [
            _c("span", { staticClass: "media-date" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("dateFilter")(_vm.item.created)) +
                  "\n            "
              ),
            ]),
            _c("p", { staticClass: "media-header-right-btns" }, [
              _vm.canDelete && _vm.checkIfIsIpad(_vm.item.deviceType)
                ? _c(
                    "span",
                    {
                      staticClass: "rotate-btn",
                      attrs: { title: "视频顺时针旋转90度" },
                      on: {
                        click: function ($event) {
                          return _vm.rotateVideo(_vm.item)
                        },
                      },
                    },
                    [
                      _c("font-icon", {
                        attrs: { href: "#icon-rotate-right" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canDelete
                ? _c(
                    "span",
                    {
                      staticClass: "text-operate-btn grey",
                      on: { click: _vm.deleteAudioVideo },
                    },
                    [
                      _c("i", { staticClass: "el-icon-close" }),
                      _vm._v("\n                    删除\n                "),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "media-content",
          class: [
            _vm.item.rotationAngle >= 0
              ? `rotate-${_vm.item.rotationAngle}`
              : "",
            _vm.item.type === 1 ? "video-content-container" : "",
            _vm.item.type === 0 ? "audio-content-container" : "",
          ],
        },
        [
          _vm.item.type === 1
            ? [
                _c("video", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.item.fileId && !_vm.isNotReady,
                      expression: "item.fileId && !isNotReady",
                    },
                  ],
                  staticClass: "video-content",
                  attrs: {
                    id: _vm.jobVideoPlayerId,
                    preload: "auto",
                    playsinline: "",
                    "webkit-playsinline": "",
                  },
                }),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.item.fileId && !_vm.isNotReady,
                      expression: "item.fileId && !isNotReady",
                    },
                  ],
                  staticClass: "job-video-player-logo",
                  domProps: { innerHTML: _vm._s(_vm.userName) },
                }),
                !_vm.item.fileId || _vm.isNotReady
                  ? _c("div", { staticClass: "video-content-modal" }, [
                      _vm._m(0),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.item.type === 0
            ? [
                _vm.item.mediaUrl.indexOf("&sign") > -1
                  ? _c("record-listen", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.item.fileId && !_vm.isNotReady,
                          expression: "item.fileId && !isNotReady",
                        },
                      ],
                      staticClass: "audio-content",
                      attrs: {
                        "audio-url": _vm.item.mediaUrl,
                        duration: _vm.duration,
                      },
                    })
                  : _vm._e(),
                !_vm.item.fileId || _vm.isNotReady
                  ? _c("div", { staticClass: "audio-content-modal" }, [
                      _c("span", { staticClass: "audio-loading-tip" }, [
                        _vm._v("音频加载中，请稍候...."),
                      ]),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "video-loading-tip" }, [
      _vm._v("视频加载中"),
      _c("br"),
      _vm._v("请稍候...."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }