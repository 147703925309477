import { render, staticRenderFns } from "./job-detail-content.vue?vue&type=template&id=6d8aea04&"
import script from "./job-detail-content.vue?vue&type=script&lang=js&"
export * from "./job-detail-content.vue?vue&type=script&lang=js&"
import style0 from "./job-detail-content.vue?vue&type=style&index=0&id=6d8aea04&lang=scss&scope=true&"
import style1 from "./job-detail-content.vue?vue&type=style&index=1&id=6d8aea04&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d8aea04')) {
      api.createRecord('6d8aea04', component.options)
    } else {
      api.reload('6d8aea04', component.options)
    }
    module.hot.accept("./job-detail-content.vue?vue&type=template&id=6d8aea04&", function () {
      api.rerender('6d8aea04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/job-detail/layout/job-detail-content.vue"
export default component.exports