var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "job-detail",
    },
    [
      _c("job-head", {
        ref: "jobHead",
        attrs: {
          currentJobType: _vm.curJobType,
          "job-info": _vm.jobInfo,
          "share-data": _vm.shareData,
          canMediaRecord: _vm.canMediaRecord,
          isFromAuthorization: _vm.jobInfo.isFromAuthorization,
        },
        on: {
          refresh: _vm.getJobInfo,
          "record-media-success": _vm.recordMediaSuccess,
        },
      }),
      _c("job-content", {
        ref: "jobContent",
        attrs: {
          currentJobType: _vm.curJobType,
          "job-info": _vm.jobInfo,
          "share-data": _vm.shareData,
          onlineList: _vm.onlineMap,
          canMediaList: _vm.canMediaList,
          isFromAuthorization: _vm.jobInfo.isFromAuthorization,
        },
        on: { refresh: _vm.getJobInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }