<template>
    <el-dialog
        title="申请预付款"
        :visible.sync="dialogVisible"
        width="580px"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="prepayment-box">
            <div v-if="canApply" class="prepayment-confirm">
                <h3 class="text-primary">预付款职位提醒</h3>
                <p>1、确定申请后，您有机会获得<span>{{prepaymentAmount}}</span>元预付款。</p>
                <p>2、猎必得将在3个工作日内支付给您</p>
                <a class="prepayment-rule" :href="prepaymentRuleUrl" target="_blank">阅读详细规则</a>
                <el-checkbox class="prepayment-check" v-model="confirmed">我已阅读规则，并愿意申请</el-checkbox>
            </div>
            <div v-else class="prepayment-alert">
                <h3 v-show="cannotReason">{{cannotReason}}</h3>
                <h2 class="prepayment-success" v-show="isSucceeded"><i class="icon-success"></i>已提交申请</h2>
                <p>您可以：</p>
                <p>1.了解<a :href="prepaymentRuleUrl" target="_blank">预付款规则</a></p>
                <p v-if="!permissionDenied">2.查看<a :href="prepaymentJobsUrl" target="_blank">其他预付款职位</a></p>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible=false">不申请</el-button>
            <el-button type="primary" @click="onConfirm">{{canApply ? '确定申请' : '我知道了'}}</el-button>
        </span>
    </el-dialog>

    

</template>
<script>
    import { prepayment as prepaymentUrl } from '#/js/config/api.json';
    const PREPAYMENT_RULE_URL = "http://zone.liebide.com/?p=1492",
        PREPAYMENT_JOBS_URL = "/MarketActivity/Jobs/Special/Prepayment",
        ERR_NOT_CONFIRMED = '请确定已阅读规则并愿意申请！';
    export default {
        name: "prepaymentDialog",
        data(){
            return{
                dialogVisible: false,
                prepaymentRuleUrl: PREPAYMENT_RULE_URL,
                prepaymentJobsUrl: PREPAYMENT_JOBS_URL,
                confirmed: false,
                canApply: true,
                isSucceeded: false,
                cannotReason: '',
                hasApplied: false,
                permissionDenied: false,
                confirmed:false,
                loading:false,
            }
        },
        props:{
            prepaymentAmount:{
                type:Number,
                default:0
            },
            jobId:String
        },
        computed:{
            token() {
                return this.$store.state.verificationToken.verificationToken;
            },
        },
        methods:{
            show(){
                this.dialogVisible = true;
            },
            onConfirm() {
                if(this.canApply) {
                    if(!this.confirmed) {
                        shortTips(ERR_NOT_CONFIRMED);
                    } else {
                        this.loading = true;
                        _request({
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            url: prepaymentUrl.apply_prepayment_url.replace(/\%p/ig, this.jobId),
                            data: {
                                __RequestVerificationToken: this.token
                            },
                            throwBusinessError: true
                        }).then(res => {
                            this.hasApplied = true;
                            this.isSucceeded = true;
                            this.$emit('prepayment-success');
                            this.dialogVisible = false;
                            this.loading = false;
                        }).catch(err => {
                            this.cannotReason = err.message;
                            shortTips(err.message)
                            this.loading = false;
                        })
                    }
                } else {
                    this.dialogVisible = false;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.prepayment-confirm {
	h3 {
		font-size: 16px;
	}

	p {
		line-height: 30px;
		margin-bottom: 0;
	}

	.prepayment-rule {
		font-size: 12px;
	}

	.prepayment-check {
		display: block;
		margin-top: 5px;
		font-size: 12px;
	}
}

.prepayment-alert {
	h3 {
		font-size: 18px;
		margin-bottom: 3px;
	}

	p {
		line-height: 25px;
		margin-bottom: 0;
    }
    
    .icon-success {
        display: inline-block;
        width: 62px;
        height: 62px;
        margin-right: 15px;
        background-image: url('~@src/assets/images/icon/success.png');
        vertical-align: middle;
    }

    .prepayment-success {
        font-size: 24px;
        color: #333;
        line-height: 62px;
        margin-bottom: 3px;
    }
}
</style>