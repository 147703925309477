var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-recommend-record" },
    [
      _vm.isAgreementJob
        ? _c("el-alert", {
            staticStyle: { "margin-top": "8px" },
            attrs: {
              title: `当前显示 ${_vm.pager.total} 条`,
              closable: false,
              type: "error",
            },
          })
        : _vm._e(),
      _vm.jobRecommendList.length == 0
        ? _c("empty")
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _vm._l(_vm.jobRecommendList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "item",
                    on: {
                      click: function ($event) {
                        return _vm.handleClick(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "hr-info" }, [
                      _c(
                        "div",
                        { staticClass: "pic" },
                        [
                          _c("avatar", {
                            attrs: {
                              src: item.headhunterAvatarUrl,
                              userId: item.headhunterId,
                              alt: item.headhunterRealName,
                              size: "sm",
                              shareData: _vm.shareData,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "info" }, [
                        _c(
                          "p",
                          {
                            staticClass: "ell",
                            attrs: {
                              title: `${item.headhunterRealName} ${item.headhunterNickname}`,
                            },
                          },
                          [
                            _c("span", { staticClass: "realname" }, [
                              _vm._v(_vm._s(item.headhunterRealName)),
                            ]),
                            _c("span", { staticClass: "nikename" }, [
                              _vm._v("@" + _vm._s(item.headhunterNickname)),
                            ]),
                          ]
                        ),
                        _c("p", [_vm._v(_vm._s(item.recommendDate) + " 推荐")]),
                      ]),
                    ]),
                    _c("div", { staticClass: "cand-info" }, [
                      _c(
                        "span",
                        {
                          staticClass: "name ell",
                          attrs: { title: item.realName },
                        },
                        [_vm._v(_vm._s(item.realName))]
                      ),
                      _c(
                        "span",
                        { staticClass: "age", attrs: { title: "年龄" } },
                        [
                          _vm._v(
                            _vm._s(_vm._f("getAgeFromBirthday")(item.birthday))
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "school ell",
                          attrs: { title: item.school },
                        },
                        [_vm._v(_vm._s(item.school))]
                      ),
                      _c("span", { staticClass: "degree ell" }, [
                        _vm._v(
                          _vm._s(_vm._f("getDegreeTextById")(item.degree))
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "recommend-status" },
                      [
                        _c("recommendation-status-tag", {
                          attrs: {
                            interviewConfirmedStatus:
                              item.interviewConfirmedStatus,
                            href: item.recommendationUrl,
                            label: item.recommendStatus,
                            flowStepName: item.flowStepName,
                          },
                        }),
                        _c(
                          "span",
                          { staticClass: "arrow-icon" },
                          [
                            _c("font-icon", {
                              attrs: { href: "#icon-right-copy" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm.pager.total > _vm.pager.take
                ? _c(
                    "el-pagination",
                    {
                      staticClass: "job-list-pagination",
                      attrs: {
                        "current-page": _vm.pager.current,
                        "page-sizes": [10, 30, 50],
                        "page-size": _vm.pager.take,
                        layout: "total, sizes, prev, pager, next, slot",
                        total: _vm.pager.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    },
                    [
                      _c("span", { staticClass: "pagination-text" }, [
                        _c(
                          "span",
                          [
                            _vm._v(
                              "\n                    前往\n                    "
                            ),
                            _c("el-input", {
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.handlePagerJump(_vm.pagerJump)
                                },
                              },
                              model: {
                                value: _vm.pagerJump,
                                callback: function ($$v) {
                                  _vm.pagerJump = $$v
                                },
                                expression: "pagerJump",
                              },
                            }),
                            _vm._v(
                              "\n                    页\n                "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handlePagerJump(_vm.pagerJump)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    跳转\n                "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }