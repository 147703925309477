<template>
    <div class="job-recommend-record">
        <el-alert
            v-if="isAgreementJob"
            :title="`当前显示 ${pager.total} 条`"
            :closable="false"
            style="margin-top: 8px;"
            type="error">
        </el-alert>
        <empty 
            v-if="jobRecommendList.length == 0">
        </empty>

        <div v-loading="loading" v-else>
            <div 
                class="item" 
                v-for="item in jobRecommendList" 
                :key="item.id"
                @click="handleClick(item)">
                
                <div class="hr-info">
                    <div class="pic">
                        <avatar
                            :src="item.headhunterAvatarUrl"
                            :userId="item.headhunterId"
                            :alt="item.headhunterRealName"
                            size="sm"
                            :shareData="shareData"
                        ></avatar>
                    </div>
                    <div class="info">
                        <p class="ell" :title="`${item.headhunterRealName} ${item.headhunterNickname}`">
                            <span class="realname">{{item.headhunterRealName}}</span>
                            <span class="nikename">@{{item.headhunterNickname}}</span>
                        </p>
                        <p>{{item.recommendDate}} 推荐</p>
                    </div>
                </div>
                <div class="cand-info">
                    <span class="name ell" :title="item.realName">{{item.realName}}</span>
                    <span class="age" title="年龄">{{ item.birthday | getAgeFromBirthday }}</span>
                    <span class="school ell" :title="item.school">{{ item.school }}</span>
                    <span class="degree ell">{{ item.degree | getDegreeTextById }}</span>
                </div>
                <div class="recommend-status">
                    <recommendation-status-tag 
                        :interviewConfirmedStatus="item.interviewConfirmedStatus" 
                        :href="item.recommendationUrl" 
                        :label="item.recommendStatus"
                        :flowStepName="item.flowStepName"
                    ></recommendation-status-tag>

                    <span class="arrow-icon">
                        <font-icon
                            href="#icon-right-copy"
                        ></font-icon>
                    </span>
                </div>
                <!-- <div class="referrer">
                    <span class="name" @click="handleClick(item)">{{ item.realName }}</span>
                </div>
                <div class="recommend-time">
                    <avatar
                        :src="item.headhunterAvatarUrl"
                        :userId="item.headhunterId"
                        :alt="item.headhunterRealName"
                        size="sm"
                        :shareData="shareData"
                    ></avatar>
                    <div class="time">{{ item.recommendDate }}推荐</div>
                </div>
                <div class="recommend-status">
                    <div class="status-container">
                        <div class="job-panel">
                            <recommendation-status-tag 
                                :interviewConfirmedStatus="item.interviewConfirmedStatus" 
                                :href="item.recommendationUrl" 
                                :label="item.recommendStatus"
                                :flowStepName="item.flowStepName"
                            ></recommendation-status-tag>
                            <span v-if="item.commentsCount" class="comment-count">
                                <i class="fa fa-comment"></i> <span>{{item.commentsCount}}</span>
                            </span>
                        </div>
                    </div>
                </div> -->
            </div>

            <el-pagination
                v-if="pager.total > pager.take"
                :current-page="pager.current" 
                :page-sizes="[10, 30, 50]"
                :page-size="pager.take"
                class="job-list-pagination"
                layout="total, sizes, prev, pager, next, slot"
                :total="pager.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
                <span class="pagination-text">
                    <span>
                        前往
                        <el-input
                            v-model="pagerJump"
                            @keyup.enter.native="handlePagerJump(pagerJump)"
                        ></el-input>
                        页
                    </span>
                    <span @click="handlePagerJump(pagerJump)">
                        跳转
                    </span>
                </span>
            </el-pagination>
            <!-- 
                
                v-if="!isImJobList && jobList.length > 0"
                
                <el-pagination 
                :current-page="pager.current" 
                :page-sizes="[10, 30, 50]" 
                :page-size="pager.take"
                class="job-recommend-record-pagination" 
                layout="prev, pager, next, slot, total, sizes" 
                :total="pager.total"
                @size-change="handleSizeChange" 
                @current-change="handleCurrentChange">
                <span class="pagination-text">
                    <span>
                        前往
                        <el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump(pagerJump)"></el-input>
                        页
                    </span>
                    <span @click="handlePagerJump(pagerJump)">
                        跳转
                    </span>
                </span>
            </el-pagination> -->
        </div>
    </div>
</template>

<script>
import Avatar from '#/component/common/avatar';
import Empty from '#/component/common/empty.vue';
// import RecommendationStatusTag from './recommendation-status-tag/index.vue';
import RecommendationStatusTag from '#/views/candidate-detail/component/recommendation-status-tag/index.vue';
import { job as jobUrl } from '#/js/config/api.json';

export default {
    components: {
        Avatar,
        Empty,
        RecommendationStatusTag
    },
    props: {
        shareData: Object
    },
    data() {
        return {
            loading: false,
            pager: {
                current: 1,
                take: 10,
                total: 0,
            },
            pagerJump: 0,
            jobRecommendList: [],
        };
    },

    computed: {
        isAgreementJob() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('AgreementJob');
        },
    },

    mounted() {
        this.getJobRecommendList();
    },

    methods: {
        handleClick(item) {
            this.routernavigate(item);
        },
        routernavigate(vm){
            let jobId = this.$route.params.jobId;
            let rootURL = '#/Job/' + jobId + '/recommendation/' + vm.id + '?recommendDate=' + vm.recommendDate;
            location.href = rootURL;
            this.$emit('update-state', 'detail');
        },
        // 分页
        handleSizeChange(val) {
            this.pager.take = val;
            this.pager.current = 1;
            this.getJobRecommendList();
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.getJobRecommendList();
        },
        handlePagerJump(pagerJump) {
            pagerJump = Number.parseInt(pagerJump);
            if (
                (pagerJump > 0 && pagerJump <= Math.ceil(this.pager.total / this.pager.take)) ||
                (pagerJump > 0 && pagerJump <= Math.ceil(this.pager.auditJobTotal / this.pager.take))
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },

        getJobRecommendList() {
            this.loading = true;
            let jobId = this.$route.params.jobId;
            _request({
                url: jobUrl.job_recommend_list.replace(/\%p/ig, jobId),
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                data: {
                    start: (this.pager.current - 1) * this.pager.take,
                    take: this.pager.take
                }
            }).then((res) => {
                this.loading = false;
                this.pager.total = res.total;
                this.jobRecommendList = res.list;
                this.$emit('updateRecommendCount', this.pager.total)
            }).catch((err) => {
                this.loading = false;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.job-recommend-record {
    padding: 0 20px;
    // .empty-data {
    //     text-align: center;
    //     margin: 20px 0;
    //     .empty-img {
    //         display: inline-block;
    //         background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
    //         background-size: contain;
    //         width: 160px;
    //         height: 160px;
    //     }
    //     & > p {
    //         font-size: 16px;
    //         line-height: 21px;
    //         margin-bottom: 0px;
    //     }
    // }

    .item {
        padding: 24px 22px 24px 0;
        border-bottom: 1px solid rgba(235,235,235,0.92);
        display: flex;
        align-items: center;
        cursor: pointer;
        // justify-content: space-between;
        .hr-info{
            width: 280px;
            display: flex;
            .pic{
                width: 36px;
                height: 36px;
                margin-right: 12px;
                overflow: hidden;
                /deep/ .avatar-sm {
                    width: 36px;
                    height: 36px;
                    border: 1px solid rgba(168,168,168,0.44);
                    border-radius: 50%;
                }
            }
            .info{
                flex: 1;
                >p{
                    line-height: 20px;
                    font-size: 12px;
                    color: #999;
                    margin: 0;
                    white-space: nowrap;
                    max-width: 230px;
                    .realname{
                        font-size: 14px;
                        color: #333;
                        margin-right: 4px;
                    }
                    .nickname{
                        color: #666;
                    }
                }

            }
        }
        .cand-info{
            flex: 1;
            display: inline-flex;
            align-items: center;
            >span{
                display: inline-block;
            }
            .name{
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                line-height: 20px;
                width: 75px;
                max-width: 75px;
            }
            .age{
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                line-height: 20px;
                display: inline-block;
                &::after{
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 14px;
                    background: #DCDCDC;
                    margin: 0 6px;
                    vertical-align: middle;
                }
            }
            .school, .degree{
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                line-height: 20px;
                max-width: 90px;
                display: inline-block;
            }
            .degree{
                margin-left: 6px;
            }
        }
        .recommend-status{
            width: 170px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tag{
                display: inline-block;

                // width: 64px;
                height: 20px;
                background: rgba(235,235,235,0.92);
                border-radius: 3px;
                border: 1px solid #DCDCDC;
                padding: 0 5px;

                font-size: 12px;
                font-weight: 500;
                color: #999999;
                line-height: 18px;
                .t-icon{
                    .font-icon{
                        vertical-align: -1px;
                    }
                }
            }
            .yellow{
                background: rgba(255,236,199,0.7);
                border-radius: 3px;
                border: 1px solid #FFDDA8;
                color: #FF9E21;
            }
            .green{
                background: rgba(56,188,157,0.14);
                border-radius: 3px;
                border: 1px solid rgba(56,188,157,0.53);
                color: #38BC9D;
            }
            .blue{
                background: rgba(185,208,255,0.14);
                border-radius: 3px;
                border: 1px solid rgba(81,164,255,0.53);
                color: #579BFF;
            }
            .arrow-icon{
                color: #666;
                font-size: 9px;
                vertical-align: 1px;
                margin-left: 20px;
                .font-icon{
                    vertical-align: baseline;
                }
            }
        }
        // .referrer {
        //     flex: 1;
        //     .name {
        //         cursor: pointer;
        //         margin-left: 10px;
        //         font-size: 16px;
        //         color: #333333;
        //         &:hover {
        //             color: $primary;
        //         }
        //     }
        // }

        // .recommend-time {
        //     flex: 2;
        //     display: flex;
        //     align-items: center;
        //     .time {
        //         color: #B4B4B4;
        //         margin-left: 10px;
        //     }
        // }

        // .recommend-status {
        //     flex: 2;
        // }
        
        // &:hover {
        //     background-color: #F9F9F9;
        // }
    }

    /deep/ .job-list-pagination.el-pagination {
        height: 38px;
        background: #FFFFFF;
        border-radius: 6px;
        padding-top: 2px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        // box-shadow: 3px 3px rgba(0, 0, 0, 0.5);
        .pagination-text{
            color: #999;
            span{
                height: 28px;
                line-height: 28px;
                font-size: 14px;
                .el-input{
                    width: 48px;
                    margin: 0 5px;
                    &__inner{
                        height: 28px;
                        line-height: 28px;
                        padding: 0 5px;
                    }
                    .el-input__inner{
                        height: 28px;
                        line-height: 28px;
                    }
                }
                &:nth-of-type(2){
                    margin-left: 5px;
                    color: $primary;
                    cursor: pointer;
                }
            }
        }

    }

    /deep/ .job-recommend-record-pagination {
        margin: 40px 0 20px 0;
        padding: 0;
        font-weight: normal;

        .btn-next, .btn-prev {
            height: 38px;
            border: 1px solid;
        }

        .btn-prev {
            border-right: none;
            border-radius: 4px 0 0 4px;
        }

        .btn-next {
            border-left: none;
            border-radius: 0 4px 4px 0;
        }

        .el-pagination__sizes .el-input {
            width: 87px;

            .el-input__inner {
                color: #666;

                .el-select__caret {
                    color: #999;
                }
            }
        }

        .el-pager {
            border-top: 1px solid;
            border-bottom: 1px solid;

            li {
                min-width: 36px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
            }
        }

        .btn-next, .btn-prev, .el-pager {
            border-color: #DDDDDD;
        }

        .el-input__inner, .el-pagination__total, .el-pagination__jump {
            height: 38px;
            line-height: 38px;
            font-size: 14px;
        }

        .pagination-text {
            color: #999;

            span {
                height: 38px;
                line-height: 38px;
                font-size: 14px;

                .el-input {
                    width: 48px;
                    margin: 0 5px;

                    &__inner {
                        height: 38px;
                        line-height: 38px;
                        padding: 0 5px;
                    }
                }

                &:nth-of-type(2) {
                    margin-left: 5px;
                    color: $primary;
                    cursor: pointer;
                }
            }
        }

        .el-pagination__total, .el-pagination__sizes, .pagination-text {
            float: right;
        }
    }
}</style>