<template>
    <div class="media-item" v-loading="loading">
        <div class="media-header">
            <avatar
                class="publisher-avatar"
                size="md"
                :src="avatarUrl"
                :userId="item.userId"
                :enableCard="true"
                :enableLink="true"
                :shareData="shareData"
            ></avatar>
            <div class="media-header-middle">
                <span class="publisher-name">
                    {{item.realName}}
                    <span class="publisher-nick-name">@{{item.nickName}}</span>
                </span>
                <span class="media-description">
                    {{description}}
                </span>
            </div>
            <div class="media-header-right">
                <span class="media-date">
                    {{item.created | dateFilter}}
                </span>
                <p class="media-header-right-btns">
                    <span class="rotate-btn" v-if="canDelete && checkIfIsIpad(item.deviceType)" @click="rotateVideo(item)" title="视频顺时针旋转90度">
                        <font-icon href="#icon-rotate-right"></font-icon>
                    </span>
                    <span class="text-operate-btn grey" v-if="canDelete" @click="deleteAudioVideo">
                        <i class="el-icon-close"></i>
                        删除
                    </span>
                </p>
            </div>
        </div>
        <div class="media-content" :class="[item.rotationAngle >= 0 ? `rotate-${item.rotationAngle}` : '', item.type === 1 ? 'video-content-container' : '', item.type === 0 ? 'audio-content-container' : '']">
            <template v-if="item.type === 1">
                <video
                    :id="jobVideoPlayerId"
                    class="video-content"
                    preload="auto"
                    playsinline
                    webkit-playsinline
                    v-show="item.fileId && !isNotReady"
                ></video>
                <div class="job-video-player-logo" v-html="userName" v-show="item.fileId && !isNotReady"></div>
                <div class="video-content-modal" v-if="!item.fileId || isNotReady">
                    <span class="video-loading-tip">视频加载中<br>请稍候....</span>
                </div>
            </template>
            <template v-if="item.type === 0">
                <record-listen v-if="item.mediaUrl.indexOf('&sign') > -1" class="audio-content" :audio-url="item.mediaUrl" :duration="duration" v-show="item.fileId && !isNotReady"></record-listen>
                <div class="audio-content-modal" v-if="!item.fileId || isNotReady">
                    <span class="audio-loading-tip">音频加载中，请稍候....</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Avatar from '#/component/common/avatar';
import RecordListen from '#/component/common/record-listen.vue';
import MediaService from '#/js/service/mediaService.js';
import { avatarHost } from '#/component/page/instant-message/utils/avatarHost.js';
import vod from '#/component/mediaPlay/tvod-client.js';
import moment from 'moment';
export default {
    components: {
        Avatar,
        RecordListen
    },
    props: {
        item: {
            type: Object,
            default() {
                return {
                    userId: "",
                    unionId: "",
                    realName: "",
                    nickName: "",
                    created: "",
                    type: 0,
                    description: "",
                    fileId : "",
                    id: "",
                    mediaId: "",
                    mediaUrl: "",
                    duration: ""
                }
            }
        },
        shareData: Object,
        canDelete: {
            type: Boolean,
            default: false
        },
        index: Number
    },
    filters: {
        dateFilter(val) {
            return moment(val * 1000).format('YYYY-MM-DD');
        }
    },
    computed: {
        userName() {
            return `${this.$store.state.user.userInfo.realName}@${this.$store.state.user.userInfo.nickname}`
        },
        jobVideoPlayerId() {
            return `videoPlayer_${this.item.id}`
        },
        avatarUrl() {
            return `${avatarHost()}/user/${this.item.unionId}`;
        },
        description() {
            if(this.item.description) {
                return this.item.description;
            }
            if(this.item.type === 0) {
                return '发布一条语音讲解';
            }
            if(this.item.type === 1) {
                return '发布一条视频讲解';
            }
        },
        duration() {
            return Math.round((parseInt(this.item.duration / 60) + this.item.duration % 60 / 60 ) * 100 ) / 100 + 'min';
        }
    },
    data() {
        return {
            loading: false,
            player: null,
            isFullScreen: false,
            isNotReady: false
        }
    },
    mounted() {},
    methods: {
        getPlayer() {
            this.isNotReady = true;
            let params = {
                type: this.item.type === 0 ? 1 : 0,
                fileId: this.item.fileId,
                t: parseInt((new Date().getTime() + 259200000)/1000).toString(16),
                dir: vod.dir(this.item.mediaUrl)
            };
            if(this.item.type === 0) {
                params.us = _generateUUID().replace(/\-/g, "").substring(0, 10);
            }
            MediaService.getPlaySign(params).then(res => {
                let self = this;
                this.isNotReady = false;
                this.$nextTick(() => {
                    if(this.item.type === 1) {
                        this.player = TCPlayer(this.jobVideoPlayerId, {
                            fileID: this.item.fileId,
                            appID: vod.appId,
                            psign: res
                        });
                        this.player.on('fullscreenchange', function() {
                            self.isFullScreen = !self.isFullScreen;
                            let playerContainer = document.querySelector('#' + self.jobVideoPlayerId);
                            if(self.isFullScreen) {
                                let logoDiv = document.querySelector('.job-video-player-logo').cloneNode(true);
                                logoDiv.style.fontSize = '16px';
                                logoDiv.style.display = 'block';
                                playerContainer.insertBefore(logoDiv, document.querySelector('#' + self.jobVideoPlayerId + '_html5_api'));
                            } else {
                                playerContainer.removeChild(playerContainer.querySelector('.job-video-player-logo'));
                            }
                        })
                    } else {
                        this.item.mediaUrl = `${this.item.mediaUrl}?t=${params.t}&us=${params.us}&sign=${res}`
                    }
                })
            }).catch(err => {
                console.log(err)
            });
        },
        deleteAudioVideo() {
            if(!this.item.fileId) {
                shortTips(`${this.item.type === 0 ? '音频' : '视频'}正在处理中，暂不能删除，请稍后重试`);
                return
            }
            this.$confirm('此操作将永久删除该文件，是否继续？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true;
                let params = {
                    fileId: this.item.fileId,
                    mappingId: this.item.id
                };
                MediaService.deleteJobMappingMedia(params).then(res => {
                    this.loading = false;
                    this.$emit('delete-media-item', this.index);
                    shortTips('删除成功!')
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                })
            });
        },
        rotateVideo(video) {
            video.rotationAngle = (video.rotationAngle + 90) % 360;
            MediaService.rotateVideo({
                mappingId: video.id,
                rotationAngle: video.rotationAngle
            })
        },
        checkIfIsIpad(deviceType) {
            let flag = false;
            if(deviceType.indexOf('iPad') > -1 || deviceType.indexOf('iPhone') > -1) {
                flag = true;
            }
            return flag;
        }
    }
}
</script>

<style lang="scss" scope>
    .media-item {
        width: 100%;
        margin-bottom: 28px;
        .media-header {
            display: flex;
            margin-bottom: 12px;
            .publisher-avatar {
                margin-right: 10px;
            }
            &-middle {
                display: flex;
                flex-direction: column;
                line-height: 20px;
                flex-grow: 1;
                .publisher-name {
                    margin-bottom: 8px;
                    color: #666;
                    .publisher-nick-name {
                        color: #b9b9b9;
                    }
                }
                .media-description {
                    color: #4f4f4f;
                }
            }
            &-right {
                min-width: 90px;
                text-align: right;
                display: flex;
                flex-direction: column;

                &-btns {
                    line-height: 16px;
                    margin: 0;
                }

                .media-date {
                    color: #b9b9b9;
                    font-size: 12px;
                    line-height: 20px;
                    margin-bottom: 4px;
                }
                .text-operate-btn {
                    font-size: 12px;
                }
                .rotate-btn {
                    display: inline-block;
                    fill: #999;
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                    cursor: pointer;

                    .font-icon {
                        width: 100%;
                        height: 100%;
                        vertical-align: top;
                    }
                }
            }
        }
        .media-content {
            width: 100%;
            position: relative;

            &.rotate-90 video {
                transform: rotate(90deg);
            }
            &.rotate-180 video {
                transform: rotate(180deg);
            }
            &.rotate-270 video {
                transform: rotate(270deg);
            }

            &.video-content-container {
                height: 512px;
                .video-content {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                }
                .video-content-modal{
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .video-loading-tip {
                        font-size: 16px;
                        color: #ddd;
                        line-height: 22px;
                    }
                }
                .job-video-player-logo {
                    position: absolute;
                    top: 4%;
                    right: 2%;
                    z-index: 10;
                    color: #bbb;
                    font-size: 14px;
                    line-height: 20px;
                    &.is-full-screen {
                        position: fixed !important;
                        top: 4% !important;
                        right: 2% !important;
                        font-size: 16px;
                    }
                }
            }
            &.audio-content-container {
                min-height: 40px;
                .audio-content-modal {
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .audio-loading-tip {
                        font-size: 16px;
                        color: #ddd;
                        line-height: 22px;
                    }
                }
            }
            button:focus {
                outline: unset;
            }
        }
    }
</style>
