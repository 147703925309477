<template>
    <div class="job-detail" v-loading="loading">
        <job-head 
            ref="jobHead" 
            :currentJobType="curJobType"
            :job-info="jobInfo" 
            :share-data="shareData" 
            :canMediaRecord="canMediaRecord" 
            @refresh="getJobInfo" 
            @record-media-success="recordMediaSuccess"
            :isFromAuthorization="jobInfo.isFromAuthorization"
        ></job-head>
        <job-content
            ref="jobContent"
            :currentJobType="curJobType"
            :job-info="jobInfo"
            :share-data="shareData"
            :onlineList="onlineMap"
            :canMediaList="canMediaList" 
            @refresh="getJobInfo"
            :isFromAuthorization="jobInfo.isFromAuthorization"
        ></job-content>
    </div>
</template>

<script>
    import emitter from '#/js/mixins/emitter';
    import jobHead from '#/views/job-detail/layout/job-detail-head.vue';
    import jobContent from '#/views/job-detail/layout/job-detail-content.vue';
    import { job as jobUrl } from '#/js/config/api.json';
    import moment from 'moment';
    // import imService from '#/js/service/imService.js';
    import imService from '#/js/service/openIMService.js';
    import MediaService from '#/js/service/mediaService.js';
    export default {
        name: "jobDetail",
        componentName: "JobDetail",
        mixins: [emitter],
        components: {
            jobHead,
            jobContent
        },
        created(){
            // let url = window.location.href;
            // let p = url.split('?')[1];
            // let routeParams = new URLSearchParams(p);
            // this.curRecommendJobType = routeParams.get('curRecommendJobType');
            // // console.log(this.curRecommendJobType);

            // this.getJobInfo();
            this.getJobTypeById();
        },
        data(){
            return{
                // curRecommendJobType: '0', // 从列表页传到url地址栏上的职位类别（0为A2A职位、3为HR直招职位...）
                curJobType: undefined, // 当前职位类别(职位详情接口获取)，3为HR直招职位
                jobInfo:{},
                loading: false,
                onlineMap: {},
                canMediaRecord: false,
                canMediaList: false
            }
        },
        computed: {
            shareData() {
                let jobInfo = this.jobInfo;
                return {
                    isPublic: jobInfo.isPublic,
                    jobId: jobInfo.id,
                    jobName: jobInfo.name,
                    firmShortName: jobInfo.firmShortName,
                    location: jobInfo.location,
                    yearOfExperience: jobInfo.yearOfExperience,
                    degree: jobInfo.degree,
                    salary: jobInfo.salary == '0K-0K' ? '面议' : jobInfo.salary,
                    // commissionValue: jobInfo.commissionValue && jobInfo.commissionValue.indexOf('0K-0K') > -1 ? '按比例' : jobInfo.commissionValue,
                    commissionValue: jobInfo.commissionType == '0' ? (jobInfo.minSalary == 0 && jobInfo.maxSalary == 0 ? `按比例(${jobInfo.commissionValue}%)` : ((jobInfo.minSalary * 12 * jobInfo.commissionValue / 100).toFixed(2) + 'K - ' + (jobInfo.maxSalary * 12 * jobInfo.commissionValue / 100).toFixed(2) + 'K(' + jobInfo.commissionValue + '%)')) : jobInfo.commissionValue + 'K',
                    creatorId: jobInfo.recruiterId,
                    creatorName: `${jobInfo.recruiterRealName}@${jobInfo.recruiterNickname}`,
                    creatorAvatar: jobInfo.recruiterAvatarUrl,
                    isInnerJob: !jobInfo.isPublic
                }
            },
            type() {
                return this.$route.query.type;
            },
            applicationId() {
                if(this.$route.query.applicationId == 'undefined') {
                    return null;
                }
                return this.$route.query.applicationId;
            },
        },
        mounted() {
            if(document.documentElement.scrollTop !== 0) {
                document.documentElement.scrollTop = 0;
            }

            this.$on('updateBindingProjects', (bindingProjects) => {
                this.$refs.jobContent.getFeeds(false);

                this.$refs.jobHead.useProjectPopover = false;
                this.jobInfo.bindingProjects = bindingProjects;
            });
        },
        methods: {
            // 通过职位id查询判断当前的职位类型
            getJobTypeById() {
                let jobId = this.$route.params.jobId;
                this.loading = true;
                _request({
                    url: "/openapi/Job/GetJobType/%p".replace(/\%p/ig, jobId),
                    method: 'GET',
                    baseURL: "LbdOpenApi",
                }).then((res) => {
                    this.curJobType = res;
                    this.getJobInfo();
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.loading = false;
                });
            },

            getJobInfo() {
                let jobId = this.$route.params.jobId;
                this.loading = true;
                let _requestParams = {};
                // if(this.curRecommendJobType==3) {
                if([3,5].includes(this.curJobType)) {
                    // hr直招职位详情接口
                    _requestParams = {
                        url: jobUrl.hr_job_info.replace(/\%p/ig, jobId),
                        method: 'GET',
                        baseURL: "LbdOpenApi",
                    }
                } else {
                    _requestParams = {
                        url: jobUrl.url_job_info.replace(/\%p/ig, jobId),
                        method: 'GET',
                    }
                }
                if(this.applicationId) {
                    _requestParams = {
                        url: jobUrl.get_job_apply_detail.replace(/\%p/ig, this.applicationId),
                        method: 'GET',
                        baseURL: "LbdOpenApi",
                    }
                }
                _request(
                    _requestParams
                ).then((res) => {
                    // this.loading = false;
                    this.jobInfo = res;
                    let firmName = this.$store.state.user.userInfo.firmShortName;
                    let title = this.jobInfo.name + '_' + firmName + '_' + '猎必得_最有效的O2O猎头招聘平台';
                    document.title = title;
                    if(location.hash.indexOf("?action=clickOperate") > -1) {
                        this.$refs.jobHead.recommendClick();
                    }
                    let accountIds = [];
                    accountIds.push(res.recruiterUnionId);
                    if(res.projectManagers && res.projectManagers.length) {
                        res.projectManagers.forEach(item => {
                            if(item.unionId) {
                                accountIds.push(item.unionId);
                            }
                        })
                    }
                    imService.getUserOnlineStatus({
                        accountIds: accountIds
                    }).then(result => {
                        if(result && result.length) {
                            result.forEach(item => {
                                this.onlineMap[item.accountId] = item.state === 'Online'
                            })
                        }
                    }).catch(err => {
                        console.log('getUserCardOnlineStatusError', err);
                    });
                    // this.checkMediaRecordingAuthority();

                    this.$nextTick(() => {
                        this.$refs.jobHead.getCategoryMap && this.$refs.jobHead.getCategoryMap();
                    })
                }).catch(err => {
                    console.log(err)
                    // this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
            },
            checkMediaRecordingAuthority() {
                MediaService.checkRecordingAuthority({
                    jobId: this.$route.params.jobId
                }).then(res => {
                    this.canMediaRecord = res.canRecord;
                    this.canMediaList = res.openStatus;
                    this.$set(this.$refs.jobContent, 'canMediaDelete', res.canDelete);
                    if(this.canMediaList) {
                        this.checkMediaViewed();
                    }
                    if(this.canMediaRecord) {
                        let today = new Date().getTime();
                        if(today >= new Date('2020/06/17 00:00:00').getTime() && today < new Date('2020/06/20 00:00:00') && (!localStorage.getItem('jobRecordGuideDate') || localStorage.getItem('jobRecordGuideDate') !== moment(today).format('YYYYMMDD'))) {
                            this.$set(this.$refs.jobHead, 'isShowRecordGuide', true);
                            localStorage.setItem('jobRecordGuideDate', moment(today).format('YYYYMMDD'));
                        }
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            checkMediaViewed() {
                MediaService.checkHasViewMedia({
                    jobId: this.$route.params.jobId
                }).then(res => {
                    this.$set(this.jobInfo, 'mediaNumber', res.count || 0);
                    this.$set(this.$refs.jobContent, 'hasNewMediaViewed', res.hasView);
                }).catch(err => {
                    console.log(err)
                })
            },
            recordMediaSuccess() {
                let currentCount = this.jobInfo.mediaNumber || 0;
                this.$set(this.jobInfo, 'mediaNumber', currentCount + 1);
                let jobContentVm = this.$refs.jobContent;
                jobContentVm.jobMediaNextId = Number.MAX_SAFE_INTEGER;
                jobContentVm.jobMediaList = [];
                if(jobContentVm.jobActiveTabName == 'jobMedia') {
                    jobContentVm.getJobMedia();
                }else {
                    jobContentVm.getJobMedia();
                    jobContentVm.jobActiveTabName = 'jobMedia';
                }
            }
        }
    }
</script>

<style lang="scss" scope>
.job-detail {
    height: 100%;
    overflow-y: auto;
    // margin-bottom: 10px;
    // padding: 20px;
    background: #F6F6F6;
}
</style>
