<template>
    <el-dialog
        class="invite-headhunter"
        width="660px"
        title="邀请猎头抢单"
        :visible.sync="dialogVisible"
        @close="reset()"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="invite-job-info">
            <!-- <a class="job-link" :href="`/Headhunting/MyCompany.html#/Job/${jobInfo.id}`" target="_blank">
                <i class="job-link-icon el-icon-link"></i>「{{jobInfo.name}}-{{jobInfo.firmShortName}}」 
            </a> -->
            <a class="job-link" :href="`/Headhunting/MyCompany.html#/Job/${jobInfo.id}`" target="_blank">
                <i class="job-link-icon el-icon-link"></i>「{{jobInfo.name}}-{{jobInfo.firmShortName}}」 
            </a>
        </div>
        <el-tabs
            class="headhunter-tab"
            :value="jobInfo.isCooperativeJob ? '4' : '1'"
            @tab-click="changeHeadhunterTab"
        >
        <!-- v-if="(tab.value != '1' && jobInfo.isCooperativeJob) || (tab.value == '1' && isHeadhunter)" -->
            <el-tab-pane
                :label="tab.name"
                :name="tab.value"
                
                v-for="tab in headhunterTabs"
                :key="tab.value"
            >
                <div class="headhunter-tab-content" v-if="tab.value == '4' || tab.value == '1'">
                    <el-input
                        placeholder="按职位、昵称搜索"
                        maxlength="30"
                        v-model="searchInput"
                        class="input-with-select"
                        @keyup.enter.native="getHeadhunters(false)"
                    >
                    </el-input>
                    <el-button
                        class="search-btn"
                        slot="append"
                        icon="el-icon-search"
                        @click="getHeadhunters(false)"
                    ></el-button>
                    <p class="invite-note" v-if="tab.value == '4'">
                        为您推荐可能感兴趣的猎头
                    </p>
                </div>
                <ul class="headhunter-list" v-loading="loading" v-if="headhunterList.length > 0">
                    <li
                        class="headhunter-item"
                        v-for="(headhunter, index) in headhunterList"
                        :key="index"
                    >
                        <div class="headhunter-detail">
                            <div class="headhunter-item-left">
                                <avatar
                                    class="headhunter-avatar"
                                    size="mg"
                                    :src="headhunter.avatarUrl"
                                    :userId="headhunter.userId"
                                    :enableCard="true"
                                    :enableLink="true"
                                ></avatar>
                            </div>
                            <div class="headhunter-item-middle">
                                <span class="headhunter-name">
                                    {{headhunter.realName}}
                                    <span class="text-grey">@{{headhunter.nickName}}</span>
                                </span>
                                <span class="headhunter-info">
                                    {{headhunter.firmName}} {{headhunter.title}}
                                </span>
                                <span class="headhunter-recommend-data" v-if="!jobInfo.isCooperativeJob">
                                    在
                                    <span class="text-primary">{{headhunter.jobCategoryName}}</span>
                                    上：推荐 {{headhunter.recomendationStatusStatistics.recommendationCount}} 面试 {{headhunter.recomendationStatusStatistics.interviewCount}} offer {{headhunter.recomendationStatusStatistics.offerCount}}
                                </span>
                            </div>
                            <div class="headhunter-item-right">
                                <el-button
                                    class="invite-btn has-invited"
                                    v-if="headhunter.hasOrdered"
                                >
                                    已抢单
                                </el-button>
                                <el-button
                                    class="invite-btn has-invited"
                                    v-else-if="headhunter.hasInvited"
                                >
                                    已邀请
                                </el-button>
                                <el-button
                                    class="invite-btn"
                                    v-else
                                    @click="showMessageInput(headhunter)"
                                >
                                    邀请
                                </el-button>
                            </div>

                        </div>
                        <div class="invite-message" v-if="headHunterId == headhunter.userId" v-loading="sendLoading">
                            发送邀请给 {{headhunterRealName}}
                            <span class="text-grey">@{{headhunterNickName}}</span>
                            <el-input
                                class="invite-message-input"
                                :class="inviteInputError ? 'invite-error' : ''"
                                type="textarea"
                                :rows="3"
                                maxlength="200"
                                resize="none"
                                v-model="inviteInput"
                                @change="inviteMessageInput"
                            ></el-input>
                            <p
                                class="invite-error-note"
                                v-if="inviteInputError"
                            >
                                邀请内容不能为空
                            </p>
                            <el-button class="edit-btn edit-btn-confirm" @click="inviteHeadhunter(headhunter.userId, index)">发送</el-button>
                            <el-button class="edit-btn edit-btn-cancel" @click="reset">取消</el-button>
                        </div>
                    </li>
                </ul>
                <div class="no-data" v-else>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
                <div class="get-more" @click="getHeadhunters(true)" v-if="!loading && !noMore">
                    加载更多
                    <font-icon class="get-more-icon" href="#icon-spread-up-copy"></font-icon>
                </div>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
import Avatar from '#/component/common/avatar.vue';
import { job as jobUrl } from '#/js/config/api.json';

export default {
    name: "inviteHeadhunterDialog",
    components: {
        Avatar
    },
    props: {
        jobInfo: Object,
        isHeadhunter: Boolean,
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            searchInput: "",
            inviteNote: "",
            headhunterTabs: [
            //     {
            //     name: "平台猎头",
            //     value: "4"
            // }, 
            {
                name: "公司同事",
                value: "1"
            }, 
            // {
            //     name: "帮做过单的",
            //     value: "2"
            // }, {
            //     name: "我关注的",
            //     value: "3"
            // }
            ],
            headhunterList: [],
            headHunterId: "",
            headhunterRealName: "",
            headhunterNickName: "",
            inviteInput: "",
            inviteInputError: false,
            headhunterTab: "",
            start: 0,
            noMore: false,
            sendLoading: false
        }
    },
    computed: {

    },
    methods: {
        show() {
            this.getHeadhunters(false);
            this.dialogVisible = true;
        },
        changeHeadhunterTab(tab) {
            if(this.headhunterTab != tab.name) {
                this.headhunterTab = tab.name;
                this.getHeadhunters(false);
            } else {
                return;
            }
        },
        getHeadhunters(isGetMore) {
            if(isGetMore) {
                this.start = this.start + 10;
            } else {
                this.start = 0;
            }
            this.loading = true;
            this.noMore = false;
            let url = jobUrl.get_invitable_headhunters;
            if(this.headhunterTab == "4") {
                url = jobUrl.invitable_headhunters;
            }
            _request({
                url: url.replace(/\%p/ig, this.jobInfo.id),
                method: "GET",
                data: {
                    start: this.start,
                    take: 10,
                    type: this.headhunterTab,
                    filter: this.searchInput,
                    _: new Date().getTime()
                }
            }).then(res => {
                this.loading = false;
                let total = 0;
                if(!isGetMore) {
                    this.headhunterList = [];
                }
                if(this.headhunterTab == "4") {
                    this.headhunterList = this.headhunterList.concat(res);
                    total = res.length;
                } else {
                    this.headhunterList = this.headhunterList.concat(res.list);
                    total = res.total;
                }
                if(this.headhunterList.length !== total) {
                    this.noMore = false;
                } else {
                    this.noMore = true;
                }
            }).catch(err => {
                this.loading = false;
            })
        },
        showMessageInput(headhunter) {
            this.headHunterId = headhunter.userId;
            this.headhunterRealName = headhunter.realName;
            this.headhunterNickName = headhunter.nickName;
        },
        inviteMessageInput() {
            if(this.inviteInput) {
                this.inviteInputError = false;
            } else {
                this.inviteInputError = true;
            }
        },
        inviteHeadhunter(id, index) {
            this.sendLoading = true;
            _request({
                url: jobUrl.invite_headhunters.replace(/\%p/ig, this.jobInfo.id),
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    jobId: this.jobInfo.id,
                    userId: id,
                    content: this.inviteInput
                }
            }).then(res => {
                this.sendLoading = false;
                this.headhunterList[index].hasInvited = true;
                this.reset();
            }).catch(err => {
                this.sendLoading = false;
            })
        },
        reset() {
            this.headHunterId = "";
            this.headhunterRealName = "";
            this.headhunterNickName = "";
            this.inviteInput = "";
            this.inviteInputError = false;
        }
    }
}
</script>

<style lang="scss" scope>
.invite-headhunter{
    .text-primary {
        color: $primary;
    }
    .text-grey {
        color: #b9b9b9;
    }
    .invite-job-info {
        padding: 15px 13px;
        background: #f8f8f8;
        line-height: 20px;
        margin-top: 10px;
        .job-link {
            color: $primary;
            .job-link-icon {
                font-size: 16px;
                vertical-align: middle;
            }
        }
    }
    .headhunter-tab {
        margin-top: 10px;
        .el-tabs__item {
            font-size: 16px;
        }
        .headhunter-tab-content {
            position: relative;
        }
        .search-btn {
            position: absolute;
            top: 0;
            right: 0;
            min-width: unset;
            width: 40px;
            height: 40px;
            padding: 0;
            background: inherit;
            border: none;
            &:hover {
                color: $primary;
            }
            i {
                font-size: 18px;
            }
        }
        .invite-note {
            margin: 20px 0 10px;
        }
        .headhunter-list {
            margin-top: 10px;
            height: 400px;
            overflow: scroll;
            .headhunter-item {
                padding: 10px;
            }
            .headhunter-detail {
                display: flex;
                justify-content: space-between;
            }
            .headhunter-item-middle {
                flex-grow: 1;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            .invite-btn {
                background: $primary;
                color: #fff;
                &:hover {
                    background: #36b495;
                }
                &.has-invited {
                    cursor: not-allowed;
                    background: #ddd;
                    color: #666;
                    border: none;
                    &:hover {
                        background: #ddd;
                        color: #666;
                    }
                }
            }
            .invite-message {
                background-color: #f0faf7;
                padding: 20px;
                margin-top: 10px;
                &-input {
                    margin-top: 15px;
                    &.invite-error {
                        .el-textarea__inner {
                            border-color: #ff493c;
                        }
                    }
                }
                .invite-error-note {
                    margin: 0;
                    color: #ff493c;
                }
                .edit-btn {
                    margin: 14px 8px 0 0;
                    padding: 0 20px;
                    height: 28px;
                    width: auto;
                    line-height: 28px;
                    border-radius: 3px;
                    &.edit-btn-confirm {
                        background: $primary;
                        color: #fff;
                        &:hover {
                            background-color: #36b495;
                        }
                    }
                }
            }
        }
        .no-data {
            margin-top: 10px;
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                width: 240px;
                height: 228px;
            }
            .empty-data {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }
        .get-more {
            text-align: center;
            height: 28px;
            line-height: 28px;
            border: 1px solid #ccc;
            color: #ccc;
            cursor: pointer;
            .get-more-icon {
                width: 16px;
                height: 16px;
            }
        }
    }
}
</style>