<template>
    <el-dialog
        title="封禁说明"
        :visible.sync="dialogVisible"
        width="550px"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <div class="guide-header">
            <p>很抱歉您可能被使用了平台提供的「封禁」功能，解禁前都不能对该职位推荐候选人了,您可以尝试：</p>
        </div>
        <ul class="guide-list">
            <li>排除误操作情况（联系平台运营人员）</li>
            <li>进一步理解职位需求（社区 #互动 中私信 HR）</li>
            <li>换其他订单使劲</li>
        </ul>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible=false">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
        name: "banDialog",
        data(){
            return{
                dialogVisible:false,
            }
        },
        methods:{
            show(){
                this.dialogVisible = true;
            }
        }
    }
</script>
<style lang="scss" scoped>
.guide-list {
    line-height: 40px;
    li:before {
        display: inline-block;
        content: '';
        width: 6px;
        height: 6px;
        vertical-align: middle;
        margin-right: 7px;
        background: $primary;
        border-radius: 3px;
    }
}
</style>