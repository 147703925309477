var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-detail-head" },
    [
      _c("div", { staticClass: "head-page-main" }, [
        _c(
          "div",
          { staticClass: "name-row" },
          [
            _c("div", { staticClass: "name_tags_wrap" }, [
              _c("span", { staticClass: "name_loca ell" }, [
                _c(
                  "span",
                  {
                    staticClass: "name ell",
                    attrs: { title: _vm.jobInfo.name },
                  },
                  [_vm._v(_vm._s(_vm.jobInfo.name))]
                ),
                _c(
                  "span",
                  {
                    staticClass: "loca ell",
                    attrs: { title: _vm.jobInfo.location },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.jobInfo.location ? `[${_vm.jobInfo.location}]` : ""
                      )
                    ),
                  ]
                ),
              ]),
              [3, 4, 5].includes(_vm.jobInfo.jobType)
                ? _c(
                    "span",
                    { staticClass: "tags ell" },
                    [
                      _vm.jobInfo.emergencyDegree == 1
                        ? _c(
                            "span",
                            {
                              staticClass: "JobEmergency",
                              attrs: { title: "急聘" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "hurry" },
                                [
                                  _c("font-icon", {
                                    attrs: { href: "#icon-shandian" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v("急聘\n                    "),
                            ]
                          )
                        : _vm._e(),
                      _vm.jobInfo.difficultDegree == 1
                        ? _c("span", { staticClass: "tag job-tag-danger" }, [
                            _vm._v("较难"),
                          ])
                        : _vm._e(),
                      _vm.jobInfo.approvedStatus != "2"
                        ? [
                            _vm.jobInfo.status == 1
                              ? _c("span", { staticClass: "recruiting" }, [
                                  _c(
                                    "span",
                                    { staticClass: "hurry" },
                                    [
                                      _c("font-icon", {
                                        attrs: { href: "#icon-a-bianzu11" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v("招聘中\n                        "),
                                ])
                              : _c(
                                  "span",
                                  {
                                    staticClass: "tag",
                                    class: `job-tag-${
                                      _vm.jobStatus[_vm.jobInfo.status]?.theme
                                    }`,
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.jobStatus[_vm.jobInfo.status]?.text
                                      )
                                    ),
                                  ]
                                ),
                          ]
                        : _vm._e(),
                      _vm.jobInfo.jobType
                        ? _c("span", { staticClass: "tag job-tag-info" }, [
                            _vm._v(
                              _vm._s(
                                _vm.jobInfo.jobType == "3" ||
                                  _vm.jobInfo.jobType == "4"
                                  ? _vm.jobInfo.jobType == "3"
                                    ? "HR"
                                    : "A2A"
                                  : "代运营"
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm.jobInfo.expectedRank
                        ? _c("span", { staticClass: "tag job-tag-primary" }, [
                            _vm._v(_vm._s(_vm.jobInfo.expectedRank)),
                          ])
                        : _vm._e(),
                    ],
                    2
                  )
                : _c(
                    "span",
                    { staticClass: "tags" },
                    [
                      _vm._l(_vm.jobInfo.activityTags, function (item) {
                        return _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.id !== "StandstillJob" &&
                                  item.id != "FirstOrderActivityJob",
                                expression:
                                  "item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'",
                              },
                            ],
                            key: item.id,
                            attrs: {
                              effect: "light",
                              placement: "right",
                              disabled: !item.description,
                              "popper-class": "job-tag-description",
                            },
                          },
                          [
                            _c("div", {
                              attrs: { slot: "content" },
                              domProps: { innerHTML: _vm._s(item.description) },
                              slot: "content",
                            }),
                            item.id == "JobEmergency"
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "JobEmergency",
                                    attrs: { title: "急聘" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "hurry" },
                                      [
                                        _c("font-icon", {
                                          attrs: { href: "#icon-shandian" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v("急聘\n                        "),
                                  ]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "tag",
                                    class: `job-tag-${
                                      _vm.jobActivityTag[item.id].theme
                                    }`,
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          item.name == "New"
                                            ? "最新"
                                            : item.name
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                          ]
                        )
                      }),
                      _vm.jobInfo.status && !_vm.jobInfo.isSleeping
                        ? _c(
                            "span",
                            {
                              staticClass: "tag",
                              class: `job-tag-${
                                _vm.jobStatus[_vm.jobInfo.status].theme
                              }`,
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.jobStatus[_vm.jobInfo.status].text)
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.jobInfo.expectedRank
                        ? _c("span", { staticClass: "tag job-tag-primary" }, [
                            _vm._v(_vm._s(_vm.jobInfo.expectedRank)),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
              _vm.jobInfo.isSleeping
                ? _c("span", { staticClass: "sleeping-state" }, [
                    !_vm.jobInfo.isMyJob
                      ? _c(
                          "span",
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "light",
                                  content:
                                    "发布者已30天未登录，快去关注其他职位吧",
                                  placement: "top",
                                },
                              },
                              [
                                _c("font-icon", {
                                  staticClass: "job-font-icon",
                                  attrs: { href: "#icon-snore" },
                                }),
                                _vm._v("沉睡职位\n                        "),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _c("font-icon", {
                              staticClass: "job-font-icon",
                              attrs: { href: "#icon-snore" },
                            }),
                            _vm._v("沉睡职位\n                    "),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
              _vm.canActivateJob
                ? _c(
                    "span",
                    { staticClass: "new-activating-state" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.activateLoading,
                              expression: "activateLoading",
                            },
                          ],
                          staticClass: "btn-activate",
                          on: { click: _vm.activateJob },
                        },
                        [_vm._v("激活")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm.approvedStatus != 2 && _vm.approvedStatus != 4
              ? _c(
                  "el-dropdown",
                  { attrs: { trigger: "click" } },
                  [
                    _c(
                      "span",
                      { staticClass: "more" },
                      [_c("font-icon", { attrs: { href: "#icon-set" } })],
                      1
                    ),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "more-dropdown-menu no-arrow",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown",
                      },
                      [
                        _vm.jobInfo.status != 4
                          ? _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "left",
                                      width: "230",
                                      trigger: "hover",
                                      "popper-class": "share-popover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "item-wrap",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c("font-icon", {
                                          staticClass: "item-icon",
                                          attrs: { href: "#icon-share1" },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "item-text" },
                                          [_vm._v("分享")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "share-btn",
                                        on: { click: _vm.shareToIM },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("#/assets/images/instantMessage/private_message.png"),
                                          },
                                        }),
                                        _vm._v(
                                          " 私信和群分享\n                            "
                                        ),
                                      ]
                                    ),
                                    !_vm.isFromAuthorization &&
                                    _vm.jobInfo.recruiterFirmId ===
                                      _vm.user.firmId
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "share-btn",
                                            on: { click: _vm.shareToWeChat },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("#/assets/images/instantMessage/wechat.png"),
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                分享到微信\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.isFromAuthorization &&
                                    _vm.jobInfo.recruiterFirmId ===
                                      _vm.user.firmId
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "share-btn",
                                            on: { click: _vm.addToMyJobCard },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("#/assets/images/instantMessage/user-card.png"),
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                添加到我的职位名片\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.jobInfo.recruiterFirmId == _vm.user.firmId &&
                        _vm.jobInfo.customerDepartmentId &&
                        _vm.jobInfo.customerDepartmentId != "0" &&
                        _vm.jobInfo.status != 4
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.showBindProjectDialog.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "item-wrap" },
                                  [
                                    _c("font-icon", {
                                      staticClass: "item-icon",
                                      attrs: { href: "#icon-survey" },
                                    }),
                                    _c("span", { staticClass: "item-text" }, [
                                      _vm._v("绑定项目"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        (_vm.jobInfo.isMyJob ||
                          _vm.jobInfo.canPublishToMarket) &&
                        !_vm.jobInfo.isSleeping &&
                        !_vm.isFromAuthorization &&
                        _vm.jobInfo.recruiterId == _vm.user.id
                          ? _c(
                              "el-dropdown-item",
                              [
                                _c("my-job-operate", {
                                  attrs: {
                                    jobInfo: _vm.jobInfo,
                                    customerId: _vm.firmId,
                                    customerDepartmentId:
                                      _vm.jobInfo.customerDepartmentId,
                                    isAdministrator: _vm.user.isAdministrator,
                                  },
                                  on: { refresh: _vm.refresh },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.jobInfo.isMyJob &&
                        _vm.user.isCFUser &&
                        (_vm.jobInfo.publicJobId ||
                          (!_vm.jobInfo.publicJobId && _vm.jobInfo.status == 1))
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.showFamousCompanyDialog.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "item-wrap" },
                                  [
                                    _c("font-icon", {
                                      staticClass: "item-icon",
                                      attrs: { href: "#icon-electronics" },
                                    }),
                                    _vm.jobInfo.publicJobId
                                      ? [
                                          _c(
                                            "span",
                                            { staticClass: "item-text" },
                                            [_vm._v("查看大厂职位")]
                                          ),
                                        ]
                                      : _vm._e(),
                                    !_vm.jobInfo.publicJobId &&
                                    _vm.jobInfo.status == 1
                                      ? [
                                          _c(
                                            "span",
                                            { staticClass: "item-text" },
                                            [_vm._v("发布到大厂机会")]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isFromAuthorization
                          ? [
                              ((_vm.jobInfo.jobType == 2 &&
                                _vm.jobInfo.isPublic == false) ||
                                _vm.jobInfo.acceptedHeadhuntersCount == 0) &&
                              _vm.canEdit
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.handleEdit.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "item-wrap" },
                                        [
                                          _c("font-icon", {
                                            staticClass: "item-icon",
                                            attrs: { href: "#icon-edit1" },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "item-text" },
                                            [_vm._v("编辑")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.jobInfo.isSleeping &&
                              (_vm.jobInfo.isMyJob ||
                                (_vm.isHeadhunter && _vm.jobInfo.isMyOrder)) &&
                              _vm.jobInfo.status != 4
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.showTransferJobDialog.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "item-wrap" },
                                        [
                                          _c("font-icon", {
                                            staticClass: "item-icon",
                                            attrs: { href: "#icon-export1" },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "item-text" },
                                            [_vm._v("移交")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm.jobInfo.isMyJob &&
                        _vm.approvedStatus != 2 &&
                        _vm.jobInfo.status != 4
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleBindDepartment.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "item-wrap" },
                                  [
                                    _c("font-icon", {
                                      staticClass: "item-icon",
                                      attrs: { href: "#icon-zuzhijiagou" },
                                    }),
                                    _c("span", { staticClass: "item-text" }, [
                                      _vm._v("绑定组织架构"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "salary-row" }, [
          _c("div", { staticClass: "sa-l" }, [
            _c("p", { staticClass: "sa-row" }, [
              _vm.currentJobType == 3
                ? _c("span", { staticClass: "salary" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.jobInfo.minSalary == 0 &&
                            _vm.jobInfo.maxSalary == 0
                            ? "面议"
                            : _vm.jobInfo.minSalary +
                                "K-" +
                                _vm.jobInfo.maxSalary +
                                "K"
                        ) +
                        "\n                    "
                    ),
                  ])
                : _c("span", { staticClass: "salary" }, [
                    _vm._v(
                      _vm._s(
                        _vm.jobInfo.salary == "0K-0K"
                          ? "面议"
                          : _vm.jobInfo.salary
                      )
                    ),
                  ]),
              _c("span", { staticClass: "icon-wrap" }, [
                _c(
                  "span",
                  { staticClass: "info-icon" },
                  [_c("font-icon", { attrs: { href: "#icon-time1" } })],
                  1
                ),
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.jobInfo.yearOfExperience) +
                    "\n                    "
                ),
              ]),
              _c("span", { staticClass: "icon-wrap" }, [
                _c(
                  "span",
                  { staticClass: "info-icon" },
                  [_c("font-icon", { attrs: { href: "#icon-work" } })],
                  1
                ),
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.jobInfo.degree) +
                    "\n                    "
                ),
              ]),
              _c("span", { staticClass: "icon-wrap" }, [
                _c(
                  "span",
                  { staticClass: "info-icon" },
                  [_c("font-icon", { attrs: { href: "#icon-user1" } })],
                  1
                ),
                _vm._v(
                  "\n                        需" +
                    _vm._s(_vm.jobInfo.recruitingCount) +
                    "人\n                    "
                ),
              ]),
              _c("span", { staticClass: "icon-wrap" }, [
                _c(
                  "span",
                  { staticClass: "info-icon" },
                  [_c("font-icon", { attrs: { href: "#icon-modular" } })],
                  1
                ),
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm.mapPath(_vm.categoryMap, _vm.jobInfo.categoryId)
                    ) +
                    "\n                    "
                ),
              ]),
            ]),
            !_vm.applicationId
              ? _c(
                  "p",
                  { staticClass: "sa-row" },
                  [
                    ![3, 5].includes(_vm.currentJobType)
                      ? [
                          _c("span", { staticClass: "percent" }, [
                            _vm._v("佣金 " + _vm._s(_vm.commissionValue)),
                          ]),
                        ]
                      : _vm._e(),
                    [3, 5].includes(_vm.currentJobType) &&
                    _vm.jobInfo.commissionType == "0"
                      ? [
                          _c("span", { staticClass: "percent" }, [
                            _vm._v(
                              "佣金 \n                            " +
                                _vm._s(
                                  _vm.jobInfo.minSalary == 0 &&
                                    _vm.jobInfo.maxSalary == 0
                                    ? `按比例(${_vm.jobInfo.commissionValue}%)`
                                    : (
                                        (_vm.jobInfo.minSalary *
                                          12 *
                                          _vm.jobInfo.commissionValue) /
                                        100
                                      ).toFixed(2) +
                                        "K - " +
                                        (
                                          (_vm.jobInfo.maxSalary *
                                            12 *
                                            _vm.jobInfo.commissionValue) /
                                          100
                                        ).toFixed(2) +
                                        "K(" +
                                        _vm.jobInfo.commissionValue +
                                        "%)"
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    [3, 5].includes(_vm.currentJobType) &&
                    _vm.jobInfo.commissionType == "1"
                      ? [
                          _c("span", { staticClass: "percent" }, [
                            _vm._v(
                              "佣金 " +
                                _vm._s(_vm.jobInfo.commissionTypeValue + "K")
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _c("span", { staticClass: "promise-time" }, [
                      _vm._v("\n                        保证期"),
                      _c("span", { staticClass: "month" }, [
                        _vm._v(_vm._s(_vm.jobInfo.guaranteePeriod)),
                      ]),
                      _vm._v("个月 \n                    "),
                    ]),
                  ],
                  2
                )
              : _c("p", { staticClass: "sa-row" }, [
                  _vm.approvedStatus == "2" ||
                  _vm.approvedStatus == "4" ||
                  _vm.approvedStatus == "0"
                    ? _c(
                        "span",
                        [
                          _vm.jobInfo.publicCommissionType == "0"
                            ? [
                                _c(
                                  "span",
                                  { staticClass: "percent" },
                                  [
                                    _vm._v(
                                      "佣金 \n                                "
                                    ),
                                    _vm.jobInfo.jobType == 4
                                      ? [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.jobInfo.minSalary == 0 &&
                                                  _vm.jobInfo.maxSalary == 0
                                                  ? `按比例(${_vm.jobInfo.commissionValue}%)`
                                                  : (
                                                      (_vm.jobInfo.minSalary *
                                                        12 *
                                                        _vm.jobInfo
                                                          .publicCommissionValue) /
                                                      100
                                                    ).toFixed(2) +
                                                      "K - " +
                                                      (
                                                        (_vm.jobInfo.maxSalary *
                                                          12 *
                                                          _vm.jobInfo
                                                            .publicCommissionValue) /
                                                        100
                                                      ).toFixed(2) +
                                                      "K(" +
                                                      _vm.jobInfo
                                                        .publicCommissionValue +
                                                      "%)"
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.jobInfo.minSalary == 0 &&
                                                  _vm.jobInfo.maxSalary == 0
                                                  ? `按比例(${_vm.jobInfo.commissionValue}%)`
                                                  : (
                                                      (_vm.jobInfo.minSalary *
                                                        12 *
                                                        _vm.jobInfo
                                                          .commissionValue) /
                                                      100
                                                    ).toFixed(2) +
                                                      "K - " +
                                                      (
                                                        (_vm.jobInfo.maxSalary *
                                                          12 *
                                                          _vm.jobInfo
                                                            .commissionValue) /
                                                        100
                                                      ).toFixed(2) +
                                                      "K(" +
                                                      _vm.jobInfo
                                                        .publicCommissionValue +
                                                      "%)"
                                              ) +
                                              "\n                                "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                          _vm.jobInfo.publicCommissionType == "1"
                            ? [
                                _c("span", { staticClass: "percent" }, [
                                  _vm._v(
                                    "佣金 " +
                                      _vm._s(
                                        _vm.jobInfo.publicCommissionValue + "K"
                                      )
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.approvedStatus == "3"
                    ? _c(
                        "span",
                        [
                          _vm.jobInfo.publicCommissionType == "0"
                            ? [
                                _c("span", { staticClass: "percent" }, [
                                  _vm._v(
                                    "佣金 " +
                                      _vm._s(
                                        _vm.jobInfo.minSalary == 0 &&
                                          _vm.jobInfo.maxSalary == 0
                                          ? `按比例(${_vm.jobInfo.commissionTypeValue}%)`
                                          : (
                                              (_vm.jobInfo.minSalary *
                                                12 *
                                                _vm.jobInfo
                                                  .commissionTypeValue) /
                                              100
                                            ).toFixed(2) +
                                              "K - " +
                                              (
                                                (_vm.jobInfo.maxSalary *
                                                  12 *
                                                  _vm.jobInfo
                                                    .commissionTypeValue) /
                                                100
                                              ).toFixed(2) +
                                              "K(" +
                                              _vm.jobInfo.commissionTypeValue +
                                              "%)"
                                      )
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm.jobInfo.publicCommissionType == "1"
                            ? [
                                _c("span", { staticClass: "percent" }, [
                                  _vm._v(
                                    "佣金 " +
                                      _vm._s(
                                        _vm.jobInfo.commissionTypeValue + "K"
                                      )
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
          ]),
          _c(
            "div",
            { staticClass: "sa-r" },
            [
              _vm.approvedStatus == 2
                ? [
                    _c("button", { staticClass: "btn share" }, [
                      _vm._v(
                        "\n                        职位审批中\n                    "
                      ),
                    ]),
                  ]
                : _vm.approvedStatus == 4
                ? [
                    _c(
                      "button",
                      { staticClass: "btn share", on: { click: _vm.reApply } },
                      [
                        _vm._v(
                          "\n                        重新申请\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          width: "200",
                          trigger: "hover",
                          content: _vm.jobInfo.remark || "暂无",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fail-tip",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c("span", { staticClass: "fail-tip-main" }, [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                              _vm._v(
                                "\n                                审批未通过，查看原因\n                            "
                              ),
                            ]),
                            _c("br"),
                            _c("span", { staticClass: "gray" }, [
                              _vm._v("可编辑职位重新申请"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                : [
                    (_vm.currentJobType != 3 &&
                      _vm.jobInfo.status == 1 &&
                      _vm.jobInfo.canGetOrder &&
                      !_vm.jobInfo.isMyOrder &&
                      !_vm.jobInfo.isMyJob) ||
                    ([3, 5].includes(_vm.currentJobType) &&
                      !_vm.jobInfo.isMyOrder)
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.headBtnLoading,
                                expression: "headBtnLoading",
                              },
                            ],
                            staticClass: "btn grab",
                            on: { click: _vm.acceptClick },
                          },
                          [
                            _vm._v(
                              "\n                        立即抢单\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.jobInfo.status == 1 &&
                    (_vm.jobInfo.isMyOrder || _vm.jobInfo.isMyJob)
                      ? _c(
                          "button",
                          {
                            staticClass: "btn share",
                            on: { click: _vm.recommendClick },
                          },
                          [_vm._v("推荐候选人")]
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "num-row" }, [
          [3, 5].includes(_vm.currentJobType)
            ? _c("div", { staticClass: "nums-wrap" }, [
                _c("div", { staticClass: "num-cell" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(
                        _vm.jobInfo.floatingInfo
                          ? _vm.jobInfo.floatingInfo.recommendationCount
                          : 0
                      )
                    ),
                  ]),
                  _vm._v("推荐\n                "),
                ]),
                _c("div", { staticClass: "num-cell" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(
                        _vm.jobInfo.floatingInfo
                          ? _vm.jobInfo.floatingInfo.interviewCount
                          : 0
                      )
                    ),
                  ]),
                  _vm._v("面试\n                "),
                ]),
                _c("div", { staticClass: "num-cell" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(
                        _vm.jobInfo.floatingInfo
                          ? _vm.jobInfo.floatingInfo.offerCount
                          : 0
                      )
                    ),
                  ]),
                  _vm._v("Offer\n                "),
                ]),
                _c("div", { staticClass: "num-cell" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(
                        _vm.jobInfo.floatingInfo
                          ? _vm.jobInfo.floatingInfo.acceptedCount
                          : 0
                      )
                    ),
                  ]),
                  _vm._v("接受\n                "),
                ]),
                _c("div", { staticClass: "num-cell" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      _vm._s(
                        _vm.jobInfo.floatingInfo
                          ? _vm.jobInfo.floatingInfo.rejectedCount
                          : 0
                      )
                    ),
                  ]),
                  _vm._v("拒绝\n                "),
                ]),
              ])
            : _c("div", { staticClass: "nums-wrap" }, [
                _c("div", { staticClass: "num-cell" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.jobInfo.recommendationCount || 0)),
                  ]),
                  _vm._v("推荐\n                "),
                ]),
                _c("div", { staticClass: "num-cell" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.jobInfo.interviewCount || 0)),
                  ]),
                  _vm._v("面试\n                "),
                ]),
                _c("div", { staticClass: "num-cell" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.jobInfo.offerCount || 0)),
                  ]),
                  _vm._v("Offer\n                "),
                ]),
                _c("div", { staticClass: "num-cell" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.jobInfo.acceptedCount || 0)),
                  ]),
                  _vm._v("接受\n                "),
                ]),
                _c("div", { staticClass: "num-cell" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.jobInfo.rejectedCount || 0)),
                  ]),
                  _vm._v("拒绝\n                "),
                ]),
              ]),
          _c("div", { staticClass: "num-r" }, [
            _c("span", { staticClass: "update" }, [
              _c(
                "span",
                { staticClass: "update-icon", attrs: { title: "更新时间" } },
                [_c("font-icon", { attrs: { href: "#icon-time1" } })],
                1
              ),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm._f("dateFilter")(_vm.jobInfo.updated)) +
                  " 更新\n                "
              ),
            ]),
            _c("span", { staticClass: "publish-time" }, [
              _vm._v(
                "发布时间：" +
                  _vm._s(_vm._f("createDateFilter")(_vm.jobInfo.created))
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "customer-wraper" },
          [
            _vm.currentJobType == 3
              ? _c("div", { staticClass: "customer-infos" }, [
                  _c(
                    "a",
                    {
                      staticClass: "pic",
                      class: {
                        "pointer-event-none":
                          !_vm.jobInfo.canViewInternalInformation,
                      },
                      attrs: {
                        href: `/#/Customer/${_vm.firmId}`,
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.jobInfo.firmLogoUrl ||
                            "//hstatic.hirede.com/lbd/images/firm-default-avatar.jpg",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "infos",
                      attrs: {
                        title:
                          _vm.jobInfo.firmShortName +
                          "-" +
                          _vm.jobInfo.weComDepartment,
                      },
                    },
                    [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.jobInfo.firmShortName)),
                      ]),
                    ]
                  ),
                ])
              : _c("div", { staticClass: "customer-infos" }, [
                  _c(
                    "a",
                    {
                      staticClass: "pic",
                      class: {
                        "pointer-event-none":
                          !_vm.jobInfo.canViewInternalInformation,
                      },
                      attrs: {
                        href: `/#/Customer/${_vm.firmId}`,
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@src/assets/images/default-company.png"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "infos" },
                    [
                      !_vm.canFirmLink
                        ? _c(
                            "span",
                            {
                              staticClass: "name",
                              class:
                                _vm.jobInfo.bindingProjects &&
                                Object.keys(_vm.jobInfo.bindingProjects)
                                  .length > 0 &&
                                _vm.jobInfo.recruiterFirmId == _vm.user.firmId
                                  ? "name-right-border"
                                  : "",
                            },
                            [_vm._v(_vm._s(_vm.customerName))]
                          )
                        : _c(
                            "span",
                            {
                              staticClass: "name canLink",
                              class:
                                _vm.jobInfo.bindingProjects &&
                                Object.keys(_vm.jobInfo.bindingProjects)
                                  .length > 0 &&
                                _vm.jobInfo.recruiterFirmId == _vm.user.firmId
                                  ? "name-right-border"
                                  : "",
                              on: { click: _vm.handleCustomer },
                            },
                            [_vm._v(_vm._s(_vm.customerName))]
                          ),
                      _vm.jobInfo.bindingProjects &&
                      Object.keys(_vm.jobInfo.bindingProjects).length > 0 &&
                      _vm.jobInfo.recruiterFirmId == _vm.user.firmId
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class": "bind-project-popover",
                                placement: "bottom",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "project-name ell",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                _vm._l(
                                  _vm.jobInfo.bindingProjects,
                                  function (value, name, index) {
                                    return _c(
                                      "span",
                                      { key: "name_" + index },
                                      [
                                        index != 0
                                          ? _c("i", { key: index + name }, [
                                              _vm._v("、"),
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "project-info-item",
                                            attrs: {
                                              target: "_blank",
                                              href: `/#/ProjectDetail/${name}`,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(value.replace("$#$", "-"))
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "span",
                                { staticClass: "name" },
                                _vm._l(
                                  _vm.jobInfo.bindingProjects,
                                  function (value, name, index) {
                                    return _c("span", { key: name }, [
                                      index != 0
                                        ? _c("i", { key: index + name }, [
                                            _vm._v("、"),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "project-info-item",
                                          attrs: {
                                            target: "_blank",
                                            href: `/#/ProjectDetail/${name}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(value.replace("$#$", "-"))
                                          ),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
            _vm.latestBindingProjects.isShowHRInfo &&
            _vm.latestBindingProjects.hrName
              ? _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom-start",
                      width: "240",
                      trigger: "hover",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "hr-info",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _c("i", { staticClass: "el-icon-user" }),
                        _vm._v("HR 信息\n                    "),
                        _c("span", { staticClass: "tip" }, [
                          _vm._v("仅自己可见"),
                        ]),
                      ]
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 6 } }, [_vm._v("姓名:")]),
                        _c("el-col", { attrs: { span: 18 } }, [
                          _vm._v(
                            _vm._s(_vm.latestBindingProjects.hrName || "暂无")
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 6 } }, [_vm._v("手机:")]),
                        _c("el-col", { attrs: { span: 18 } }, [
                          _vm._v(
                            _vm._s(_vm.latestBindingProjects.hrPhone || "暂无")
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 6 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getContactTypeText(
                                _vm.latestBindingProjects.hrWeChat
                              )
                            ) + ":"
                          ),
                        ]),
                        _c("el-col", { attrs: { span: 18 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getWeChat(
                                _vm.latestBindingProjects.hrWeChat
                              ) || "暂无"
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 6 } }, [_vm._v("职位:")]),
                        _c("el-col", { attrs: { span: 18 } }, [
                          _vm._v(
                            _vm._s(_vm.latestBindingProjects.hrTitle || "暂无")
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 6 } }, [_vm._v("邮箱:")]),
                        _c("el-col", { attrs: { span: 18 } }, [
                          _vm._v(
                            _vm._s(_vm.latestBindingProjects.hrEmail || "暂无")
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("recommend-dialog", { ref: "recommendDialog", attrs: { type: 1 } }),
      _c("ban-dialog", { ref: "banDialog" }),
      _vm.hasPrepayment
        ? _c("prepayment-dialog", {
            ref: "prepaymentDialog",
            attrs: {
              "prepayment-amount":
                _vm.jobInfo.jobPrepaymentViewModel.prepaymentAmount,
              "job-id": _vm.jobInfo.id,
            },
            on: { "prepayment-success": _vm.refresh },
          })
        : _vm._e(),
      _c("job-share-edit-dialog", {
        ref: "jobShareEditDialog",
        on: {
          shareJobCard: _vm.showShareJobCardDialog,
          firstEditPersonalInfo: _vm.showJobPersonalInfoDialog,
        },
      }),
      _vm.isFirstAddMyShareJob
        ? _c("job-personal-info-dialog", {
            ref: "jobPersonalInfoDialog",
            on: { personalInfoEditComfirm: _vm.updatePersonalInfo },
          })
        : _vm._e(),
      _c("share-job-card-dialog", { ref: "shareJobCardDialog" }),
      _c("media-record-entry", {
        ref: "mediaRecordEntry",
        on: { "record-media-success": _vm.recordMediaSuccess },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "637px",
            visible: _vm.recordIntroShow,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            "custom-class": "record-introduce-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.recordIntroShow = $event
            },
          },
        },
        [
          _c("span", {
            staticClass: "close-record-intro-btn",
            on: { click: _vm.hideRecordIntro },
          }),
          _c("div", { staticClass: "record-introduce-content" }, [
            _c("h5", { staticClass: "record-introduce-title" }, [
              _vm._v("\n                什么是“职播”？\n            "),
            ]),
            _c("p", { staticClass: "record-introduce-text" }, [
              _vm._v(
                "\n                类似于淘宝的直播讲解货品，pm也可以对自己的"
              ),
              _c("span", { staticStyle: { color: "#38bc9d" } }, [
                _vm._v("职位进行讲解"),
              ]),
              _vm._v("；"),
              _c("br"),
              _vm._v("让顾问对职位理解更到位，推荐更精准；\n            "),
            ]),
          ]),
          _c("div", { staticClass: "record-introduce-content" }, [
            _c("h5", { staticClass: "record-introduce-title" }, [
              _vm._v("\n                使用场景\n            "),
            ]),
            _c("p", { staticClass: "record-introduce-text" }, [
              _vm._v(
                "\n                1.线下进行项目讲解时，点击职播进行录音/录制后上传；"
              ),
              _c("br"),
              _vm._v(
                "2.职位有要求变动或者任何需求要补充的时候，可快速发布一条职播进行讲解。\n            "
              ),
            ]),
          ]),
        ]
      ),
      _vm.isShowRecordGuide
        ? _c("div", { staticClass: "job-record-guide-modal" })
        : _vm._e(),
      _vm.jobInfo.isMyJob
        ? _c("bind-department-dialog", {
            ref: "bindDepartment",
            attrs: { jobInfo: _vm.jobInfo, customerId: _vm.firmId },
            on: { "bind-department-confirm": _vm.refresh },
          })
        : _vm._e(),
      _c("job-bind-project-dialog", {
        ref: "jobBindProjectDialog",
        attrs: {
          jobId: _vm.jobInfo.id,
          customerId: _vm.firmId,
          customerDepartmentId: _vm.jobInfo.customerDepartmentId,
        },
      }),
      _c("famous-company-dialog", {
        ref: "famousCompanyDialog",
        on: { refresh: _vm.refresh },
      }),
      _c("transfer-job-dialog", {
        ref: "transferJobDialog",
        attrs: { isAllMembers: true, isAdminTransfer: true },
        on: { "update-list": _vm.refresh },
      }),
      _c("grab-result-dialog", {
        ref: "grabResultDialog",
        on: {
          "recommend-btn-callback": _vm.handleRecommendBtnCallback,
          "grab-dialog-close": _vm.grabDialogClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }