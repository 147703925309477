var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "invite-headhunter",
      attrs: {
        width: "660px",
        title: "邀请猎头抢单",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: function ($event) {
          return _vm.reset()
        },
      },
    },
    [
      _c("div", { staticClass: "invite-job-info" }, [
        _c(
          "a",
          {
            staticClass: "job-link",
            attrs: {
              href: `/Headhunting/MyCompany.html#/Job/${_vm.jobInfo.id}`,
              target: "_blank",
            },
          },
          [
            _c("i", { staticClass: "job-link-icon el-icon-link" }),
            _vm._v(
              "「" +
                _vm._s(_vm.jobInfo.name) +
                "-" +
                _vm._s(_vm.jobInfo.firmShortName) +
                "」 \n        "
            ),
          ]
        ),
      ]),
      _c(
        "el-tabs",
        {
          staticClass: "headhunter-tab",
          attrs: { value: _vm.jobInfo.isCooperativeJob ? "4" : "1" },
          on: { "tab-click": _vm.changeHeadhunterTab },
        },
        _vm._l(_vm.headhunterTabs, function (tab) {
          return _c(
            "el-tab-pane",
            { key: tab.value, attrs: { label: tab.name, name: tab.value } },
            [
              tab.value == "4" || tab.value == "1"
                ? _c(
                    "div",
                    { staticClass: "headhunter-tab-content" },
                    [
                      _c("el-input", {
                        staticClass: "input-with-select",
                        attrs: {
                          placeholder: "按职位、昵称搜索",
                          maxlength: "30",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getHeadhunters(false)
                          },
                        },
                        model: {
                          value: _vm.searchInput,
                          callback: function ($$v) {
                            _vm.searchInput = $$v
                          },
                          expression: "searchInput",
                        },
                      }),
                      _c("el-button", {
                        staticClass: "search-btn",
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getHeadhunters(false)
                          },
                        },
                        slot: "append",
                      }),
                      tab.value == "4"
                        ? _c("p", { staticClass: "invite-note" }, [
                            _vm._v(
                              "\n                    为您推荐可能感兴趣的猎头\n                "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.headhunterList.length > 0
                ? _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "headhunter-list",
                    },
                    _vm._l(_vm.headhunterList, function (headhunter, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "headhunter-item" },
                        [
                          _c("div", { staticClass: "headhunter-detail" }, [
                            _c(
                              "div",
                              { staticClass: "headhunter-item-left" },
                              [
                                _c("avatar", {
                                  staticClass: "headhunter-avatar",
                                  attrs: {
                                    size: "mg",
                                    src: headhunter.avatarUrl,
                                    userId: headhunter.userId,
                                    enableCard: true,
                                    enableLink: true,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "headhunter-item-middle" },
                              [
                                _c("span", { staticClass: "headhunter-name" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(headhunter.realName) +
                                      "\n                                "
                                  ),
                                  _c("span", { staticClass: "text-grey" }, [
                                    _vm._v("@" + _vm._s(headhunter.nickName)),
                                  ]),
                                ]),
                                _c("span", { staticClass: "headhunter-info" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(headhunter.firmName) +
                                      " " +
                                      _vm._s(headhunter.title) +
                                      "\n                            "
                                  ),
                                ]),
                                !_vm.jobInfo.isCooperativeJob
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "headhunter-recommend-data",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                在\n                                "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-primary" },
                                          [
                                            _vm._v(
                                              _vm._s(headhunter.jobCategoryName)
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                                上：推荐 " +
                                            _vm._s(
                                              headhunter
                                                .recomendationStatusStatistics
                                                .recommendationCount
                                            ) +
                                            " 面试 " +
                                            _vm._s(
                                              headhunter
                                                .recomendationStatusStatistics
                                                .interviewCount
                                            ) +
                                            " offer " +
                                            _vm._s(
                                              headhunter
                                                .recomendationStatusStatistics
                                                .offerCount
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "headhunter-item-right" },
                              [
                                headhunter.hasOrdered
                                  ? _c(
                                      "el-button",
                                      { staticClass: "invite-btn has-invited" },
                                      [
                                        _vm._v(
                                          "\n                                已抢单\n                            "
                                        ),
                                      ]
                                    )
                                  : headhunter.hasInvited
                                  ? _c(
                                      "el-button",
                                      { staticClass: "invite-btn has-invited" },
                                      [
                                        _vm._v(
                                          "\n                                已邀请\n                            "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        staticClass: "invite-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showMessageInput(
                                              headhunter
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                邀请\n                            "
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _vm.headHunterId == headhunter.userId
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.sendLoading,
                                      expression: "sendLoading",
                                    },
                                  ],
                                  staticClass: "invite-message",
                                },
                                [
                                  _vm._v(
                                    "\n                        发送邀请给 " +
                                      _vm._s(_vm.headhunterRealName) +
                                      "\n                        "
                                  ),
                                  _c("span", { staticClass: "text-grey" }, [
                                    _vm._v(
                                      "@" + _vm._s(_vm.headhunterNickName)
                                    ),
                                  ]),
                                  _c("el-input", {
                                    staticClass: "invite-message-input",
                                    class: _vm.inviteInputError
                                      ? "invite-error"
                                      : "",
                                    attrs: {
                                      type: "textarea",
                                      rows: 3,
                                      maxlength: "200",
                                      resize: "none",
                                    },
                                    on: { change: _vm.inviteMessageInput },
                                    model: {
                                      value: _vm.inviteInput,
                                      callback: function ($$v) {
                                        _vm.inviteInput = $$v
                                      },
                                      expression: "inviteInput",
                                    },
                                  }),
                                  _vm.inviteInputError
                                    ? _c(
                                        "p",
                                        { staticClass: "invite-error-note" },
                                        [
                                          _vm._v(
                                            "\n                            邀请内容不能为空\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "edit-btn edit-btn-confirm",
                                      on: {
                                        click: function ($event) {
                                          return _vm.inviteHeadhunter(
                                            headhunter.userId,
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("发送")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "edit-btn edit-btn-cancel",
                                      on: { click: _vm.reset },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "no-data" }, [
                    _c("span", { staticClass: "empty-img" }),
                    _c("p", { staticClass: "empty-data" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]),
              !_vm.loading && !_vm.noMore
                ? _c(
                    "div",
                    {
                      staticClass: "get-more",
                      on: {
                        click: function ($event) {
                          return _vm.getHeadhunters(true)
                        },
                      },
                    },
                    [
                      _vm._v("\n                加载更多\n                "),
                      _c("font-icon", {
                        staticClass: "get-more-icon",
                        attrs: { href: "#icon-spread-up-copy" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }