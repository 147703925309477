<template>
    <div class="job-detail-head">
        <div class="head-page-main">
            <div class="name-row">
                <div class="name_tags_wrap">
                    <span class="name_loca ell">
                        <span class="name ell" :title="jobInfo.name">{{jobInfo.name}}</span>
                        <span class="loca ell" :title="jobInfo.location">{{ jobInfo.location ? `[${jobInfo.location}]`: '' }}</span>
                    </span>
                        
                        <!-- {{jobInfo.name}}
                        {{ jobInfo.location ? `[${jobInfo.location}]`: '' }}</span> -->
                    <!-- <span class="tags">
                        <span class="tag">招聘中</span>
                    </span> -->
                    <span class="tags ell" v-if="[3,4,5].includes(jobInfo.jobType)">
                        <!-- <span v-if="isNewTag" class="tag job-tag-warning">最新</span> -->
                        <!-- <span v-if="jobInfo.emergencyDegree==1" class="tag job-tag-danger">紧急</span> -->
                        <span class="JobEmergency" title="急聘" v-if="jobInfo.emergencyDegree==1">
                            <span class="hurry">
                                <font-icon href="#icon-shandian"></font-icon>    
                            </span>急聘
                        </span>
                        <span v-if="jobInfo.difficultDegree==1" class="tag job-tag-danger">较难</span>
                        <!-- 招聘状态标签 -->
                        <template v-if="jobInfo.approvedStatus != '2'">
                            <span v-if="jobInfo.status==1" class="recruiting" >
                                <span class="hurry">
                                    <font-icon href="#icon-a-bianzu11"></font-icon>    
                                </span>招聘中
                            </span>
                            <span v-else class="tag" :class="`job-tag-${jobStatus[jobInfo.status]?.theme}`">{{jobStatus[jobInfo.status]?.text}}</span>
                        </template>

                        <span class="tag job-tag-info" v-if="jobInfo.jobType">{{(jobInfo.jobType=='3' || jobInfo.jobType=='4') ? (jobInfo.jobType=='3' ? 'HR' : 'A2A') : '代运营'}}</span>
                        
                        <!-- 职级标签 -->
                        <!-- <span class="tag job-tag-primary" v-if="jobInfo.customerGrades">{{jobInfo.customerGrades}}</span> -->
                        
                        <span class="tag job-tag-primary" v-if="jobInfo.expectedRank">{{jobInfo.expectedRank}}</span>
                    </span>
                    <span class="tags" v-else>
                        <el-tooltip
                            v-for="item in jobInfo.activityTags"
                            :key="item.id"
                            effect="light"
                            placement="right"
                            :disabled="!item.description"
                            v-show="item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'"
                            popper-class="job-tag-description"
                        >
                            <div slot="content" v-html="item.description"></div>
                            <span class="JobEmergency" title="急聘" v-if="item.id == 'JobEmergency'">
                                <span class="hurry">
                                    <font-icon href="#icon-shandian"></font-icon>    
                                </span>急聘
                            </span>
                            <span v-else class="tag" :class="`job-tag-${jobActivityTag[item.id].theme}`" >
                                {{item.name == 'New' ? '最新': item.name}}
                            </span>
                        </el-tooltip>

                        <!-- <template v-if="jobInfo.status==1">
                            <span v-if="jobInfo.status==1 && jobInfo.approvedStatus != '2'" class="recruiting" >
                                <span class="hurry">
                                    <font-icon href="#icon-a-bianzu11"></font-icon>    
                                </span>招聘中
                            </span>
                        </template>
                        <template v-else> -->
                            <span v-if="jobInfo.status && !jobInfo.isSleeping" class="tag" :class="`job-tag-${jobStatus[jobInfo.status].theme}`">{{jobStatus[jobInfo.status].text}}</span>

                        <span class="tag job-tag-primary" v-if="jobInfo.expectedRank">{{jobInfo.expectedRank}}</span>
                    </span>
                    <span class="sleeping-state" v-if="jobInfo.isSleeping">
                        <span v-if="!jobInfo.isMyJob">
                            <el-tooltip class="item" effect="light" content="发布者已30天未登录，快去关注其他职位吧" placement="top">
                                <font-icon class="job-font-icon" href="#icon-snore"></font-icon>沉睡职位
                            </el-tooltip>
                        </span>
                        <span v-else>
                            <font-icon class="job-font-icon" href="#icon-snore"></font-icon>沉睡职位
                        </span>
                    </span>
                    <span class="new-activating-state" v-if="canActivateJob">
                        <el-button @click="activateJob" class="btn-activate" v-loading="activateLoading">激活</el-button>
                        <!-- <span class="activite-tip" v-if="isCooperativeJob">+20积分</span> -->
                    </span>

                </div>

                <el-dropdown trigger="click" v-if="approvedStatus != 2 && approvedStatus != 4">
                    <span class="more">
                        <font-icon href="#icon-set"></font-icon>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="more-dropdown-menu no-arrow">
                        <el-dropdown-item v-if="jobInfo.status!=4">

                            <el-popover
                                placement="left"
                                width="230"
                                trigger="hover"
                                popper-class="share-popover"
                            >   
                                <div class="item-wrap" slot="reference">
                                    <font-icon
                                        class="item-icon"
                                        href="#icon-share1"
                                    ></font-icon>
                                    <span class="item-text">分享</span>
                                </div>
                                <button class="share-btn" @click="shareToIM">
                                    <img :src="require('#/assets/images/instantMessage/private_message.png')" /> 私信和群分享
                                </button>
                                <button class="share-btn" 
                                    @click="shareToWeChat" 
                                    v-if="!isFromAuthorization && jobInfo.recruiterFirmId === user.firmId">
                                    <img :src="require('#/assets/images/instantMessage/wechat.png')" />
                                    分享到微信
                                </button>
                                <button class="share-btn" 
                                    @click="addToMyJobCard" 
                                    v-if="!isFromAuthorization && jobInfo.recruiterFirmId === user.firmId">
                                    <img :src="require('#/assets/images/instantMessage/user-card.png')" />
                                    添加到我的职位名片
                                </button>
                            </el-popover>
                        </el-dropdown-item>
                        
                        <el-dropdown-item 
                            v-if="jobInfo.recruiterFirmId == user.firmId && jobInfo.customerDepartmentId && jobInfo.customerDepartmentId != '0' && jobInfo.status!=4" 
                            @click.native="showBindProjectDialog">
                            <div class="item-wrap">
                                <font-icon
                                    class="item-icon"
                                    href="#icon-survey"
                                ></font-icon>
                                <span class="item-text">绑定项目</span>
                            </div>
                        </el-dropdown-item>
                        <!-- <el-dropdown-item
                            v-if="(currentJobType != 3 && jobInfo.status == 1 && jobInfo.canGetOrder && !jobInfo.isMyOrder && !jobInfo.isMyJob) || ([3,5].includes(currentJobType) && !jobInfo.isMyOrder)"
                            @click.native="acceptClick">
                            <div class="item-wrap">
                                <font-icon
                                    class="item-icon"
                                    href="#icon-operation"
                                ></font-icon>
                                <span class="item-text">抢单</span>
                            </div>
                        </el-dropdown-item> -->
                        
                        <el-dropdown-item 
                            v-if="(jobInfo.isMyJob || jobInfo.canPublishToMarket) && !jobInfo.isSleeping && !isFromAuthorization && jobInfo.recruiterId == user.id">
                            <my-job-operate
                                :jobInfo="jobInfo"
                                :customerId="firmId" 
                                :customerDepartmentId="jobInfo.customerDepartmentId"
                                :isAdministrator="user.isAdministrator"
                                @refresh="refresh">
                            </my-job-operate>
                        </el-dropdown-item>
                        
                        <el-dropdown-item 
                            v-if="jobInfo.isMyJob && user.isCFUser && (jobInfo.publicJobId || (!jobInfo.publicJobId && jobInfo.status == 1))"
                            @click.native="showFamousCompanyDialog">
                            <div class="item-wrap">
                                <font-icon
                                    class="item-icon"
                                    href="#icon-electronics"
                                ></font-icon>
                                <template v-if="jobInfo.publicJobId">
                                    <span class="item-text">查看大厂职位</span>
                                </template>
                                <template v-if="!jobInfo.publicJobId && jobInfo.status == 1">
                                    <span class="item-text">发布到大厂机会</span>
                                </template>
                            </div>
                        </el-dropdown-item>

                        <template v-if="!isFromAuthorization">
                            <el-dropdown-item
                                v-if="((jobInfo.jobType == 2 &&jobInfo.isPublic == false) || jobInfo.acceptedHeadhuntersCount == 0) && canEdit"
                                @click.native="handleEdit">
                                <div class="item-wrap">
                                    <font-icon
                                        class="item-icon"
                                        href="#icon-edit1"
                                    ></font-icon>
                                    <span class="item-text">编辑</span>
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item 
                                v-if=" !jobInfo.isSleeping && (jobInfo.isMyJob || (isHeadhunter && jobInfo.isMyOrder)) && jobInfo.status!=4"
                                @click.native="showTransferJobDialog"
                                >
                                <div class="item-wrap">
                                    <font-icon
                                        class="item-icon"
                                        href="#icon-export1"
                                    ></font-icon>
                                    <span class="item-text">移交</span>
                                </div>
                            </el-dropdown-item>
                        </template>
                     
                        
                        <el-dropdown-item 
                            v-if="jobInfo.isMyJob && approvedStatus!=2 && jobInfo.status!=4"
                            @click.native="handleBindDepartment">
                            <div class="item-wrap">
                                <font-icon
                                    class="item-icon"
                                    href="#icon-zuzhijiagou"
                                ></font-icon>
                                <span class="item-text">绑定组织架构</span>
                            </div>
                        </el-dropdown-item> <!-- -->
                        
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="salary-row">
                <div class="sa-l">
                    <p class="sa-row">
                        <span class="salary" v-if="currentJobType == 3">
                            {{jobInfo.minSalary == 0 && jobInfo.maxSalary == 0 ? '面议' : jobInfo.minSalary + 'K-' + jobInfo.maxSalary + 'K'}}
                        </span>
                        <span class="salary" v-else>{{jobInfo.salary == '0K-0K' ? '面议' : jobInfo.salary}}</span>

                        <span class="icon-wrap">
                            <span class="info-icon">
                                <font-icon
                                    href="#icon-time1"
                                ></font-icon>
                            </span>
                            {{jobInfo.yearOfExperience}}
                        </span>
                        <span class="icon-wrap">
                            <span class="info-icon">
                                <font-icon
                                    href="#icon-work"
                                ></font-icon>
                            </span>
                            {{jobInfo.degree}}
                        </span>
                        <span class="icon-wrap">
                            <span class="info-icon">
                                <font-icon
                                    href="#icon-user1"
                                ></font-icon>
                            </span>
                            需{{jobInfo.recruitingCount}}人
                        </span>
                        <span class="icon-wrap">
                            <span class="info-icon">
                                <font-icon
                                    href="#icon-modular"
                                ></font-icon>
                            </span>
                            {{ mapPath(categoryMap, jobInfo.categoryId) }}
                        </span>
                    </p>

                    <p class="sa-row" v-if="!applicationId">
                        <template v-if="![3,5].includes(currentJobType)">
                            <span class="percent">佣金 {{commissionValue }}</span>
                        </template>
                        <template v-if="[3,5].includes(currentJobType) && jobInfo.commissionType == '0'" >
                            <span class="percent">佣金 
                                {{ jobInfo.minSalary == 0 && jobInfo.maxSalary == 0 ? `按比例(${jobInfo.commissionValue}%)` : ((jobInfo.minSalary * 12 * jobInfo.commissionValue / 100).toFixed(2) + 'K - ' + (jobInfo.maxSalary * 12 * jobInfo.commissionValue / 100).toFixed(2) + 'K(' + jobInfo.commissionValue + '%)') }}
                            </span>
                        </template>
                        <template v-if="[3,5].includes(currentJobType) && jobInfo.commissionType == '1'" >
                            <span class="percent">佣金 {{ jobInfo.commissionTypeValue + 'K' }}</span>
                        </template>

                        <span class="promise-time">
                            保证期<span class="month">{{jobInfo.guaranteePeriod}}</span>个月 
                        </span>
                    </p>
                    <p class="sa-row" v-else>
                        <span v-if="approvedStatus == '2' || approvedStatus == '4' || approvedStatus == '0'">
                            <!-- 选择的签约方式为按比例 -->
                            <template v-if="jobInfo.publicCommissionType == '0'" >
                                <span class="percent">佣金 
                                    <template v-if="jobInfo.jobType == 4">
                                        {{ jobInfo.minSalary == 0 && jobInfo.maxSalary == 0 ? `按比例(${jobInfo.commissionValue}%)` : ((jobInfo.minSalary * 12 * jobInfo.publicCommissionValue / 100).toFixed(2) + 'K - ' + (jobInfo.maxSalary * 12 * jobInfo.publicCommissionValue / 100).toFixed(2) + 'K(' + jobInfo.publicCommissionValue + '%)') }}
                                    </template>
                                    <template v-else>
                                        {{ jobInfo.minSalary == 0 && jobInfo.maxSalary == 0 ? `按比例(${jobInfo.commissionValue}%)` : ((jobInfo.minSalary * 12 * jobInfo.commissionValue / 100).toFixed(2) + 'K - ' + (jobInfo.maxSalary * 12 * jobInfo.commissionValue / 100).toFixed(2) + 'K(' + jobInfo.publicCommissionValue + '%)') }}
                                    </template>
                                </span>
                            </template>
                            <!-- 选择的签约方式为固定佣金 -->
                            <template v-if="jobInfo.publicCommissionType == '1'" >
                                <span class="percent">佣金 {{ jobInfo.publicCommissionValue + 'K' }}</span>
                            </template>
                        </span>
                        <span v-if="approvedStatus == '3'">
                            <!-- 选择的签约方式为按比例 -->
                            <template v-if="jobInfo.publicCommissionType == '0'" >
                                <span class="percent">佣金 {{ jobInfo.minSalary == 0 && jobInfo.maxSalary == 0 ? `按比例(${jobInfo.commissionTypeValue}%)` : ((jobInfo.minSalary * 12 * jobInfo.commissionTypeValue / 100).toFixed(2) + 'K - ' + (jobInfo.maxSalary * 12 * jobInfo.commissionTypeValue / 100).toFixed(2) + 'K(' + jobInfo.commissionTypeValue + '%)') }}</span>
                            </template>
                            <!-- 选择的签约方式为固定佣金 -->
                            <template v-if="jobInfo.publicCommissionType == '1'" >
                                <span class="percent">佣金 {{ jobInfo.commissionTypeValue + 'K' }}</span>
                            </template>
                        </span>
                    </p>
                </div>

                <div class="sa-r">
                    <template v-if="approvedStatus == 2">
                        <button 
                            class="btn share">
                            职位审批中
                        </button>
                    </template>
                    <template  v-else-if="approvedStatus == 4">
                        <button
                            class="btn share"
                            @click="reApply">
                            重新申请
                        </button>
                        <el-popover
                            placement="bottom"
                            width="200"
                            trigger="hover"
                            :content="jobInfo.remark || '暂无'">
                            <span slot="reference" class="fail-tip">
                                <span class="fail-tip-main">
                                    <i class="el-icon-warning-outline"></i>
                                    审批未通过，查看原因
                                </span>
                                <br />
                                <span class="gray">可编辑职位重新申请</span>
                            </span>
                        </el-popover>
                    </template>
                    <template v-else>
                        <button
                            v-if="(currentJobType != 3 && jobInfo.status == 1 && jobInfo.canGetOrder && !jobInfo.isMyOrder && !jobInfo.isMyJob) || ([3,5].includes(currentJobType) && !jobInfo.isMyOrder)"
                            class="btn grab"
                            @click="acceptClick"
                            v-loading="headBtnLoading"
                        >
                            立即抢单
                        </button>
                        <button v-if="jobInfo.status == 1 && (jobInfo.isMyOrder || jobInfo.isMyJob)" class="btn share" @click="recommendClick">推荐候选人</button>
                    </template>
                    <!-- <button class="btn share">分享</button>
                    <button class="btn grab"
                        v-if="(currentJobType != 3 && jobInfo.status == 1 && jobInfo.canGetOrder && !jobInfo.isMyOrder && !jobInfo.isMyJob) || ([3,5].includes(currentJobType) && !jobInfo.isMyOrder)" 
                        @click="acceptClick"
                        v-loading="headBtnLoading">立即抢单</button> -->
                </div>
            </div>
            <div class="num-row">
                <div class="nums-wrap" v-if="[3,5].includes(currentJobType)">
                    <div class="num-cell">
                        <span class="count">{{jobInfo.floatingInfo ? jobInfo.floatingInfo.recommendationCount : 0}}</span>推荐
                    </div>
                    <div class="num-cell">
                        <span class="count">{{jobInfo.floatingInfo ? jobInfo.floatingInfo.interviewCount : 0}}</span>面试
                    </div>
                    <div class="num-cell">
                        <span class="count">{{jobInfo.floatingInfo ? jobInfo.floatingInfo.offerCount : 0}}</span>Offer
                    </div>
                    <div class="num-cell">
                        <span class="count">{{jobInfo.floatingInfo ? jobInfo.floatingInfo.acceptedCount : 0}}</span>接受
                    </div>
                    <div class="num-cell">
                        <span class="count">{{jobInfo.floatingInfo ? jobInfo.floatingInfo.rejectedCount : 0}}</span>拒绝
                    </div>
                </div>
                <div class="nums-wrap" v-else>
                    <div class="num-cell">
                        <span class="count">{{jobInfo.recommendationCount || 0}}</span>推荐
                    </div>
                    <div class="num-cell">
                        <span class="count">{{jobInfo.interviewCount || 0}}</span>面试
                    </div>
                    <div class="num-cell">
                        <span class="count">{{jobInfo.offerCount || 0}}</span>Offer
                    </div>
                    <div class="num-cell">
                        <span class="count">{{jobInfo.acceptedCount || 0}}</span>接受
                    </div>
                    <div class="num-cell">
                        <span class="count">{{jobInfo.rejectedCount || 0}}</span>拒绝
                    </div>
                </div>

                <div class="num-r">
                    
                    <span class="update">
                        <span class="update-icon" title="更新时间">
                            <font-icon href="#icon-time1"></font-icon>
                        </span>
                        <!-- 11分钟前更新 -->
                        {{ jobInfo.updated | dateFilter }} 更新
                    </span>
                    <span class="publish-time">发布时间：{{jobInfo.created | createDateFilter}}</span>
                </div>
            </div>
            
            <div class="customer-wraper">
                <div class="customer-infos" v-if="currentJobType == 3">
                    <a class="pic" 
                        :href="`/#/Customer/${firmId}`"
                        :class="{'pointer-event-none' : !jobInfo.canViewInternalInformation}"
                        target="_blank">
                        <img :src="jobInfo.firmLogoUrl || '//hstatic.hirede.com/lbd/images/firm-default-avatar.jpg'" />
                    </a>
                    <div class="infos" :title="jobInfo.firmShortName+ '-' + jobInfo.weComDepartment">
                        <span class="name">{{jobInfo.firmShortName}}</span>
                        <!-- <span class="name">{{jobInfo.weComDepartment}}</span> -->
                        <!-- <span class="name">{{currentItemInfo.categoryName}}</span> -->
                    </div>
                </div>

                <div class="customer-infos" v-else>
                    <a class="pic"
                        :href="`/#/Customer/${firmId}`"
                        :class="{'pointer-event-none' : !jobInfo.canViewInternalInformation}"
                        target="_blank">
                        <!-- <img :src="jobInfo.firmLogoUrl || '//hstatic.hirede.com/lbd/images/firm-default-avatar.jpg'" /> -->
                        <img src="@src/assets/images/default-company.png" />
                    </a>

                    <!-- <div class="infos" v-if="[3,4,5].includes(jobInfo.jobType)">
                        <template v-if="jobInfo.jobType == 4">
                            <span class="name">{{jobInfo.customerName}}</span>
                            <span class="name">{{currentItemInfo.categoryName}}</span>
                        </template>
                        <template v-else>
                            <span class="name">{{jobInfo.definedCustomerName}}</span>
                            <span class="name">{{currentItemInfo.categoryName}}</span>
                        </template>
                    </div> -->
                    <div class="infos">
                        <span 
                            class="name" 
                            :class="jobInfo.bindingProjects && Object.keys(jobInfo.bindingProjects).length > 0 && (jobInfo.recruiterFirmId == user.firmId) ? 'name-right-border' : ''" 
                            v-if="!canFirmLink"
                        >{{customerName}}</span>
                        <span 
                            class="name canLink" 
                            :class="jobInfo.bindingProjects && Object.keys(jobInfo.bindingProjects).length > 0 && (jobInfo.recruiterFirmId == user.firmId) ? 'name-right-border' : ''" 
                            v-else 
                            @click="handleCustomer"
                        >{{customerName}}</span>

                        <el-popover
                            popper-class="bind-project-popover"
                            placement="bottom"
                            trigger="hover"
                            v-if="jobInfo.bindingProjects && Object.keys(jobInfo.bindingProjects).length > 0 && (jobInfo.recruiterFirmId == user.firmId)"
                            >
                            <span class="project-name ell"
                                slot="reference"
                                >
                                <span v-for="(value, name, index) in jobInfo.bindingProjects" :key="'name_' + index">
                                    <i :key="index + name" v-if="index != 0">、</i>
                                    <a class="project-info-item" target="_blank" :href="`/#/ProjectDetail/${name}`">{{value.replace("$#$","-")}}</a>
                                </span>
                            </span>

                            <span class="name">
                                <span v-for="(value, name, index) in jobInfo.bindingProjects" :key="name">
                                    <i :key="index + name" v-if="index != 0">、</i>
                                    <a class="project-info-item" target="_blank" :href="`/#/ProjectDetail/${name}`">{{value.replace("$#$","-")}}</a>
                                </span>
                            </span>
                        </el-popover>

                        <!-- <span class="name underline"
                            v-if="jobInfo.bindingProjects && Object.keys(jobInfo.bindingProjects).length > 0 && (jobInfo.recruiterFirmId == user.firmId)"
                            >
                            <template v-for="(value, name, index) in jobInfo.bindingProjects">
                                <i :key="index + name" v-if="index != 0">、</i>
                                <a class="project-info-item" :key="name" target="_blank" :href="`/#/ProjectDetail/${name}`">{{value.replace("$#$","-")}}</a>
                            </template>
                        </span> -->
                        <!-- <span class="arrow-icon" v-if="jobInfo.bindingProjects && Object.keys(jobInfo.bindingProjects).length > 0 && (jobInfo.recruiterFirmId == user.firmId)">
                            <font-icon
                                href="#icon-right-copy"
                            ></font-icon>
                        </span> -->
                    </div>
                </div>

                <el-popover
                    placement="bottom-start"
                    width="240"
                    trigger="hover"
                    v-if="latestBindingProjects.isShowHRInfo && latestBindingProjects.hrName"
                    >
                    <span class="hr-info" slot="reference">
                        <i class="el-icon-user"></i>HR 信息
                        <span class="tip">仅自己可见</span>
                    </span>
                    <el-row :gutter="20">
                        <el-col :span="6">姓名:</el-col>
                        <el-col :span="18">{{latestBindingProjects.hrName || '暂无'}}</el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="6">手机:</el-col>
                        <el-col :span="18">{{latestBindingProjects.hrPhone || '暂无'}}</el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="6">{{getContactTypeText(latestBindingProjects.hrWeChat)}}:</el-col>
                        <el-col :span="18">{{getWeChat(latestBindingProjects.hrWeChat) || '暂无'}}</el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="6">职位:</el-col>
                        <el-col :span="18">{{latestBindingProjects.hrTitle || '暂无'}}</el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="6">邮箱:</el-col>
                        <el-col :span="18">{{latestBindingProjects.hrEmail || '暂无'}}</el-col>
                    </el-row>
                </el-popover>
            </div>
            

        </div>

        <recommend-dialog
            :type="1"
            ref="recommendDialog"
        ></recommend-dialog>
        <ban-dialog ref="banDialog"></ban-dialog>
        <prepayment-dialog
            ref="prepaymentDialog"
            :prepayment-amount="jobInfo.jobPrepaymentViewModel.prepaymentAmount"
            :job-id="jobInfo.id"
            @prepayment-success="refresh"
            v-if="hasPrepayment"
        ></prepayment-dialog>
        <job-share-edit-dialog ref="jobShareEditDialog" @shareJobCard="showShareJobCardDialog" @firstEditPersonalInfo="showJobPersonalInfoDialog"></job-share-edit-dialog>
        <job-personal-info-dialog
            ref="jobPersonalInfoDialog"
            v-if="isFirstAddMyShareJob"
            @personalInfoEditComfirm="updatePersonalInfo"
        ></job-personal-info-dialog>
        <share-job-card-dialog ref="shareJobCardDialog"></share-job-card-dialog>
        <media-record-entry ref="mediaRecordEntry" @record-media-success="recordMediaSuccess"></media-record-entry>
        <el-dialog
            width="637px"
            :visible.sync="recordIntroShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            custom-class="record-introduce-dialog">
            <span class="close-record-intro-btn" @click="hideRecordIntro"></span>
            <div class="record-introduce-content">
                <h5 class="record-introduce-title">
                    什么是“职播”？
                </h5>
                <p class="record-introduce-text">
                    类似于淘宝的直播讲解货品，pm也可以对自己的<span style="color: #38bc9d;">职位进行讲解</span>；<br>让顾问对职位理解更到位，推荐更精准；
                </p>
            </div>
            <div class="record-introduce-content">
                <h5 class="record-introduce-title">
                    使用场景
                </h5>
                <p class="record-introduce-text">
                    1.线下进行项目讲解时，点击职播进行录音/录制后上传；<br>2.职位有要求变动或者任何需求要补充的时候，可快速发布一条职播进行讲解。
                </p>
            </div>
        </el-dialog>
        <div class="job-record-guide-modal" v-if="isShowRecordGuide"></div>
        <bind-department-dialog
            ref="bindDepartment"
            :jobInfo="jobInfo"
            :customerId="firmId"
            @bind-department-confirm="refresh"
            v-if="jobInfo.isMyJob"
        ></bind-department-dialog>
        <job-bind-project-dialog ref="jobBindProjectDialog" :jobId="jobInfo.id" :customerId="firmId" :customerDepartmentId="jobInfo.customerDepartmentId"></job-bind-project-dialog>
        <!-- <el-tooltip effect='light' placement="top" ref="tooltip" :content="tooltipContent">
            <template slot="content">123</template>
        </el-tooltip> -->
        <famous-company-dialog
            ref="famousCompanyDialog"
            @refresh="refresh"
        />

        <!-- 移交职位 -->
        <transfer-job-dialog
            ref="transferJobDialog"
            :isAllMembers="true"
            :isAdminTransfer="true"
            @update-list="refresh"
        ></transfer-job-dialog>

        <!-- 抢单成功后的弹出窗口 -->
        <grab-result-dialog
            ref="grabResultDialog"
            @recommend-btn-callback="handleRecommendBtnCallback"
            @grab-dialog-close="grabDialogClose"
            >
        </grab-result-dialog>
    </div>
</template>

<script>
    import moment from 'moment';
    import emitter from '@src/js/mixins/emitter';
    import jobActivityTag from '#/js/config/jobActivityTag.json';
    import jobStatus from '#/js/config/jobStatus.json';
    import { job as jobUrl , prepayment as prepaymentUrl, user as userUrl} from '#/js/config/api.json';
    import RecommendDialog from '#/component/common/dialog/recommendDialog.vue';
    import BanDialog from '#/views/job-detail/component/ban-dialog.vue';
    import PrepaymentDialog from '#/views/job-detail/component/prepayment-dialog.vue';
    import MyJobOperate from '#/views/job-detail/component/my-job-operate-refactor.vue';
    import JobShareEditDialog from '#/component/common/dialog/job-share-edit-dialog.vue';
    import JobPersonalInfoDialog from '#/component/common/dialog/job-personal-info-dialog.vue';
    import ShareJobCardDialog from '#/component/common/dialog/share-job-card-dialog.vue';
    import MediaRecordEntry from '#/component/mediaRecord/mediaRecordEntry.vue';
    import BindDepartmentDialog from '#/component/common/dialog/bind-department-dialog.vue';
    import JobBindProjectDialog from '../component/job-bind-project-dialog.vue';
    import FamousCompanyDialog from '../component/famous-company-dialog.vue';
    import ProjectService from "#/js/service/projectService.js";
    import {contactType} from "@src/js/config/dates/projectData.js";
    import TransferJobDialog from "#/component/common/dialog/transferJobDialog.vue";
    import GrabResultDialog from '#/views/job-list/component/grab-result-dialog.vue';
    import JobService from '#/js/service/jobService.js';
    import { findElementPath, } from '#/js/util/dataProcess.js';

    export default {
        name: "jobDetailHead",
        components: {
            RecommendDialog,
            BanDialog,
            PrepaymentDialog,
            MyJobOperate,
            JobShareEditDialog,
            JobPersonalInfoDialog,
            ShareJobCardDialog,
            MediaRecordEntry,
            BindDepartmentDialog,
            JobBindProjectDialog,
            FamousCompanyDialog,
            TransferJobDialog,
            GrabResultDialog,
        },
        mixins: [emitter],
        computed: {
            token() {
                return this.$store.state.verificationToken.verificationToken;
            },
            canActivateJob(){
                return this.jobInfo.isMyJob && this.jobInfo.isSleeping;
            },
            isCooperativeJob(){
                return this.$store.state.user.userInfo.isHeadhunter && this.jobInfo.isMyJob;
            },
            commissionValue(){
                // return this.jobInfo.commissionValue && this.jobInfo.commissionValue.indexOf('0K-0K') > -1 ? '按比例' : this.jobInfo.commissionValue;

                // const jobInfo = this.jobInfo;
                // return jobInfo.commissionType == '0' 
                // ? (jobInfo.minSalary == 0 && jobInfo.maxSalary == 0 ? `按比例(${jobInfo.commissionValue}%)` : ((jobInfo.minSalary * 12 * jobInfo.orignalCommissionValue / 100).toFixed(2) + 'K - ' + (jobInfo.maxSalary * 12 * jobInfo.orignalCommissionValue / 100).toFixed(2) + 'K(' + jobInfo.orignalCommissionValue + '%)')) : jobInfo.commissionValue + 'K';
                if(this.jobInfo.jobType == 4 && this.applicationId && this.applicationId!='undefined') {
                    return this.jobInfo.publicCommissionValue + 'K';
                }
                return this.jobInfo.commissionValue;
            },
            hasLink(){
                return this.jobInfo.isMyJob || this.jobInfo.isProjectManagerOfJob || false;
            },
            hasGuarantee() {
                return this.jobInfo.jobType == 1 || this.jobInfo.isCooperativeJob;
            },
            hasPrepayment() {
                if(this.jobInfo.activityTags) {
                    return this.jobInfo.activityTags.some(item => {
                        return item.id === 'PrepaymentJob'
                    })
                }
                return false;
            },
            averageFeedbackHours() {
                let str = parseFloat(this.jobInfo.lastestFeedbackDate) < 24 ? '小时' : '天';
                return (this.jobInfo.averageFeedbackHours + str)
            },
            user() {
                return this.$store.state.user.userInfo;
            },
            firmId() {
                let firmUrl = this.jobInfo.firmDetailUrl;
                if(firmUrl) {
                    return firmUrl.replace('/MyCompany/Customer/', '').replace('/Detail', '');
                }else {
                    return '';
                }
            },
            canFirmLink() {
                if(this.jobInfo.customerDepartmentId) {
                    return this.jobInfo.canViewInternalInformation && this.jobInfo.isLeafNode;
                } else {
                    return this.jobInfo.canViewInternalInformation
                }
            },
            customerName() {
                let name = this.jobInfo.departmentPath || this.jobInfo.firmShortName;
                let enc = new TextEncoder("utf-8");
                if(this.jobInfo.jobType ==4) {
                    return this.jobInfo.customerName;
                } else if(this.jobInfo.jobType == 2) {
                    return this.jobInfo.departmentPath || this.jobInfo.customerName;
                }
                if(enc.encode(name).length > 34) {
                    let firstParent = "";
                    if(this.jobInfo.departmentPath) {
                        firstParent = name.slice(0, name.indexOf("-"));
                        if(firstParent.length > 10) {
                            firstParent = name.slice(0, 10);
                        }
                    } else {
                        firstParent = name.slice(0, 10);
                    }
                    return firstParent + " ··· ";
                }
                return name;
            },
            firmLink() {
                if(this.jobInfo.customerDepartmentId) {
                    // return this.jobInfo.canViewInternalInformation && this.jobInfo.isLeafNode ? `/Headhunting/MyCompany.html#/Customer/${this.firmId}/department/${this.jobInfo.customerDepartmentId}` : "javascript:void(0)";
                    return this.jobInfo.canViewInternalInformation && this.jobInfo.isLeafNode ? `/Headhunting/#/Customer/${this.firmId}/department/${this.jobInfo.customerDepartmentId}` : "javascript:void(0)";
                } else {
                    // return this.jobInfo.canViewInternalInformation ? `/Headhunting/MyCompany.html#/Customer/${this.firmId}` : "javascript:void(0)"
                    return this.jobInfo.canViewInternalInformation ? `/Headhunting/#/Customer/${this.firmId}` : "javascript:void(0)"
                }
            },
            approvedStatus() {
                return this.$route.query.approvedStatus;
            },
            applicationId() {
                return this.$route.query.applicationId;
            },
            editJobDetail() {
              return this.$route.query.editJobDetail == 'undefined' ? null: this.$route.query.editJobDetail;
            },
            isHeadhunter() {
                return this.$store.state.user.userInfo.isHeadhunter || false;
            },
            canEdit() {
                return (
                    (!this.jobInfo.isFromAuthorization &&
                        this.jobInfo.isMyJob &&
                        (this.jobInfo.status == 1 || this.jobInfo.status == 2)) ||
                    false
                );
            },
        },
        props:{
            currentJobType: Number,
            jobInfo: Object,
            shareData: Object,
            canMediaRecord: {
                type: Boolean,
                default: false
            },
            isFromAuthorization: Boolean
        },
        data() {
            return {
                showIntegralTip: true,
                // curRecommendJobType: '', // 当前推荐职位类型，1为HR直招职位
                jobStatus: jobStatus,
                jobActivityTag: jobActivityTag,
                activateLoading: false,
                headBtnLoading: false,
                showShareToImGuide: false,
                isFirstAddMyShareJob: false,
                recordIntroShow: false,
                isShowRecordGuide: false,
                tooltipContent: '',
                useProjectPopover: false,
                latestBindingProjects: {},
                bindingProjects: [],
                projectPopoverWidth: 460, 

                // currentItemInfo: {},
                categoryMap: null,
            }
        },
        filters: {
            hourToDay(val) {
                let hour = parseInt(val);
                if(hour < 24) {
                    return hour + '小时';
                } else {
                    return Math.floor(hour / 24) + '天';
                }
            },
            createDateFilter(val) {
                let today = moment().format('YYYY-MM-DD'),
                    dateDay = moment(val).format('YYYY-MM-DD');
                if(today === dateDay) {
                    return '今天';
                } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
                    return '昨天';
                } else {
                    return dateDay;
                }
            },
            dateFilter(val) {
                let now = new Date(),
                    date = val;
                let today = moment().format('YYYY-MM-DD'),
                    dateDay = moment(date).format('YYYY-MM-DD'),
                    nowTime = new Date().getTime(),
                    dateTime = new Date(date).getTime(),
                    oneHourTime = 60 * 60 * 1000,
                    hour = now.getHours(),
                    dateHours = new Date(date).getHours();
                if(today === dateDay) {
                    if (dateTime > nowTime - oneHourTime * 4 && dateTime < nowTime - oneHourTime) {
                        return (hour - dateHours) + '小时前';
                    } else if (dateHours == hour || (dateTime > nowTime && dateTime < nowTime + oneHourTime) || (dateTime < nowTime && dateTime > nowTime - oneHourTime)) {  // 目标时间与当前时间
                        let minutesDiff = Math.floor((dateTime - nowTime) / 60000);
                        if(minutesDiff == 0 || minutesDiff == 1) {
                            return '刚刚';
                        } else if(minutesDiff < 0) {
                            return (minutesDiff * -1) + '分钟前';
                        }
                    } else {
                        return '今天';
                    }
                } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
                    return '昨天';
                } else {
                    return dateDay;
                }
            },
            
        },
        created() {
            // this.activateTooltip = (tooltip) => debounce(tooltip => tooltip.handleShowPopper(), 50);
            // console.log(this.jobInfo.bindingProjects);
            // for(var key in this.jobInfo.bindingProjects){
            //     console.log(key);
            //     console.log(this.jobInfo.bindingProjects[key]);
            //     console.log(this.jobInfo.bindingProjects[key].replace("$#$","-"));
            // }

            // let url = window.location.href;
            // let p = url.split('?')[1];
            // let routeParams = new URLSearchParams(p);
            // this.curRecommendJobType = routeParams.get('curRecommendJobType');
            // // console.log(this.curRecommendJobType)
        },
        mounted(){
            sessionStorage.removeItem('tabItems');
            if(sessionStorage.getItem('isjumpFromJobCreate')) {
                this.showShareToImGuide = true;
                sessionStorage.removeItem('isjumpFromJobCreate');
            }

            // 点击重新申请只编辑职位内容的情况
            if(this.editJobDetail == '1') {
              setTimeout(() => {
                eventBus.$emit('canEditJobDescription', true);
              }, 2000);
            }
            this.getHasBindList();
        },
        methods:{
            getContactTypeText(hrWeChat) {
                if(!hrWeChat) return '微信';
                if(hrWeChat.indexOf('|') > -1) {
                    let arr = hrWeChat.split("|");
                    return contactType.find(it => it.value == arr[0])?.label || '微信';
                } else {
                    return '微信';
                }
            },
            getWeChat(hrWeChat) {
                if(!hrWeChat) return '-';
                if(hrWeChat.indexOf('|') > -1) { 
                    return hrWeChat.split("|")[1];
                }
                return hrWeChat;
            },
            getHasBindList() {
                // this.loading = true;
                ProjectService.getJobAlreadyBindingProjects(this.$route.params.jobId)
                    .then((res) => {
                        console.log(`getJobAlreadyBindingProjects:`, res)
                        // res.forEach((el) => {
                        //     el.projectName = el.projectName.replace("$#$", "-");
                        // });
                        // this.hasBindList = res || [];
                        // console.log(this.hasBindList);
                        this.bindingProjects = res || [];
                        this.latestBindingProjects = res[0]|| {};
                    })
                    .finally(() => {
                        // this.loading = false;
                    });
            },
            // this.getCategoryMap();
        
            getCategoryMap() {
                let firmId = '';
                if (this.isFromAuthorization && this.jobInfo.recruiterFirmId != this.user.firmId) {
                    firmId = this.jobInfo.recruiterFirmId;
                }
                JobService.
                    getJobCategoryData(firmId)
                    .then(res => {
                        this.categoryMap = res;
                    });
            },
            mapPath(categoryMap, categoryId) {
                try {
                    let path = findElementPath(categoryMap, categoryId, 'id', 'subJobCategories');
                    path.pop();
                    let _path = path.map(it => it.name).reverse().join("-");
                    return _path || '未知';
                } catch (error) {
                    return '未知';
                }
            },
            closeTip() {
                this.showIntegralTip = false;
            },
            activateJob() {
                this.activateLoading = true;
                _request({
                    method: 'POST',
                    url: jobUrl.url_job_wakeup.replace(/\%p/ig, this.jobInfo.id),
                }).then(()=>{
                    this.activateLoading = false;
                    if(!this.isCooperativeJob){
                        shortTips("激活职位！ +20积分");
                    }
                    this.refresh();
                }).catch(err => {
                    this.activateLoading = false;
                });
            },
            gotoUrl(){
                if (!this.hasLink) {
                    return ;
                }
                let urlWithParams = `/Headhunting/?companyName=${encodeURI(this.jobInfo.firmShortName)}&jobName=${encodeURI(this.jobInfo.name)}&statusType=recommendation#/floatingList`;
                window.open(urlWithParams);
            },
            acceptClick() {
                let isRecommend = _getQueryString('isRecommend');
                let sourcefrom = _getQueryString('sourcefrom');
                if(isRecommend){
                    lbdTrack&&lbdTrack.track(
                        'getOrder',
                        {
                            jobId: this.jobInfo.id,
                            sourcefrom: sourcefrom
                        }
                    )
                }
                this.headBtnLoading = true;
                if (this.jobInfo.jobType === 2) {
                    _request({
                        method: 'POST',
                        url: jobUrl.get_internal_order,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data:{
                            jobId: this.jobInfo.id,
                            __RequestVerificationToken: this.token
                        },
                        throwBusinessError: true
                    }).then(()=>{
                        this.headBtnLoading = false;
                        // shortTips('抢单成功！');
                        // location.href = `/Headhunting/MyCompany.html?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}&fromPage=GrabOrder`;
                        // location.href = `/?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}&fromPage=GrabOrder`;
                        this.$refs.grabResultDialog.show(this.jobInfo);
                        this.refresh();
                    }).catch(err => {
                        this.headBtnLoading = false;
                        switch (err.data.code) {
                            case "NotFoundFirm":
                                this.$confirm('您尚未加入公司，为更好保障您做单的权益，请加入公司。', '提示', {
                                    confirmButtonText: '加入公司',
                                    cancelButtonText: '取消'
                                }).then(() => {
                                    window.open(err.data.url);
                                }).catch(() => {});
                                break;
                            case "NotConfirmEmail":
                                this.$confirm('您的邮箱尚未验证，为您能及时获取职位信息，请验证。', '提示', {
                                    confirmButtonText: '验证邮箱',
                                    cancelButtonText: '取消'
                                }).then(() => {
                                    // window.open("/Headhunting/MyCompany.html#/emailVerify");
                                    // window.open("/Headhunting/#/personalCenter?isEmailVerify=true");
                                    window.open("/Headhunting/#/personalCenter?Tab=accountSet");
                                }).catch(() => {});
                                break;
                            case "Applying":
                                this.$alert('还未能认证您所在的公司，请等待审核通过后再抢单！', {
                                    confirmButtonText: '我知道了'
                                });
                                break;
                            case "FirmNotVerified":
                                if(this.user.firmVerificationStatus == 0) {
                                    this.$alert('市场职位抢单需要公司的信息被审核验证', '提示', {
                                        confirmButtonText: '申请认证',
                                        callback: action => {
                                            if(action == 'conform') {
                                                window.open("/Headhunting/Firm/Verify");
                                            }
                                        }
                                    });
                                } else if(this.user.firmVerificationStatus == 1) {
                                    this.$alert('你的公司信息正在审核中，请等待...', {
                                        confirmButtonText: '我知道了'
                                    });
                                }
                                break;
                            default:
                                shortTips("系统异常，请稍后重试");
                                break;
                        }
                    });
                } else if( [3,4,5].includes(this.jobInfo.jobType) ) {
                    let isConfirm = this.jobInfo.jobType === 1?true:false;
                    console.log(this.jobInfo.jobType);
                    _request({
                        url: "/openapi/Job/%p/Order".replace(/\%p/ig, this.jobInfo.id),
                        method: "POST",
                        baseURL: "LbdOpenApi",
                        data: {
                            jobId: this.jobInfo.id,
                        },
                    }).then(()=>{
                        this.headBtnLoading = false;
                        // shortTips('抢单成功！');
                        // location.href = `/Headhunting/MyCompany.html?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}&curRecommendJobType=${this.currentJobType}&marketJobType=${this.jobInfo.jobType}`;
                        // location.href = `/?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}&curRecommendJobType=${this.currentJobType}&marketJobType=${this.jobInfo.jobType}`;
                        // MyCompany.html#/market
                        this.$refs.grabResultDialog.show(this.jobInfo);
                        this.refresh();
                    }).catch(err => {
                        this.headBtnLoading = false;
                    });
                } else {
                    let isConfirm = this.jobInfo.jobType === 1?true:false;
                    console.log(this.jobInfo.jobType);
                    _request({
                        method: 'POST',
                        url: jobUrl.get_market_order,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: {
                            jobId: this.jobInfo.id,
                            confirm: isConfirm,
                            __RequestVerificationToken: this.token
                        },
                    }).then(()=>{
                        this.headBtnLoading = false;
                        // shortTips('抢单成功！');
                        // location.href = `/Headhunting/MyCompany.html?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}`;
                        // location.href = `/?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}`;
                        this.$refs.grabResultDialog.show(this.jobInfo);
                        this.refresh();
                    }).catch(err => {
                        this.headBtnLoading = false;
                    });
                }
            },
            recommendClick() {
                if (this.jobInfo.isBlocked) {
                    this.$refs.banDialog.show();
                } else {
                    _request({
                        url: userUrl.get_user_intergrity,
                        method: "GET"
                    }).then(res => {
                        let message ='';
                        if(!res.hasTitle){
                            message = '请到「我的资料」页面补充「职位」';
                        }
                        if(!res.hasAvatar){
                            message = '请到「我的资料」页面补充「个人头像」';
                        }
                        if(message){
                            this.$confirm( message, '提示', {
                                showClose: false,
                                showCancelButton:false,
                                customClass: 'comfirm-dialog-class',
                                confirmButtonText: '确定'
                            }).then(() => {
                                window.open('/#/personalCenter');
                            })
                        }else{
                            this.$refs.recommendDialog.show(this.jobInfo.id,this.jobInfo.name,this.currentJobType, this.jobInfo.jobType);
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                }
            },
            applyPrepayment() {
                if (this.jobInfo.jobPrepaymentViewModel.prepaymentResidueQuotaCount === 0) {
                    this.$refs.prepaymentDialog.cannotReason = '很遗憾，本职位预付款已被抢完';
                    this.$refs.prepaymentDialog.canApply = false;
                } else {
                    this.headBtnLoading = true;
                    _request({
                        method: 'GET',
                        url: prepaymentUrl.check_prepayment_url.replace(/\%p/ig, this.jobInfo.id),
                        throwBusinessError: true
                    }).then(res =>{
                        this.headBtnLoading = false;
                        this.$refs.prepaymentDialog.canApply = true;
                    }).catch(err =>{
                        this.$refs.prepaymentDialog.canApply = false;
                        this.$refs.prepaymentDialog.cannotReason = err.message;
                        this.headBtnLoading = false;
                        if (err.data[0] && err.data[0].code === "PermissionDenied") {
                            this.$refs.prepaymentDialog.permissionDenied = true;
                        }
                    })
                }
                this.$refs.prepaymentDialog.show();
            },
            refresh(){
                this.$emit('refresh');
            },
            shareToIM() {
                let shareData = Object.assign({ type: 'jobShare' }, this.shareData);
                this.dispatch('App', 'showShareToImDialog', shareData);
            },
            shareToWeChat() {
                this.$refs.jobShareEditDialog.show(true, {
                    jobId: this.jobInfo.id
                });
            },
            addToMyJobCard() {
                this.$refs.jobShareEditDialog.show(false, {
                    jobId: this.jobInfo.id
                });
            },
            showJobPersonalInfoDialog() {
                this.isFirstAddMyShareJob = true;
                this.$nextTick(() => {
                    this.$refs.jobPersonalInfoDialog.show(true);
                })
            },
            updatePersonalInfo() {
                //展示添加到我的职位名片
                this.$refs.jobShareEditDialog.show(false, {
                    jobId: this.jobInfo.id
                });
            },
            showShareJobCardDialog() {
                this.$refs.shareJobCardDialog.show();
            },
            handleCloseShareGuide() {
                this.showShareToImGuide = false;
            },
            showRecordMediaDialog(command) {
                let jobIds = [this.jobInfo.id];
                this.$refs.mediaRecordEntry.show(command, jobIds);
            },
            recordMediaSuccess() {
                this.$emit('record-media-success');
            },
            hideRecordGuide() {
                this.isShowRecordGuide = false;
                this.showRecordIntro();
            },
            showRecordIntro() {
                document.querySelector('#floatOption').style.zIndex = '2000';
                this.recordIntroShow = true;
            },
            hideRecordIntro() {
                this.recordIntroShow = false;
                document.querySelector('#floatOption').style.zIndex = ''
            },
            handleBindDepartment() {
                this.$refs.bindDepartment.show();
            },
            showBindProjectDialog() {
                this.$refs.jobBindProjectDialog.show();
            },
            handleTextMouseEnter(e) {
                const ele = e.target;
                const range = document.createRange();
                range.setStart(ele, 0);
                range.setEnd(ele, ele.childNodes.length);
                const rangeWidth = range.getBoundingClientRect().width;
                // if ((rangeWidth > ele.offsetWidth || ele.scrollWidth > ele.offsetWidth) && this.$refs.tooltip) {
                if ((rangeWidth > ele.offsetWidth || ele.scrollWidth > ele.offsetWidth)) {
                    this.projectPopoverWidth = parseInt(ele.offsetWidth);
                    this.useProjectPopover = true;
                    // const tooltip = this.$refs.tooltip;
                    // // TODO 会引起整个 Table 的重新渲染，需要优化
                    // this.tooltipContent = ele.innerText;
                    // tooltip.referenceElm = ele;
                    // tooltip.$refs.popper && (tooltip.$refs.popper.style.display = 'none');
                    // tooltip.doDestroy();
                    // tooltip.setExpectedState(true);
                    // // this.activateTooltip(tooltip);
                    // tooltip.handleShowPopper();
                }
            },
            handleTextMouseLeave(event) {
                // const tooltip = this.$refs.tooltip;
                // if (tooltip) {
                //     // tooltip.$refs.popper && (tooltip.$refs.popper.style.display = 'block');
                //     tooltip.setExpectedState(false);
                //     tooltip.handleClosePopper();
                // }
            },
            reApply() {
                // applicationId
              const _brother = this.$parent.$refs['jobContent'];
              if(_brother.acceptedHeadhuntersCount > 0) {
                _brother.isEditJobDescription = true;
                _brother.descriptionInput = _brother.jobInfo.description;
              } else {
                window.open(`/Headhunting/MyCompany.html#/jobCreate/single?applicationId=${this.applicationId}&jobId=${this.jobInfo.jobId}`, '_self');
              }
            },
            isNewTag() {
                let todayTime = Date.parse(new Date());
                let createTime= Date.parse(this.jobInfo.created);
                if(Math.round((todayTime - createTime) / 1000 / 60 / 60 / 24) > 3) {
                    return false;
                } else {
                    return true;
                }
            },
            showFamousCompanyDialog() {
                if(this.jobInfo.publicJobId) {
                    // this.$router.push({
                    //     path: `/opportunityJobDetail/${this.jobInfo.publicJobId}`,
                    //     query: {
                    //         jobId: this.jobInfo.id,
                    //     },
                    // });

                    location.href = `/#/opportunityJobDetail/${this.jobInfo.publicJobId}?jobId=${this.jobInfo.id}`;
                } else {
                    this.$refs['famousCompanyDialog'].show(this.jobInfo);
                }
            },
            handleEdit() {
                location.href = `/Headhunting/MyCompany.html#/jobCreate/single?jobId=${this.jobInfo.id}`;
            },
            showTransferJobDialog() {
                let transferJobs = [];
                transferJobs.push(this.jobInfo);
                this.$refs.transferJobDialog.initDialog(transferJobs);
            },
            handleRecommendBtnCallback(job) {
                this.$refs.recommendDialog.show(job.id, job.name, job.jobType, job.jobType);
            },
            grabDialogClose(job) {
                // this.$emit('updateJobItem', {
                //     ...job,
                //     isMyOrder: true,
                // });
            },
            handleCustomer() {
                // :href="`/#/Customer/${firmId}`"
                window.open(this.firmLink);
            },

        }
    }
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1400px) {
    .job-header-left {
        width: 60% !important;
    }
    .job-header-right{
        width: 30% !important;
    }
}
.job-detail-head {
    min-width: 1200px;
    // background-color: #f8fbfa;
    background-color: #ffffff;
    // border-bottom: 1px solid #ececec;
    // border-radius: 8px;
    // padding: 50px 0 60px;
    // min-height: 225px;
    .head-page-main{
        width: 1200px;
        margin: 0 auto;
        padding: 35px 0 10px;
        .name-row{
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            // justify-content: space-between;
            margin-bottom: 7px;
            .name_tags_wrap{
                flex: 1;
                display: flex;
                align-items: center;
                flex-flow: row nowrap;
                .name_loca{
                // flex: 1;

                    display: inline-block;
                    font-size: 28px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 37px;

                    // max-width: 700px;
                    // overflow: hidden;
                    // white-space: nowrap;
                    // text-overflow: ellipsis;
                    // max-width: 800px;
                    .name{
                        max-width: 500px;
                        display: inline-block;
                    }
                    .loca{
                        max-width: 300px;
                        display: inline-block;
                    }
                }
                .tags{
                    // max-width: 220px;
                    margin-left: 14px;
                    vertical-align: top;
                    margin-right: 20px;
                    white-space: nowrap;
                    .JobEmergency{
                        display: inline-block;
                        height: 20px;
                        line-height: 20px;
                        background: #FF4B4B;
                        border-radius: 3px;
                        font-size: 12px;
                        margin-right: 7px;
                        padding: 0 10px;
                        color: white;
                        .hurry{
                            color: white;
                            font-size: 10px;
                            vertical-align: top;
                        }
                    }
                    .recruiting{
                        display: inline-block;
                        height: 20px;
                        line-height: 18px;
                        background: rgba(56,188,157,0.14);
                        color: #38BC9D;
                        border: 1px solid rgba(56,188,157,0.53);
                        font-size: 12px;
                        margin-right: 7px;
                        padding: 0 10px;
                        border-radius: 3px;
                        .hurry{
                            vertical-align: top;
                            margin-right: 2px;
                            .icon{
                                vertical-align: -2px;
                            }
                        }
                    }
                    .tag{
                        display: inline-block;
                        height: 20px;
                        line-height: 18px;
                        border-radius: 3px;
                        font-size: 12px;
                        margin-right: 7px;
                        padding: 0 10px;
                        border: 1px solid #666;
                        // vertical-align: -1px;
                    }
                    .job-tag-danger{
                        border-color: #FFB6B6;
                        color: #FF4B4B;
                        background: rgba(255,75,75,0.1);
                    }
                    .job-tag-primary{
                        border-color: #38bc9d;
                        color: #38bc9d;
                    }
                    .job-tag-info{
                        border-color: #39f;
                        color: #39f;
                        background: rgba(185,208,255,0.14);
                    }
                    .job-tag-warning{
                        border-color: #FFDDA8;
                        color: #FF9E21;
                        background: rgba(255,236,199,0.7);
                    }
                }


                .sleeping-state {
                    font-size: 12px;
                    color: #b9b9b9;
                    margin: 0 6px;
                    .job-font-icon{
                        height: 20px;
                        width: 20px;
                        opacity: 0.5;
                    }
                }
                .new-activating-state {
                    font-size: 16px;
                    .btn-activate{
                        height: 28px;
                        line-height: 28px;
                        padding: 0 20px;
                        border-radius: 4px;
                        margin-right: 4px;
                        min-width: 0;
                        background-color: #38bc9d;
                        color: #fff;
                        border-color: #38bc9d;
                        &:hover{
                            background-color: #36b495;
                            border-color: #36b495;
                        }
                    }
                }


            }
            
            .more{
                align-self: flex-end;
                font-size: 19px;
                color: black;
                display: inline-block;

                // width: 25px;
                // height: 26px;
                line-height: 28px;
                cursor: pointer;
                font-size: 28px;
                &:hover{
                    color: $primary;
                }
            }
                        
        }
        .salary-row{
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            .sa-l{
                flex: 1;
                max-width: 80%;
                .sa-row{
                    margin: 0;
                    line-height: 26px;
                    display: flex;
                    align-items: center;
                    .percent{
                        font-weight: 500;
                        color: #FF9E21;
                        font-size: 14px;
                    }
                    .promise-time{
                        color: #666666;
                        margin-left: 20px;
                        margin-right: 20px;
                        font-size: 14px;
                        .month{
                            color: #FF9E21;
                            margin: 0 4px;
                        }
                    }
                    .salary{
                        font-size: 16px;
                        font-weight: 500;
                        color: #666666;
                        // line-height: 20px;
                        margin-right: 8px;
                    }
                    .icon-wrap{
                        margin-left: 13px;
                        display: inline-block;
                        max-width: 400px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #999;
                        font-size: 14px;
                        .info-icon{
                            color: #666;
                            font-size: 15px;
                            vertical-align: baseline;
                        }
                    }
                }
            }
            .sa-r{
                width: 20%;
                display: flex;
                justify-content: end;
                .btn{
                    // width: 100px;
                    height: 42px;
                    padding: 0 34px;
                    border-radius: 5px;
                    font-size: 16px;
                    // font-weight: 600;
                }
                .fail-tip{
                    color: #FF493C;
                    font-size: 14px;

                    &-main {
                        white-space: nowrap;
                    }

                    .gray{
                        display: inline-block;
                        text-align: right;
                        color: #B9B9B9;
                    }
                }
                .share{
                    color: #38BC9D;
                    border: 1px solid #38BC9D;
                    margin-right: 8px;
                    background: white;
                }
                .grab{
                    color: white;
                    background: $primary;
                }
            }
        }
        .num-row{
            margin-top: 7px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            .nums-wrap{
                background: linear-gradient(270deg, #FCFCFC 0%, #F7F8F8 100%);
                border-radius: 5px;
                height: 40px;
                width: 686px;
                

                display: flex;
                justify-content: space-around;
                align-items: center;
                .num-cell{
                
                    font-size: 14px;
                    font-weight: 500;
                    color: #999999;
                    line-height: 17px;
                    flex: 1;
                    text-align: center;
                    position: relative;
                    &::after{
                        width: 1px;
                        height: 15px;
                        background: #DCDCDC;
                        content: '';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        top: 4px;
                    }
                    &:last-child::after{
                        display: none;
                    }
                    .count{
                        font-size: 16px;
                        font-weight: 600;
                        color: #333333;
                        line-height: 20px;
                        margin-right: 5px;
                    }


                }
            }
            .num-r{
                flex: 1;
                display: flex;
                justify-content: end;
                align-items: center;
                .update{
                    display: inline-flex;
                    align-items: center;
                    .update-icon{
                        color: #666666;
                        font-size: 14px;
                        margin-right: 5px;
                    }
                    font-size: 14px;
                    font-weight: 500;
                    color: #999999;
                    line-height: 20px;
                }
                .publish-time{
                    font-size: 14px;
                    font-weight: 500;
                    color: #999999;
                    line-height: 20px;
                    margin-left: 27px;
                }

                
                // .project-info-item {
                //     color: #666;

                //     &:hover {
                //         color: $primary;
                //     }
                // }
                
            }
        }
        .customer-wraper{
            display: flex;
            justify-content: space-between;
            // margin-top: 10px;
            .customer-infos{
                flex: 1;
                max-width: 70%;

                margin-top: 9px;
                margin-bottom: 17px;
                height: 30px;
                line-height: 30px;
                display: flex;
                align-items: center;
                .pic{
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    border: 1px solid rgba(168,168,168,0.44);
                    overflow: hidden;
                    margin-right: 7px;
                    border-radius: 50%;
                }
                .pointer-event-none {
                    pointer-events: none;
                }
                .infos{
                    flex: 1;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    .name{
                        font-size: 12px;
                        font-weight: 500;
                        color: #666666;
                        line-height: 17px;
                        padding-right: 30px;
                        display: inline-block;

                        &.name-right-border {
                            border-right: 1px solid rgba(235, 235, 235, 0.92);
                        }
                        
                        // .project-info-item {
                        //     color: #666;

                        //     &:hover {
                        //         color: $primary;
                        //     }
                        // }
                    }
                    .canLink {
                        &:hover {
                            color: $primary;
                            cursor: pointer;
                        }
                    }
                    .project-name{
                        display: inline-block;
                        max-width: 560px;
                        margin-left: 30px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #666666;
                        line-height: 17px;
                        vertical-align: middle;
                        .project-info-item {
                            color: #666;
                            cursor: pointer;

                            &:hover {
                                color: $primary;
                                text-decoration: underline;
                            }
                        }
                    }
                    .underline{
                        text-decoration: underline;
                        padding-left: 30px;
                    }
                    .arrow-icon{
                        color: #666;
                        font-size: 9px;
                        vertical-align: 1px;
                        margin-left: 20px;
                    }
                    &:hover{
                        color: $primary;
                        .arrow-icon{
                            color: $primary;
                        }
                    }
                }
            }

            .hr-info{
                width: 150px;
                // margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                color: #999;
                text-align: right;
                .tip{
                    font-size: 13px;
                    color: #ff7200;
                }
            }

        }
        
    }

    .text-primary {
        color: $primary;
    }
    .text-grey {
        color: #ccc;
    }
    .head-page-main1{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        .job-header-left {
            width: 70%;
            display: flex;
            margin-left: 60px;
        }
        .job-firm{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 30px;
            .firm-logo {
                width: 195px;
                height: 94px;
                background-color: #fff;
                border: 1px solid #ececec;
                line-height: 94px;
                text-align: center;
                font-size: 0;
            }
            .firm-name {
                font-size: 16px;
                color: #333;
                // height: 40px;
                margin: 10px 0;
                max-width: 195px;
                // display: inline-flex;
                align-items: center;
                cursor: default;

                overflow : hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            a.firm-name:hover{
                color: #34b092;
                cursor: pointer;
            }
            .pointer-event-none {
                pointer-events: none;
            }
        }
        .job-head-main{
            position: relative;
            // // margin-left: 30px;
            // width: 615px;
            margin-left: 30px;
            // width: 615px;
            width: 70%;
            .job-head-title {
                width: 98%;
                font-size: 24px;
                // margin-bottom: 20px;
                margin-bottom: 10px;
                white-space: nowrap;
                height: 30px;
                & > span{
                    margin-right: 10px;
                }
                .job-detail-tags{
                    width: 48%;
                    display: inline-flex;
                    margin-bottom: 0;
                    user-select: none;
                    .job-detail-tag{
                        text-align: center;
                        font-size: 12px;
                        border: 1px solid #b9b9b9;
                        padding: 0 6px;
                        line-height: 18px;
                        border-radius: 10px;
                        color: #b9b9b9;
                        margin-right: 7px;
                        &.job-tag-danger{
                            border-color: #ff493c;
                            color: #ff493c;
                        }
                        &.job-tag-primary{
                            border-color: #38bc9d;
                            color: #38bc9d;
                        }
                        &.job-tag-info{
                            border-color: #39f;
                            color: #39f;
                        }
                        &.job-tag-warning{
                            border-color: #ff7200;
                            color: #ff7200;
                        }
                    }
                }
                .job-name {
                    // max-width: 430px;
                    max-width: 50%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #333;
                    display: inline-block;
                    vertical-align: middle;
                }
                .activite-tip {
                    color: #ff7200;
                }
                .btn-activate{
                    padding: 0 20px;
                    border-radius: 4px;
                    margin-right: 4px;
                    min-width: 0;
                    background-color: #38bc9d;
                    color: #fff;
                    border-color: #38bc9d;
                    &:hover{
                        background-color: #36b495;
                        border-color: #36b495;
                    }
                }
                .sleeping-state {
                    font-size: 14px;
                    color: #b9b9b9;
                    margin: 0 6px;
                    .job-font-icon{
                        height: 20px;
                        width: 20px;
                        opacity: 0.5;
                    }
                }

                .new-activating-state {
                    font-size: 16px;
                }
            }

            .job-customer {
                margin-bottom: 25px;
                color: #666666;
                font-size: 14px;
            }

            .job-primary {
                width: 98%;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                >span {
                    margin-left: 10px;
                    white-space: nowrap;
                    &:first-child,
                    &.convention-icon {
                        margin-left: 0;
                    }
                }
                .icon {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                    vertical-align: -2px;
                    background-position: right center;
                    background-repeat: no-repeat;
                    opacity: 0.5;
                    min-width: 0;
                    min-height: 0;
                    &.job-icon-text{
                        margin-right: 0px;
                        margin-left: 10px;
                        width: 14px;
                        height: 14px;
                    }
                    &.job-icon-salary, &.job-icon-project {
                        width: 14px;
                        height: 14px;
                    }
                }
                .major-data {
                    color: #ff7200;
                }
                .tip-icon {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    .font-icon {
                        width: 16px;
                        height: 16px;
                        margin-left: 5px;
                        opacity: 1;
                    }
                    .hover {
                        display: none;
                    }
                    &:hover {
                        .default {
                            display: none;
                        }
                        .hover {
                            display: inline-block;
                        }
                    }
                }
                .job-location, .job-address {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 290px;
                }
                .job-address {
                    max-width: 650px;
                }

                .project-info {
                    flex: 1;
                    overflow: hidden;
                    max-width: 300px;

                    &.project-info-flex {
                        display: flex;
                    }

                    .job-icon-project {
                        margin-top: 3px;
                        flex-shrink: 0;
                    }

                    > span {
                        flex: 1;
                        display: inline-block;
                        max-width: 100%;
                        height: 20px;
                        line-height: 20px;
                        overflow: hidden;

                        /deep/ .el-popover__reference {
                            display: inline-block;
                            max-width: 100%;
                        }
                    }
                }
                
            }
            .job-statistics {
                padding-top: 10px;
                .link-wrap {
                    display: inline-block;
                    cursor: pointer;
                    &:hover>span{
                        color: #34b092;
                    }
                    &.disabled {
                        cursor: default;
                    }

                    >span {
                        margin-right: 15px;
                        color: #38bc9c;
                    }
                    .link-wrap-num{
                        margin-left: 5px;
                        font-size: 16px;
                        color: #ff7200;
                    }
                }
            }
        }
        .job-owner-statistics {
            display: flex;
            margin-top: 10px;
            margin-bottom: 0;
            li {
                margin-right: 35px;
                &::before {
                    content: "";
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    vertical-align: middle;
                    margin-right: 5px;
                    background: #38bc9d;
                    border-radius: 2px;
                }
                .job-feedback-text {
                    margin-left: 6px;
                    color: #ccc;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
        .job-header-right {
            // width: 320px;
            width: 28%;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            position: relative;
        }
        .job-operate-list {
            line-height: 20px;
            width: 360px;
            display: flex;
            margin-top: 18px;

            .bind-project-btn {
                display: flex;
                align-items: center;
                color: #9b9b9b;
                cursor: pointer;
                margin-left: 20px;

                .icon {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    margin-right: 4px;
                }
            }

            .my-job-operate {
                margin-left: 20px;
            }

            .send-famous-company-btn {
                color: #9b9b9b;
                cursor: pointer;
                margin-left: 20px;

                .el-icon-position{
                    color: $primary;
                    font-weight: bold;
                }

                .tip-box {
                    cursor: auto;
                    position: absolute;
                    top: 146px;
                    .integral-tip{
                        position: relative;
                        display: flex;
                        align-items: center;
                        padding: 5px 10px;
                        background-color: #FA765D;
                        font-size: 12px;
                        color: #fff;
                        border-radius: 2px;
                        .triangle {
                            position: absolute;
                            top: -12px;
                            width: 0;
                            height: 0;
                            border-bottom: 8px solid #FA765D;
                            border-top: 6px solid transparent;
                            border-right: 8px solid transparent;
                            border-left: 8px solid transparent;
                        }
                        i {
                            cursor: pointer;
                            margin-left: 10px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .job-share-dropdown {
            .el-dropdown-link {
                color: #9b9b9b;
                position: relative;
                line-height: 20px;
                .share-icon {
                    font-size: 18px;
                    color: $primary;
                    vertical-align: top;
                }
                .share-guide {
                    position: absolute;
                    z-index: 1;
                    left: 54px;
                    top: -5px;
                    display: inline-block;
                    width: 240px;
                    height: 30px;
                    line-height: 30px;
                    padding-left: 20px;
                    font-size: 14px;
                    color: #fff;
                    background: url('~@src/assets/images/guide/img_share_to_im_bg.png') no-repeat 0 0;
                    background-size: 100% 100%;
                    vertical-align: top;
                    i {
                        position: relative;
                        top: 2px;
                        font-size: 18px;
                        margin-left: 4px;
                    }
                }
            }
        }
        .headhunter-operate-btn {
            position: relative;
            min-width: 280px;
            display: flex;
            align-items: center;
            .fail-tip{
                color: #FF493C;
                font-size: 14px;
                .gray{
                    color: #B9B9B9;
                }
            }
            .el-button {
                width: 134px;
                height: 46px;
                line-height: 44px;
                padding: 0;
                font-size: 18px;
                border: 1px solid $primary;
                margin-left: 0;
                outline: none;
                background-color: #38bc9d;
                color: #fff;
                vertical-align: top;
                margin-right: 20px;
                &:hover{
                    background-color: #36b495;
                    border-color: #36b495;
                }
            }
            .btn-warning {
                color: #ff7200;
                border: 1px solid #ff7200;
                background: none;
                &:hover{
                    background-color: #ff7200;
                    border-color: #ff7200;
                    color: #fff;
                }
            }

            .prepayment-done {
                display: inline-block;
                color: #b9b9b9;
                line-height: 46px;
                width: 134px;
                text-align: center;
                font-size: 16px;
                .icon-check {
                    margin-right: 5px;
                    width: 16px;
                    height: 16px;
                }
            }
            .el-dropdown {
                height: 46px;
            }
            .record-btn {
                background-color: #fff;
                color: $primary;
                vertical-align: top;
                &:hover{
                    background-color: #fff;
                    border-color: #36b495;
                }
            }
            .record-btn-guide {
                position: absolute;
                z-index: 4001;
                top: calc(100% + 76px);
                right: 0;
                .guide-box {
                    display: inline-block;
                    width: 340px;
                    height: 72px;
                    border: 2px dashed rgba(0, 0, 0, 0.4);
                    background-color: #fff;
                    border-radius: 12px;
                    padding: 10px;
                    position: relative;
                    .guide-content {
                        color: #666;
                        line-height: 24px;
                        font-size: 14px;
                        white-space: normal;
                        text-align: left;
                        vertical-align: unset;
                        display: inline-block;
                    }
                    .guide-btn {
                        line-height: 20px;
                        position: absolute;
                        bottom: 10px;
                        right: 18px;
                    }
                }
                .guide-arrows {
                    position: absolute;
                    top: -66px;
                    right: 48px;
                    width: 2px;
                    height: 66px;
                    border-left: 2px dashed #fff;
                    &::before {
                        content: "";
                        position: absolute;
                        top: -1px;
                        right: 0;
                        width: 18px;
                        height: 2px;
                        background-color: #fff;
                        transform: rotate(-60deg);
                        transform-origin: bottom right;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: -1px;
                        left: -2px;
                        width: 18px;
                        height: 2px;
                        background-color: #fff;
                        transform: rotate(60deg);
                        transform-origin: bottom left;
                    }
                }
            }
            .icon-question {
                width: 18px;
                height: 18px;
                display: inline-block;
                position: absolute;
                bottom: 4px;
                right: -22px;
                cursor: pointer;
                .question-hover, .question-no-hover {
                    width: 100%;
                    height: 100%;
                    min-width: unset;
                    min-height: unset;
                }
                .question-hover {
                    display: none;
                }
                &:hover{
                    .question-hover{
                        display: inline-block;
                    }
                    .question-no-hover{
                        display: none;
                    }
                }
            }
        }
    }
}
.more-dropdown-menu{
    /deep/.item-wrap{
        display: flex;
        flex-flow: row nowrap;
        font-size: 16px;
        justify-content: space-between;
        width: 150px;
        align-items: center;
        height: 44px;
        .item-icon{
            font-size: 18px;
        }
        .item-text{
            padding-left: 16px;
            flex: 1;
        }
        .item-arrow-icon{
            font-size: 12px;
        }
    }
    
}


.share-popover{
    .share-btn{
        width: 100%;
        font-size: 14px;
        color: #333333;
        border: none;
        text-align: left;
        margin-bottom: 6px;
        >img{
            width: 35px;
            height: 35px;
            margin-right: 10px;
        }
        &:hover{
            background: #fafafa;
        }
    }
}

</style>
<style lang="scss">
    .record-dropdown-menu {
        width:134px;
        text-align: center;
    }
    .job-tag-description.el-tooltip__popper.is-light {
        margin-left: 7px;
        color: #666;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        .popper__arrow {
            border: 6px solid transparent;
            border-left: none;
            border-right-color: rgba(0, 0, 0, 0.2);
            &::after {
                border-right-color: #fff;
            }
        }
    }
    .commission-rate-text.el-tooltip__popper.is-light {
        margin-left: 8px;
        margin-top: 40px;
        .popper__arrow {
            display: none;
        }
    }
    .job-share-menu.el-dropdown-menu {
        margin-top: 4px;
        padding: 0;
        .popper__arrow {
            display: none;
        }
        .el-dropdown-menu__item {
            color: #444;
            height: 46px;
            padding: 0 12px;
            line-height: 45px;
            border-bottom: 1px solid #eee;
            &:nth-last-of-type(1) {
                border-bottom: none;
                height: 45px;
            }
            &:hover {
                color: $primary;
            }
            img {
                width: 35px;
                height: 35px;
                margin-right: 10px;
            }
            .icon-question {
                width: 18px;
                height: 18px;
                display: inline-block;
                .question-hover, .question-no-hover {
                    width: 100%;
                    height: 100%;
                    min-width: unset;
                    min-height: unset;
                }
                .question-hover {
                    display: none;
                }
                &:hover{
                    .question-hover{
                        display: inline-block;
                    }
                    .question-no-hover{
                        display: none;
                    }
                }
            }
        }
    }
    .job-card-popper.el-tooltip__popper.is-light {
        margin-left: 0 !important;
        margin-top: 6px;
    }
    .job-record-guide-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 4000;
        background-color: rgba(0,0,0,0.5);
    }
    .record-introduce-dialog.el-dialog {
        width: 637px;
        height: 286px;
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            position: relative;
            padding: 32px 30px;
            background: url('~@src/assets/images/jobDetail/job_record_introduce.png') no-repeat center;
            background-position-x: 470px;
            .close-record-intro-btn {
                width: 18px;
                height: 18px;
                color: #fff;
                border-radius: 9px;
                border: 1px solid #fff;
                position: absolute;
                right: -24px;
                top: -24px;
                line-height: 18px;
                cursor: pointer;
                &::before {
                    content: "×";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &:hover {
                    color: #fff;
                    background-color: #e95352;
                    border-color: #e95352;
                }
            }
            .record-introduce-content {
                & + .record-introduce-content {
                    margin-top: 22px;
                }
                .record-introduce-title {
                    margin-bottom: 8px;
                    font-size: 18px;
                    line-height: 24px;
                    color: #666;
                }
                .record-introduce-text {
                    font-size: 15px;
                    color: #666666;
                    line-height: 30px;
                    font-weight: bold;
                }
            }
        }
    }
    .customer-name-tooltip.el-tooltip__popper.is-light {
        margin-top: 0;
        margin-left: 0;
    }
    .project-info-popover .project-info-list {
        margin: 0;

        > i {
            display: inline-block;
        }

        .project-info-item {
            color: #666;
            cursor: pointer;

            &:hover {
                color: $primary;
            }
        }
    }

    .bind-project-popover{
        margin: 0;
        .project-info-item {
            color: #666;
            &:hover {
                color: $primary;
                text-decoration: underline;
            }
        }
    }
</style>
