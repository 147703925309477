<template>
    <el-dialog
        title="分享给候选人"
        :visible.sync="dialogVisible"
        width="700px"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        custom-class="share-dg"
    >
        <div class="job-share-dialog-head">
            <p class="job-share-title">
                职位机会： {{jobInfo.firmShortName}}-{{jobInfo.name}}
            </p>
            <el-select
                v-model="candidates"
                multiple
                filterable
                remote
                allow-create
                default-first-option
                placeholder="收件人（以空格隔开）"
                :remote-method="remoteMethod"
                :loading="searchLoading"
                class="share-candidate-select"
                @change="candidateEmailChange"
            >
                <el-option
                    v-for="item in candidateOptions"
                    :key="item.name"
                    :label="item.name + '<' + item.email + '>'"
                    :value="item.email"
                >{{item.name}}&lt;{{item.email}}&gt;</el-option>
            </el-select>
        </div>
        <div class="job-share-dialog-body">
            <el-input
                :rows="3"
                type="textarea"
                v-model="emailContentInput"
                class="job-share-dialog-input"
            ></el-input>
            <div class="job-share-dialog-intro">

                <div class="title-row ell">
                    <span class="name text-ellipsis" :title="jobInfo.name">{{jobInfo.name}}</span>
                    <span class="locations text-ellipsis" :title="jobInfo.location">[{{jobInfo.location}}]</span>
                    <span class="tags">
                        <el-tooltip
                            v-for="item in jobInfo.activityTags"
                            :key="item.id"
                            effect="light"
                            placement="right"
                            :disabled="!item.description"
                            v-show="item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'"
                            popper-class="job-tag-description">
                            <div slot="content" v-html="item.description"></div>

                            <span class="JobEmergency" title="急聘" v-if="item.id == 'JobEmergency'">
                                <span class="hurry">
                                    <font-icon href="#icon-shandian"></font-icon>    
                                </span>急聘
                            </span>
                            <span v-else class="tag" :class="`job-tag-${jobActivityTag[item.id].theme}`" :title="item.name">{{item.name == 'New' ? '最新': item.name}}</span>

                        </el-tooltip>

                        <span class="tag job-tag-info" v-if="jobInfo.jobType ==3">HR</span>
                        <span class="tag job-tag-info" v-if="jobInfo.jobType ==5">代运营</span>
                    </span>
                </div>

                <div class="item-row">
                    <div class="item">
                        <span class="salary">{{jobInfo.salary == '0K-0K' ? '面议' : jobInfo.salary}}</span>
                        <span class="job-tag">{{jobInfo.yearOfExperience}}</span>
                        <span class="job-tag">{{jobInfo.degree}}</span>
                        <span class="job-tag">需 {{jobInfo.recruitingCount}} 人</span>
                        <span class="job-tag" v-if="jobInfo.categoryName">{{jobInfo.categoryName}}</span>
                    </div>
                </div>


                <!-- <h2 class="job-head-title">
                    <span class="job-name">{{jobInfo.name || ''}}</span>
                    <span class="job-location">[{{jobInfo.location || ''}}]</span>
                    <div class="job-detail-tags">
                        <el-tooltip
                            v-for="item in jobInfo.activityTags"
                            :key="item.id"
                            effect="light"
                            placement="right"
                            :content="item.description"
                            :disabled="!item.description"
                            v-show="item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'"
                            popper-class="job-tag-description"
                        >
                            <span
                                class="job-detail-tag"
                                :class="`job-tag-${jobActivityTag[item.id].theme}`"
                            >
                                {{item.name}}
                            </span>
                        </el-tooltip>
                        <span v-if="jobInfo.status && !jobInfo.isSleeping" class="job-detail-tag" :class="`job-tag-${jobStatus[jobInfo.status].theme}`">{{jobStatus[jobInfo.status].text}}</span>
                        <span class="job-detail-tag job-tag-primary" v-if="jobInfo.expectedRank">{{jobInfo.expectedRank}}</span>
                    </div>
                </h2> -->
                <!-- <p class="job-primary">
                    <span title="月薪">
                        <font-icon href="#icon-rmb-circle-o"></font-icon>
                        <em>{{jobInfo.salary == '0K-0K' ? '面议' : jobInfo.salary}}</em>
                    </span>
                    <span title="工作经验">
                        <font-icon href="#icon-clock-o"></font-icon>
                        <em>{{jobInfo.yearOfExperience}}</em>
                    </span>
                    <span title="学历">
                        <font-icon href="#icon-article-o"></font-icon>
                        <em>{{jobInfo.degree}}</em>
                    </span>
                    <span title="所需人数">
                        <font-icon href="#icon-people-o"></font-icon>
                        需<em>{{jobInfo.recruitingCount}}</em>人
                    </span>
                    <span title="工作地点">
                        <font-icon href="#icon-address"></font-icon>
                        <em>{{jobInfo.address || "等待补充"}}</em>
                    </span>
                </p> -->
            </div>
            <div class="job-share-dialog-content">
                <!-- <detail-item-header
                    title="职位详情"
                    :hasBottomBorder="false"
                ></detail-item-header> -->
                <h5 class="job-intro-title">职位详情</h5>
                <pre
                    class="job-description-show"
                    v-html="jobInfo.description"
                ></pre>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="onConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import DetailItemHeader from "./detail-item-header.vue";
    import jobActivityTag from '#/js/config/jobActivityTag.json';
    import jobStatus from '#/js/config/jobStatus.json';
    import { job as jobUrl, candidate as candidateUrl } from '#/js/config/api.json';
    import { email as emailTest } from '#/js/util/validators.js';

    export default {
        name: "shareToCandidateDialog",
        components: {
            DetailItemHeader
        },
        data(){
            return{
                dialogVisible: false,
                loading: false,
                searchLoading: false,
                candidates: [],
                candidateOptions: [],
                emailContentInput: `hi,\n我是猎头${this.$store.state.user.userInfo.realName}，这个职位应该很适合你。感兴趣的话，打我电话沟通吧：${this.$store.state.user.userInfo.mobile}。`,
                jobStatus: jobStatus,
                jobActivityTag: jobActivityTag,
            }
        },
        props:{
            jobInfo: Object,
        },
        computed:{
        },
        methods:{
            show(){
                this.dialogVisible = true;
            },
            candidateEmailChange(emails) {
                if(emailTest(emails[emails.length-1])) {
                    return;
                } else {
                    
                }
            },
            onConfirm() {
                if(this.candidates.length > 0) {
                    this.loading = true;
                    let invalidatorEmail = [],
                        validatorEmail = [],
                        params = {};
                    this.candidates.forEach(item => {
                        if(!emailTest(item)) {
                            invalidatorEmail.push(item);
                        } else {
                            validatorEmail.push(item);
                        }
                    })
                    params.content = this.emailContentInput;
                    params.emails = validatorEmail.join(",");
                    params.__RequestVerificationToken = this.$store.state.verificationToken.verificationToken;
                    _request({
                        url: jobUrl.share_to_candidate.replace(/\%p/ig, this.jobInfo.id),
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: params,
                    }).then((res) => {
                        this.loading = false;
                        shortTips(`已成功向 ${validatorEmail.length} 位候选人发出邮件！`);
                        if(invalidatorEmail.length > 0) {
                            setTimeout(() => {
                                shortTips("以下地址存在错误，未发送成功：<br/>" + invalidatorEmail.join("<br/>"));
                            }, 2000);
                        }
                        this.dialogVisible = false;
                    }).catch(err => {
                        this.loading = false;
                    })
                } else {
                    shortTips("请输入要分享的候选人邮箱");
                }
            },
            remoteMethod(query) {
                if (query !== '') {
                    this.searchLoading = true;
                    _request({
                        url: candidateUrl.search_similar_candidates,
                        method: "GET",
                        data: {
                            keyword: query,
                            _: new Date().getTime()
                        }
                    }).then((res) => {
                        this.searchLoading = false;
                        let candidateList = [];
                        Object.keys(res.candidateInfos).forEach((item) => {
                            let candidate = {};
                            candidate.name = res.candidateInfos[item];
                            candidate.email = item;
                            candidateList.push(candidate);
                        })
                        this.candidateOptions = candidateList;
                    }).catch((err) => {
                        this.searchLoading = false;
                    })
                } else {
                    this.candidateOptions = [];
            }
        }
    }
}
</script>

<style lang="scss" scope>
.job-share-dialog-head {
    .job-share-title, .share-candidate-select {
        margin-bottom: 10px;
        width: 100%;
        min-height: 45px;
        line-height: 45px;
        color: #666;
    }
    .job-share-title {
        padding: 0 30px 0 15px;
        border-radius: 3px;
        border: 1px solid #DCDCDC;
    }
    .share-candidate-select {
        .el-select__tags > span {
            display: inline-block;
            margin-left: 7px;
        }
        .el-select__input {
            margin-left: 7px;
        }
        .el-input {
            input {
                border-radius: 3px;
                padding-left: 15px;
            }
        }
        &.el-select {
            .el-tag.el-tag--info {
                margin-left: 0;
                font-size: 14px;
                height: 18px;
                line-height: 18px;
                background-color: #fff;
                border: none;
                color: #666;
                &::after {
                    content: ";";
                }
                .el-tag__close {
                    display: none;
                }
                &.is-hit {
                    background-color: #b3e4d7;
                }
            }
        }
    }
}
.job-share-dialog-body{
    border: 1px solid #DCDCDC;
    border-radius: 3px;
    padding: 20px 0 0;
    max-height: 348px;
    overflow-y: auto;
    .job-share-dialog-input {
        textarea {
            border: none;
            color: #333;
            font-size: 14px;
        }
    }
    .job-share-dialog-intro, .job-share-dialog-content {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .job-share-dialog-intro {
        padding: 20px 15px 10px;
        // margin-top: 20px;
        margin: 10px;
        background: #F6F6F6;
        border-radius: 10px;
    }

    .title-row{
        height: 26px;
        line-height: 26px;
        margin-bottom: 7px;
        .name{
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            max-width: 340px;
            cursor: pointer;
        }
        .locations{
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            vertical-align: top;
            max-width: 220px;
            cursor: pointer;
        }
        .tags{
            vertical-align: top;
            .JobEmergency{
                display: inline-block;
                height: 20px;
                line-height: 20px;
                background: #FF4B4B;
                border-radius: 3px;
                font-size: 12px;
                margin-left: 7px;
                padding: 0 10px;
                color: white;
                .hurry{
                    color: white;
                    font-size: 10px;
                    vertical-align: top;
                }
            }
            .tag{
                display: inline-block;
                height: 20px;
                line-height: 18px;
                border-radius: 3px;
                font-size: 12px;
                margin-left: 7px;
                padding: 0 10px;
                border: 1px solid #666;
                // vertical-align: -1px;
            }
            .job-tag-danger{
                border-color: #FFB6B6;
                color: #FF4B4B;
                background: rgba(255,75,75,0.1);
            }
            .job-tag-primary{
                border-color: #38bc9d;
                color: #38bc9d;
            }
            .job-tag-info{
                border-color: #39f;
                color: #39f;
                background: rgba(185,208,255,0.14);
            }
            .job-tag-warning{
                border-color: #FFDDA8;
                color: #FF9E21;
                background: rgba(255,236,199,0.7);
            }
            

        }
    }
    .item-row{
        .item{
            line-height: 22px;
            margin-bottom: 7px;
            font-size: 14px;
            .salary{
                font-size: 14px;
                font-weight: 500;
                color: #666666;
                line-height: 20px;
                margin-right: 8px;
            }
            .job-tag{
                display: inline-block;
                height: 22px;
                // background: #F6F6F6;
                border-radius: 3px;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                line-height: 22px;
                margin-right: 4px;
                padding: 0 10px;

                background: rgba(235,235,235,0.92);
            }

            .percent{
                font-weight: 500;
                color: #FF9E21;
            }
            .promise-time{
                color: #666666;
                margin-left: 20px;
                margin-right: 20px;
                .month{
                    color: #FF9E21;
                    margin: 0 4px;
                }
            }
            .customer{
                color: #666666;
                cursor: pointer;
                &:hover{
                    color: $primary;
                }
                &::before{
                    width: 1px;
                    height: 15px;
                    background: #DCDCDC;
                    content: '';
                    display: inline-block;
                    margin-right: 20px;
                    vertical-align: -4px;
                }
            }

            .job-firm-commission {
                color: #b9b9b9;
                .text-grey {
                    color: #666;
                }
            }

            .tip-icon {
                display: inline-block;
                width: 22px;
                height: 22px;
                margin-left: 5px;
                .font-icon {
                    width: 16px;
                    height: 16px;
                    margin-top: -3px;
                    opacity: 1;
                }
                .hover {
                    display: none;
                }
                &:hover {
                    .default {
                        display: none;
                    }
                    .hover {
                        display: inline-block;
                    }
                }
            }

        }
    }

    .job-head-title {
        font-size: 24px;
        margin-bottom: 20px;
        white-space: nowrap;
        & > span{
            margin-right: 10px;
        }
        .job-detail-tags{
            display: inline-flex;
            margin-bottom: 0;
            user-select: none;
            .job-detail-tag{
                text-align: center;
                font-size: 12px;
                border: 1px solid #b9b9b9;
                padding: 1.5px 6px;
                border-radius: 10px;
                color: #b9b9b9;
                margin-right: 7px;
                &.job-tag-danger{
                    border-color: #ff493c;
                    color: #ff493c;
                }
                &.job-tag-primary{
                    border-color: #38bc9d;
                    color: #38bc9d;
                }
                &.job-tag-info{
                    border-color: #39f;
                    color: #39f;
                }
                &.job-tag-warning{
                    border-color: #ff7200;
                    color: #ff7200;
                }
            }
        }
        .job-name {
            max-width: 330px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #333;
            display: inline-block;
            vertical-align: middle;
        }
        .job-location {
            font-size: 20px;;
            color: #b9b9b9;
            display: inline-block;
            vertical-align: middle;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 300px;
        }
    }
    .job-primary {
        font-size: 16px;
        line-height: 20px;
        > span {
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
        }
        .icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 5px;
            vertical-align: -2px;
            background-position: right center;
            background-repeat: no-repeat;
            opacity: 0.5;
            min-width: 0;
            min-height: 0;
        }
    }
    .job-share-dialog-content {
        padding: 0 15px 20px;
        .job-intro-title {
            font-size: 16px;
            color: #4a4a4a;
            line-height: 48px;
            height: 48px;
            font-weight: bold;
            margin-bottom: 0;
            margin-top: 22px;
        }
        .job-description-show {
            line-height: 24px;
            margin-bottom: 0;
            color: #666;
            white-space: pre-wrap;
            font-family: inherit;
        }
    }
}
.share-dg{
    .el-dialog__title{
        font-size: 16px;
        color: #333;
        font-weight: 600;
        line-height: 22px;
    } 

}
</style>