var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "detail-item-header",
      class: _vm.hasBottomBorder ? "header-bottom-border" : "",
    },
    [
      _c("span", { staticClass: "header-title" }, [
        _vm.isOrderHeader
          ? _c("span", { staticClass: "text-primary" }, [
              _vm._v("\n          " + _vm._s(_vm.headerDetail) + "\n      "),
            ])
          : _vm._e(),
        _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
        _vm.headerDetail >= 0 && !_vm.isOrderHeader
          ? _c("span", [
              _vm._v("\n          (" + _vm._s(_vm.headerDetail) + ")\n      "),
            ])
          : _vm._e(),
      ]),
      _c("span", { staticClass: "header-slot" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }