var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "分享给候选人",
        visible: _vm.dialogVisible,
        width: "700px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "custom-class": "share-dg",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "job-share-dialog-head" },
        [
          _c("p", { staticClass: "job-share-title" }, [
            _vm._v(
              "\n            职位机会： " +
                _vm._s(_vm.jobInfo.firmShortName) +
                "-" +
                _vm._s(_vm.jobInfo.name) +
                "\n        "
            ),
          ]),
          _c(
            "el-select",
            {
              staticClass: "share-candidate-select",
              attrs: {
                multiple: "",
                filterable: "",
                remote: "",
                "allow-create": "",
                "default-first-option": "",
                placeholder: "收件人（以空格隔开）",
                "remote-method": _vm.remoteMethod,
                loading: _vm.searchLoading,
              },
              on: { change: _vm.candidateEmailChange },
              model: {
                value: _vm.candidates,
                callback: function ($$v) {
                  _vm.candidates = $$v
                },
                expression: "candidates",
              },
            },
            _vm._l(_vm.candidateOptions, function (item) {
              return _c(
                "el-option",
                {
                  key: item.name,
                  attrs: {
                    label: item.name + "<" + item.email + ">",
                    value: item.email,
                  },
                },
                [_vm._v(_vm._s(item.name) + "<" + _vm._s(item.email) + ">")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "job-share-dialog-body" },
        [
          _c("el-input", {
            staticClass: "job-share-dialog-input",
            attrs: { rows: 3, type: "textarea" },
            model: {
              value: _vm.emailContentInput,
              callback: function ($$v) {
                _vm.emailContentInput = $$v
              },
              expression: "emailContentInput",
            },
          }),
          _c("div", { staticClass: "job-share-dialog-intro" }, [
            _c("div", { staticClass: "title-row ell" }, [
              _c(
                "span",
                {
                  staticClass: "name text-ellipsis",
                  attrs: { title: _vm.jobInfo.name },
                },
                [_vm._v(_vm._s(_vm.jobInfo.name))]
              ),
              _c(
                "span",
                {
                  staticClass: "locations text-ellipsis",
                  attrs: { title: _vm.jobInfo.location },
                },
                [_vm._v("[" + _vm._s(_vm.jobInfo.location) + "]")]
              ),
              _c(
                "span",
                { staticClass: "tags" },
                [
                  _vm._l(_vm.jobInfo.activityTags, function (item) {
                    return _c(
                      "el-tooltip",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              item.id !== "StandstillJob" &&
                              item.id != "FirstOrderActivityJob",
                            expression:
                              "item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'",
                          },
                        ],
                        key: item.id,
                        attrs: {
                          effect: "light",
                          placement: "right",
                          disabled: !item.description,
                          "popper-class": "job-tag-description",
                        },
                      },
                      [
                        _c("div", {
                          attrs: { slot: "content" },
                          domProps: { innerHTML: _vm._s(item.description) },
                          slot: "content",
                        }),
                        item.id == "JobEmergency"
                          ? _c(
                              "span",
                              {
                                staticClass: "JobEmergency",
                                attrs: { title: "急聘" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "hurry" },
                                  [
                                    _c("font-icon", {
                                      attrs: { href: "#icon-shandian" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v("急聘\n                        "),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "tag",
                                class: `job-tag-${
                                  _vm.jobActivityTag[item.id].theme
                                }`,
                                attrs: { title: item.name },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.name == "New" ? "最新" : item.name
                                  )
                                ),
                              ]
                            ),
                      ]
                    )
                  }),
                  _vm.jobInfo.jobType == 3
                    ? _c("span", { staticClass: "tag job-tag-info" }, [
                        _vm._v("HR"),
                      ])
                    : _vm._e(),
                  _vm.jobInfo.jobType == 5
                    ? _c("span", { staticClass: "tag job-tag-info" }, [
                        _vm._v("代运营"),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "item-row" }, [
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "salary" }, [
                  _vm._v(
                    _vm._s(
                      _vm.jobInfo.salary == "0K-0K"
                        ? "面议"
                        : _vm.jobInfo.salary
                    )
                  ),
                ]),
                _c("span", { staticClass: "job-tag" }, [
                  _vm._v(_vm._s(_vm.jobInfo.yearOfExperience)),
                ]),
                _c("span", { staticClass: "job-tag" }, [
                  _vm._v(_vm._s(_vm.jobInfo.degree)),
                ]),
                _c("span", { staticClass: "job-tag" }, [
                  _vm._v("需 " + _vm._s(_vm.jobInfo.recruitingCount) + " 人"),
                ]),
                _vm.jobInfo.categoryName
                  ? _c("span", { staticClass: "job-tag" }, [
                      _vm._v(_vm._s(_vm.jobInfo.categoryName)),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "job-share-dialog-content" }, [
            _c("h5", { staticClass: "job-intro-title" }, [_vm._v("职位详情")]),
            _c("pre", {
              staticClass: "job-description-show",
              domProps: { innerHTML: _vm._s(_vm.jobInfo.description) },
            }),
          ]),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }