var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "封禁说明",
        visible: _vm.dialogVisible,
        width: "550px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "guide-header" }, [
        _c("p", [
          _vm._v(
            "很抱歉您可能被使用了平台提供的「封禁」功能，解禁前都不能对该职位推荐候选人了,您可以尝试："
          ),
        ]),
      ]),
      _c("ul", { staticClass: "guide-list" }, [
        _c("li", [_vm._v("排除误操作情况（联系平台运营人员）")]),
        _c("li", [_vm._v("进一步理解职位需求（社区 #互动 中私信 HR）")]),
        _c("li", [_vm._v("换其他订单使劲")]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }