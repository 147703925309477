let appId = 1252215655;
if(location.href.indexOf('www.liebide.com') > -1) {
  appId = 1500000610;
}
if(location.href.indexOf('stg.liebide.com') > -1 || location.href.indexOf('rc.liebide.com') > -1 || location.href.indexOf('dev1.liebide.com') > -1 || location.href.indexOf('dev2.liebide.com') > -1 || location.href.indexOf('localhost:17984') > -1) {
  appId = 1500000609;
}
// 初始化 SDK 实例
const vod = {
    appId: appId,
    dir: function videoDir(url) {
        let regResult = ""
        regResult = url.replace(/http.+\.com/, '');
        regResult = regResult.substring(0, regResult.lastIndexOf('/') + 1);
        return regResult;
    }
}

export default vod