<template>
    <div v-loading="loading">
        <div class="job-recommend-detail">
            <div class="top-wrap">
                <div class="jr-top">
                    <button @click="handleTopTap('baseInfo')" class="tab-btn" :class="{'active': currentTab=='baseInfo'}">简历基本信息</button>
                    <button @click="handleTopTap('currentStatus')" class="tab-btn" :class="{'active': currentTab=='currentStatus'}">当前状态</button>
                    <button @click="handleTopTap('recommendEvaluate')" class="tab-btn" :class="{'active': currentTab=='recommendEvaluate'}">推荐评价</button>
                    <button @click="handleTopTap('timeline')" class="tab-btn" :class="{'active': currentTab=='timeline'}">时间线</button>
                </div>
                <div class="user-infos" id="baseInfo">
                    <div class="info-row">
                        <div class="ir-l">
                            <span class="pic">
                                <avatar
                                    :userId="callee.id ? callee.id : defaultCreatorId"
                                    :src="callee.avatarUrl ? callee.avatarUrl : formatAvatarUrl(`/Data/Avatar/${callee.avatarId}`)"
                                    :enableLink="true"
                                    :enableName="true"
                                    
                                    :isRecommendation="true"
                                    :shareData="shareData"
                                    size="sm"
                                ></avatar>
                            </span>
                            <span class="name">{{callee.name}}
                                <span class="nick"> {{callee.nickname}}</span>
                            </span>
                            <span class="spacer"></span>
                            <span class="time">推荐时间：{{ recommendDate | dateFilter }}</span>
                        </div>
                        <div class="ir-r">
                            <span class="back" @click="handleRouterNavigate">
                                <span class="back-icon">
                                    <font-icon href="#icon-chexiao"></font-icon>
                                </span>
                                返回全部推荐
                            </span>
                        </div>
                    </div>

                    <div
                        class="recommend-attachment"
                        v-show="hasRecommendAttachment"
                    >
                        <attachment-list
                            ref="attachmentList"
                            :recommendationId="recommendationId"
                            :candidateName="shareData ? shareData.candidateName : ''"
                            :torecommends="false"
                            type="recommend"
                            @has-attachment="showRecommendAttachment"
                        ></attachment-list>
                    </div>
                    <!-- <div class="files">
                        <div class="file-item">
                            <div class="cell">
                                <span class="file-icon">公</span>
                                <span class="file-icon secret">密</span>
                                <span class="file-name ell">某某某简历实话实说的好很多很很多很很多很很多很.PDF</span>
                            </div>
                            <div class="cell">
                                <span class="pub-time">上传时间：2023-12-23 12:34:45</span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="blank-block">
                <div class="tit-cell">
                    <span class="tit">简历详情</span>
                    <span class="line"></span>
                    <span class="expand" @click="handleToggleFold">
                        {{unFold ? '收起全部': '展开全部'}}
                        <span class="expand-icon">
                            <font-icon
                                href="#icon-right-copy"
                            ></font-icon>
                        </span>
                    </span>
                </div>
                <div class="content">
                    <resume-info 
                        ref="resumeInfo"
                        :resume-id="resumeId"
                    ></resume-info>
                </div>

            </div>
            <div class="blank-block" id="currentStatus">
                <div class="tit-cell">
                    <span class="tit">当前状态</span>
                    <span class="line"></span>
                </div>
                <div class="content">
                    <recommend-process
                        ref="recommendProcess"
                        :recommendationId="recommendationId"
                        :isShowStep="true"
                        class="card"
                        @recommend-process-success="handleProcessSuccess"
                        @timelineRefresh="timelineRefresh"
                        @comment-at-and-tip="AtandTip"
                        @similar-resume='handelSimilarResume'
                        @transfer="handleEmailShare"
                        @successEditOffer="offerEditSuccess"
                        @attachmentRefresh="attachmentRefresh"
                    ></recommend-process>
                </div>

            </div>
            <div class="blank-block" id="recommendEvaluate">
                <div class="tit-cell">
                    <span class="tit">推荐评价</span>
                    <span class="line"></span>
                </div>
                <div class="content">
                    <recomand-comment 
                        ref="recomandComment"
                        commentType="job"
                        placeholderText="请对该条推荐做出你的评价"
                        class="card"
                        :commentObjectInfo="jobInfo"
                        :isEmail="true"
                        :team="true"
                        :prefetch="true"
                        :shareData="shareData"
                        :canEvalute="canEvalute"
                        @is-evalute="isEvalute"
                    ></recomand-comment>
                </div>
            </div>
            <div class="blank-block" id="timeline">
                <div class="tit-cell">
                    <span class="tit">时间线</span>
                    <span class="line"></span>
                </div>
                <div class="content">
                    <!-- class="card" -->
                    <candidate-recommendation-timeline
                        ref="candidateRecommendationTimeline"
                        :recommendationId="recommendationId"
                        :isPublisher="isPublisher"
                        
                    ></candidate-recommendation-timeline>
                </div>
            </div>
            <div class="job-recommend-left" v-if="false">
                <div class="menu-panel">
                    <ul class="menu-resume">
                        <li @click="selectMenu('status-process')" :class="{active: currentActive == 'status-process'}">
                            <font-icon href="#icon-job-statues"></font-icon>
                            <span class="menu-text">当前状态</span>
                        </li>
                        <li @click="selectMenu('resume-info')" :class="{active: currentActive == 'resume-info'}">
                            <font-icon href="#icon-baocun"></font-icon>
                            <span class="menu-text">简历详情</span>
                        </li>
                        <li @click="selectMenu('recomand-evalute')" :class="{active: currentActive == 'recomand-evalute'}">
                            <font-icon href="#icon-wechat"></font-icon>
                            <span style="position: relative;">
                                <span class="menu-text">推荐评价</span>
                                <i class="dot" v-if="canEvalute && !hasEvalute"></i>
                            </span>
                        </li>
                        <li @click="selectMenu('time-line')" :class="{active: currentActive == 'time-line'}">
                            <font-icon href="#icon-job-timeline"></font-icon>
                            <span class="menu-text">时间线</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="job-recommend-right" v-if="false">
                <div class="job-recommend-header">
                    <span class="title">
                        <a @click="handleRouterNavigate">
                            <i class="el-icon-d-arrow-left"></i>
                            <span class="title-font-color">全部推荐记录</span>
                        </a>
                    </span>
                    <span class="recommend-user recommend-user-overflow">
                        <avatar
                            :userId="callee.id ? callee.id : defaultCreatorId"
                            :src="callee.avatarUrl ? callee.avatarUrl : formatAvatarUrl(`/Data/Avatar/${callee.avatarId}`)"
                            :enableLink="true"
                            :enableName="true"
                            :realName="callee.name"
                            :nickName="callee.nickname"
                            :isRecommendation="true"
                            :shareData="shareData"
                            size="sm"
                        ></avatar>
                    </span>
                    <span class="recommend-time">{{ recommendDate | dateFilter }}</span>
                </div>

                <div
                    class="recommend-attachment"
                    v-show="hasRecommendAttachment"
                >
                    <span class="recommend-attachment-title">
                        推荐上传附件：
                    </span>
                    <!-- <attachment-list
                        ref="attachmentList"
                        :recommendationId="recommendationId"
                        :candidateName="shareData ? shareData.candidateName : ''"
                        :torecommends="false"
                        type="recommend"
                        @has-attachment="showRecommendAttachment"
                    ></attachment-list> -->
                </div>

                <div class="recommendation-detail-content">
                    <!-- 当前状态 -->
                    <!-- <div id="status-process"> -->
                        <!-- <recommend-process
                            ref="recommendProcess"
                            :recommendationId="recommendationId"
                            :isShowStep="true"
                            class="card"
                            @recommend-process-success="handleProcessSuccess"
                            @timelineRefresh="timelineRefresh"
                            @comment-at-and-tip="AtandTip"
                            @similar-resume='handelSimilarResume'
                            @transfer="handleEmailShare"
                            @successEditOffer="offerEditSuccess"
                            @attachmentRefresh="attachmentRefresh"
                        ></recommend-process> -->
                    <!-- 简历详情 -->
                    <!-- <div id="resume-info">
                        <resume-info 
                            ref="resumeInfo"
                            :resume-id="resumeId"
                        ></resume-info>
                    </div>   -->
                    <!-- 推荐评价 -->
                    <!-- <div class="recomand-evaluate" id="recomand-evalute"> -->
                        <!-- <recomand-evaluate 
                            ref="recomandEvaluate"
                            commentType="job"
                            :isEmail="true"
                            :commentObjectInfo="jobInfo"
                            :team="true"
                            :prefetch="true"
                            :shareData="shareData"
                            :canEvalute="canEvalute"
                            @is-evalute="isEvalute"
                        ></recomand-evaluate> -->
                        <!-- <recomand-comment 
                            ref="recomandComment"
                            commentType="job"
                            placeholderText="请对该条推荐做出你的评价"
                            class="card"
                            :commentObjectInfo="jobInfo"
                            :isEmail="true"
                            :team="true"
                            :prefetch="true"
                            :shareData="shareData"
                            :canEvalute="canEvalute"
                            @is-evalute="isEvalute"
                        ></recomand-comment> -->
                    <!-- </div> -->
                    <!-- 时间线 -->
                    <!-- <div id="time-line">
                        <candidate-recommendation-timeline
                            ref="candidateRecommendationTimeline"
                            :recommendationId="recommendationId"
                            :isPublisher="isPublisher"
                            class="card"
                        ></candidate-recommendation-timeline>
                    </div> -->
                </div>
            </div>
        </div>

        <candidate-recommendation-similar-resume
            ref="candidateRecommendationSimilarResume"
        ></candidate-recommendation-similar-resume>

        <!-- @emailshare-success='handelReaderList' -->
        <candidate-email-share
            ref="candidateEmailShare"
            :recommendationId="recommendationId"
            
        ></candidate-email-share>

    </div>
</template>

<script>
import Avatar from '#/component/common/avatar.vue';
import recommendProcess from '@/views/candidate-detail/component/recommend-process/index-refactor.vue';
import candidateRecommendationTimeline from '@/views/candidate-detail/component/candidate-recommendation-timeline/index';
import resumeInfo from './resume-info/index.vue'
import recomandEvaluate from '#/component/recomand-evaluate/index.vue'
import recomandComment from '#/component/recomand-evaluate/comments.vue'
import moment from 'moment'
import { job as jobUrl } from '#/js/config/api.json';
import attachmentList from '@/views/candidate-detail/component/attachmentList.vue';
import candidateEmailShare from '@/views/candidate-detail/component/candidateEmailShareDialog.vue';
import candidateRecommendationSimilarResume from '@/views/candidate-detail/component/candidateRecommendationSimilarResume.vue';

export default {
    components: {
        Avatar,
        recommendProcess,
        resumeInfo,
        recomandEvaluate,
        recomandComment,
        candidateRecommendationTimeline,
        attachmentList,
        candidateEmailShare,
        candidateRecommendationSimilarResume,
    },

    props: {
        recommendation: {
            type: Object,
            default:{}
        },
        canEdit:{
            type:Boolean,
            default:false
        },
        jobInfo: Object,
        shareData: Object
    },

    data() {
        return {
            currentActive: 'status-process',

            callee: {
                name: '',
                id: '',
                nickname: '',
                action: '',
                avatarUrl: '',
                mobile: ''
            },
            userId: this.$store.state.user.userInfo.id,
            isPublisher: false,
            isRecommender: true,
            loading:false,
            publisherInfo: null,

            recommendationId: '',
            resumeId: '',
            recommendDate: '',
            hasEvalute: false,
            canEvalute: false,

            hasRecommendAttachment: false,
            unFold: false,

            currentTab: 'baseInfo',
        };
    },

    computed: {
        // isMyself(){
        //     return this.userId === this.callee.id;
        // },
        defaultCreatorId(){
            return this.$store.state.user.userInfo.id;
        },
        defaultCreatorAvatar(){
            return this.$store.state.user.userInfo.avatarUrl
        }
    },

    filters: {
        dateFilter(val) {
            if(val) {
                return moment(val).format('YYYY-MM-DD HH:mm');
            } else {
                return '';
            }
        }
    },

    watch: {
        
    },

    mounted() {
        this.attachmentList = this.$refs.attachmentList;
        this.candidateRecommendationTimeline = this.$refs.candidateRecommendationTimeline;
    },

    methods: {
        getProcess() {
            this.recommendDate = this.$route.query.recommendDate;
            this.recommendationId = this.$route.params.recommendationid;

            this.attachmentList.refresh(this.recommendationId);

            this.checkCanEvaluate();
            this.$refs.recommendProcess.refresh(this.recommendationId);
            // this.$refs.candidateRecommendationTimeline.refresh(this.recommendationId);
        },

        // 判断是否可以评分
        checkCanEvaluate() {
            this.loading = true;
            _request({
                url: jobUrl.check_can_evaluate.replace(/\%p/ig, this.recommendationId),
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
            }).then((res) => {
                this.loading = false;
                this.canEvalute = res.success;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        // 是否进行过评分了
        isEvalute(value) {
            this.hasEvalute = value;
        },

        selectMenu(type){
            console.log(type);
            this.currentActive = type;
            this.scrollById(type);
        },
        scrollById(id){
            let offestTop = document.getElementById(id).offsetTop;
            document.querySelector('.job-detail').scrollTop = offestTop;
        },

        formatAvatarUrl(url) {
            return _host.portal + url;
        },
        handleRouterNavigate(){
            this.$emit('update-state','list');
        },
        updateCallee(callee){
            this.callee = callee;
        },
        
        handleProcessSuccess(summary){
            // console.log(summary);

            this.isPublisher = summary.isPublisher;
            this.isRecommender = summary.isRecommender;
            this.publisherInfo = summary.publisherInfo;

            this.resumeId = summary.candidateId;
            this.callee = summary.recommenderInfo;

            this.candidateRecommendationTimeline.refresh(summary.id);

            this.loading = false;
            return false;
        },
        attachmentRefresh(){
            this.attachmentList.refresh(this.myrecommendationId);
        },
        timelineRefresh(){
            this.candidateRecommendationTimeline.refresh();
        },
        AtandTip(id){
            // this.comments.atAndTip(id);
        },
        handleEmailShare(){
            this.$refs.candidateEmailShare.show(this.recommendationId);
        },
        handelSimilarResume(){
            this.$refs.candidateRecommendationSimilarResume.show(this.recommendationId);
        },
        offerEditSuccess() {
            this.candidateRecommendationTimeline.loadcontent();
        },
        showRecommendAttachment(hasRecommendAttachment) {
            this.hasRecommendAttachment = hasRecommendAttachment;
        },
        handleToggleFold() {
            this.unFold = !this.unFold;
            this.$refs.resumeInfo.handleClikeIsFold();
        },
        handleTopTap(el) {
            this.currentTab = el;
            document.getElementById(el)?.scrollIntoView(true);
        },
        
    },
};
</script>

<style lang="scss" scoped>
.job-recommend-detail {
    // padding: 17px 30px 10px;
    font-size: 14px;
    // margin-bottom: 18px;
    // display: flex;

    .top-wrap{
        padding: 17px 17px 10px;
    
        .jr-top{
            width: 100%;
            height: 38px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 11px;
            .tab-btn{
                max-width: 23%;
                width: 23%;
                background: rgba(235,235,235,0.92);
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                color: #666666;
                line-height: 20px;
            }
            .active{
                color: white;
                background: $primary;
            }
        }
        .user-infos{
            .info-row{
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                .ir-l{
                    display: flex;
                    align-items: center;
                    >span{
                        display: inline-block;
                    }
                    .pic{
                        width: 30px;
                        height: 30px;
                        margin-right: 9px;
                    }
                    .name{
                        font-size: 14px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 20px;
                        .nick{
                            font-size: 12px;
                            color: #666666;
                        }
                    }
                    .spacer{
                        display: inline-block;
                        width: 1px;
                        height: 14px;
                        background: #DCDCDC;
                        margin: 0 14px;
                    }
                    .time{
                        font-size: 14px;
                        font-weight: 500;
                        color: #999999;
                        line-height: 20px;
                    }
                }
                .ir-r{
                    .back{
                        cursor: pointer;
                        .back-icon{
                            font-size: 16px;
                            margin-right: 7px;
                            vertical-align: top;
                        }
                        font-size: 14px;
                        font-weight: 500;
                        color: #38BC9D;
                        line-height: 20px;
                    }
                }
            }

            // .files{
            //     .file-item{
            //         width: 100%;
            //         height: 40px;
            //         background: linear-gradient(270deg, #FCFCFC 0%, #F7F8F8 100%);
            //         border-radius: 5px;
            //         border: 1px solid #EBEBEB;
            //         display: flex;
            //         justify-content: space-between;
            //         padding: 0 12px;
            //         .cell{
            //             line-height: 38px;
                        
            //             .file-icon{
            //                 display: inline-block;
            //                 width: 20px;
            //                 height: 20px;
            //                 background: rgba(56,188,157,0.14);
            //                 border-radius: 3px;
            //                 border: 1px solid rgba(56,188,157,0.53);

            //                 font-size: 12px;
            //                 font-weight: 500;
            //                 color: #38BC9D;
            //                 line-height: 18px;
            //                 margin: 10px 6px 0 0;
            //                 text-align: center;
            //                 vertical-align: top;
                            
            //             }
            //             .secret{
            //                 background: #FFECEC;
            //                 border-radius: 3px;
            //                 border: 1px solid #FFA8A8;
            //                 color: #FF4B4B;
            //             }   
            //             .file-name{
            //                 display: inline-block;
            //                 font-size: 14px;
            //                 font-weight: 500;
            //                 color: #38BC9D;
            //                 line-height: 38px;
            //                 max-width: 440px;
            //             }
            //             .pub-time{
            //                 font-size: 12px;
            //                 font-weight: 500;
            //                 color: #666666;
            //                 line-height: 17px;
            //             }
            //         }
            //     }
            // }
        }
    }

    .blank-block{
        padding: 12px 17px 0 0;
        .tit-cell{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            .tit{
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                line-height: 22px;
                margin-right: 13px;
                &:before{
                    display: inline-block;
                    content: '';
                    width: 3px;
                    height: 16px;
                    background: #38BC9D;
                    margin-right: 14px;
                    vertical-align: -2px;
                }
            }
            .line{
                // width: 689px;
                height: 2px;
                border: 1px solid rgba(235,235,235,0.92);
                flex: 1;
            }
            .expand{
                font-size: 14px;
                font-weight: 500;
                color: #38BC9D;
                line-height: 20px;
                margin-left: 13px;
                cursor: pointer;
                .expand-icon{
                    margin-left: 5px;
                    font-size: 10px;
                    vertical-align: top;
                }
            }
        }
        .content{
            padding-left: 16px;
            // .resume{
            //     .resume-head{
            //         .resume-row{
            //             display: flex;
            //             .rr-l{
            //                 width: 252px;
            //                 min-width: 252px;
            //             }
            //             .rr-r{
            //                 flex: 1;
            //             }
            //             .name{
            //                 font-size: 14px;
            //                 font-weight: 600;
            //                 color: #333;
            //                 line-height: 20px;
            //             }
            //             .back{
            //                 font-size: 14px;
            //                 font-weight: 500;
            //                 color: #333;
            //                 line-height: 20px;
            //                 margin-right: 6px;
            //             }
            //             .light{
            //                 font-size: 14px;
            //                 color: #999;
            //             }
            //             .spacer{
            //                 &::after{
            //                     display: inline-block;
            //                     width: 1px;
            //                     height: 15px;
            //                     background: #DCDCDC;
            //                     content: '';
            //                     margin: 0 4px;
            //                     vertical-align: -2px;
            //                 }
                            
            //             }

            //         }

            //     }
            // }

            
        }
    }
    

    .recommend-attachment {
        // display: flex;
        margin-bottom: 15px;
        height: 28px;
        .recommend-attachment-title {
            padding-top: 3px;
        }
    }
    
    .job-recommend-left {
        flex: 1;
        // width: 160px;
        border-right: 2px solid #eee;
        // padding: 0 30px 0 20px;
        color: #333;
        font-size: 16px;
        .menu-resume {
            li {
                cursor: pointer;
                height: 50px;
                line-height: 50px;
                .menu-text {
                    margin-left: 10px;
                    vertical-align: middle;
                }
                .dot {
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background-color: red;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    right: -8px;
                }
            }
            .active {
                color: #38BC9C;
                .font-icon{
                    fill: $primary;
                }
            }
        }
    }
    .job-recommend-right {
        // flex: 1;
        width: 85%;
        padding-left: 20px;
        .job-recommend-header {
            margin-bottom: 18px;
            .title {
                cursor: pointer;
                font-size: 14px;
                margin: 0;
                color: #333;
                .fa-chevron-left {
                    margin-right: 20px;
                }
                .title-font-color{
                    color: #666;
                    &:hover{
                        color: #34b092;
                    }
                }
            }

            .recommend-user {
                .avatar-container{
                    margin-right: 5px;
                    .avatar{
                        width: 22px;
                        height: 22px;
                        position: relative;
                    }
                }
            }
            
            .recommend-user-overflow {
                margin-left: 20px;
                display: inline-block;
                // height: 22px;
                .avatar-container {
                    display: inline-block;
                    // height: 22px;
                    .user-name{
                        max-width: 120px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .recommend-time {
                margin-left: 20px;
            }
        }

        .recommendation-detail-content{
            .resume-progress{
                padding: 0;
                margin-bottom: 20px;
            }
        }
    }
}
</style>

<style lang="scss">
.job-recommend-detail {
    .candidate-icon {
        margin-right: 5px;
        color: #666;
    }
}
</style>