export function findElementPath (tree, target, keyAlias, childrenAlias) {
    const path = [];
    dfs(tree, target, keyAlias, childrenAlias, path);
    return path;
}

function dfs (node, target, keyAlias, childrenAlias, path) {
    if (node[keyAlias] === target) {
        path.push(node);
        return true;
    }
    if (node[childrenAlias]) {
        for (let child of node[childrenAlias]) {
            if (dfs(child, target, keyAlias, childrenAlias, path)) {
                path.push(node);
                return true;
            }
        }
    }
    return false;
}