<template>
    <div class="recomand-evaluate" v-loading="loading">
        <div class="recomand-evaluate-title"><font-icon href="#icon-wechat" class="candidate-icon"></font-icon>推荐评价</div>
        <div class="recomand-evaluate-content">
            <div class="evaluate-wrap">
                <p class="score-and-tip">
                    <span>匹配度评分</span>
                    <span class="star-wrap">
                        <span class="star-item" v-for="(star, index) in stars" :key="'star_' + index" @click="updateStarRating(index + 1)">
                            <i class="el-icon-star-on" v-if="star"></i>
                            <i class="el-icon-star-off" v-else></i>
                        </span>
                        <span class="score-text" v-if="score">{{score}}分</span>
                    </span>
                    <span class="tip-text" v-if="score">
                        <i v-if="score == '1'">非常不匹配，不建议推荐</i>
                        <i v-if="score == '2'">匹配度较低，推荐需慎重考虑</i>
                        <i v-if="score == '3'">基本匹配，缺乏亮点</i>
                        <i v-if="score == '4'">较为匹配，有一定竞争力</i>
                        <i v-if="score == '5'">非常匹配，很建议推荐给客户</i>
                    </span>
                </p>
                <div class="evaluate-comment">
                    <div class="atWrap">
                        <el-input
                            ref="evaluateTextarea"
                            type="textarea"
                            :rows="4"
                            placeholder="请对该条推荐做出你的评价"
                            :disabled="hasEvalute"
                            v-model="evaluate">
                        </el-input>
                        <div class="at-tools clearfix">
                            <div class="pull-right">
                                <el-dropdown @command="handlePrivacyCommand">
                                    <span class="el-dropdown-link">
                                        <!-- 下拉菜单<i class="el-icon-arrow-down el-icon--right"></i> -->
                                        <a v-if="privacy == 0" id="privacy" class="privacy" title="设置私密性" href="javascript:;"><i class="fa fa-eye"><span>公开</span></i></a>
                                        <a v-if="privacy == 1" id="privacy" class="privacy" title="设置私密性" href="javascript:;"><i class="fa fa-lock"><span>私密</span></i></a>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-tooltip class="item" effect="light" content="所有人能看到评论" placement="left">
                                            <el-dropdown-item command="0">
                                                <i class="fa fa-eye"></i>公开
                                            </el-dropdown-item>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="light" content="只有被@的人才能看到评论" placement="left">
                                            <el-dropdown-item command="1">
                                                <i class="fa fa-lock"></i>私密
                                            </el-dropdown-item>
                                        </el-tooltip>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <!-- <div class="pull-right comment-image">
                                <a href="javascript:;"><i class="fa fa-paperclip"></i></a>
                                <input id="commentWithImageId" title="添加附件" class="add-image" type="file" name="file" value="0" />
                            </div> -->
                            <div class="pull-right">
                                <el-popover
                                    placement="bottom"
                                    title=""
                                    width="370"
                                    trigger="click"
                                    v-model="atListPopoverVisible"
                                >
                                    <div class="atList" aria-labelledby="atlist">
                                        <div class="search">
                                            <input @keyup="searchUsers" @keydown="stopEvent" autocomplete="off" type="text" name="keyword" placeholder="查找更多好友" />
                                        </div>
                                        <ul>
                                            <template v-for="(user, index) in users">
                                                <p v-if="user.type == 1 && (index == (users.length-2))" class="bazaSelectMenuTipsBox">小猎或其他用户</p>
                                                <li>
                                                    <a @click="selectPerson(user, $event)" href="javascript:;">
                                                        <img v-bind:src="user.avatar | avatar" width="16" />
                                                        <span class="team-tip" v-if="user.isFirmTeam">团队</span>
                                                        <span class="realName" v-text="user.realName"></span>
                                                        <span class="nickname">{{ user.nickname ? `@${user.nickname}` : '' }}</span>
                                                    </a>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                    <a slot="reference" class="at-list-icon" id="atlist" title="对TA说" href="javascript:;" @click="getClickAtUserInfo">@</a>
                                </el-popover>
                            </div>
                        </div>
                    </div>
                    <div class="submit-button">
                        <p v-show="isEmail">
                            <el-checkbox v-model="isEmailNotic">发送邮件通知</el-checkbox>
                        </p>
                        <el-button type="primary" @click="onSubmitEvalute(commentId)" :disabled="hasEvalute">发表</el-button>
                    </div>
                </div>
            </div>

            <!-- 评分列表 -->
            <div class="evaluate-list" v-for="item in evaluateList" :key="item.id">
                <div class="top">
                    <div class="top-info">
                        <avatar
                            :userId="item.posterId"
                            :src="item.avatarUrl"
                            :enableLink="true"
                            :enableName="false"
                            :realName="item.posterName"
                            :nickName="item.posterNickname"
                            :isRecommendation="true"
                            :shareData="shareData"
                            size="mg"
                        ></avatar>
                        <div class="name-and-score">
                            <p class="name">{{ item.posterName }}@{{ item.posterNickname }}</p>
                            <p class="star-wrap">
                                <span class="star-item">
                                    <i class="el-icon-star-on" v-for="(star, index) in computedParse(item.content,'score')" :key="'starOn_' + index"></i>
                                    <i class="el-icon-star-off" v-for="(star, index) in 5-computedParse(item.content,'score')" :key="'starOff_' + index"></i>
                                </span>
                                <span class="score-text">{{ computedParse(item.content,'score') }}分</span>
                            </p>
                        </div>
                    </div>
                    <div class="top-time">
                        {{ item.created }}
                    </div>
                </div>
                <div class="bottom">
                    {{ computedParse(item.content,'text') }}
                </div>
                <div class="edit" v-if="item.posterId === userInfo.id " @click="editEvaluate(item)">
                    <i class="el-icon-edit-outline"></i>
                    <span>编辑</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from '#/component/common/avatar.vue';
import { job as jobUrl } from '#/js/config/api.json';
// import atSystemMixin from '#/component/comments/atSystem-vue';

export default {
    components: {
        Avatar,
    },

    props: {
        prefetch: Boolean,
        canPost: {
            type: Boolean,
            default: true
        },
        team: {
            type: Boolean,
            default: false
        },
        isEmail: {
            type: Boolean,
            default: false
        },
        commentObjectInfo: {
            type: Object,
            default: function() {
                return {}
            }
        },
        shareData: Object
    },

    // mixins: [
    //     atSystemMixin
    // ],
    
    data() {
        return {
            loading: false,
            hasEvalute: false,
            stars: [false, false, false, false, false],
            score: '',
            evaluate: '',
            evaluateList: [],
            commentId: '',
            isEmailNotic: this.isEmail,

            atListPopoverVisible: false,
            privacy: 0,
            users: [],
            userList: [],
            defaults: {},
        };
    },

    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },

    watch: {
        '$route.params.recommendationid': {
            handler(val) {
                if(val) {
                    this.stars = [false, false, false, false, false];
                    this.score = '';
                    this.evaluate = '';
                    this.getEvaluateList();
                }
            },
            immediate: true, 
            deep: true,
        }
    },

    mounted() {
        this.defaults = {
            userId: this.$store.state.user.userInfo.id,
            atPersonLength: 8,
            start: 0,
            defaultTake: 5,
            requestTime: '',
            privacyTips: "只有被@的人才能看到评论",
            publicTips: "所有人能看到评论",
            placeholderText: "",
            atReg: /(@[\S\.]+)[\s]*/g,
            emailReg: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/g,

            commentType: this.commentType || 'job',
            placeholderText: this.placeholderText,
            prefetch: this.prefetch, //实例化之后是否立即取数据
            canPost: this.canPost, // 是否可以发表消息
            isTeam: this.team, //团队
            isEmail: this.isEmail,
        };
    },

    methods: {
        handlePrivacyCommand(val) {
            this.privacy = val;
        },
        getClickAtUserInfo() {
            var param = {
                searchScope: this.commentObjectInfo.isMyJob ? 1 : 0,
                showFirmTeam: this.defaults.isTeam
            };
            return _request({
                url: "/" + this.defaults.commentType + "/" + this.commentObjectInfo.id + "/Comment/AtUsers",
                method: 'GET',
                data: param
            }).then(res => {
                this.users = this.getUserData(res);
                this.userList = res;
            })
        },
        getUserData(data) {
            var arr = JSON.parse(JSON.stringify(data));
            //放开职位AT人列表展示数量限制
            // if (this.defaults.commentType === 'job' && arr.length > this.defaults.atPersonLength) {
            //     var itemsLength = arr.length;
            //     var otherUsers = arr.slice(itemsLength - 2, itemsLength);
            //     arr = arr.slice(0, this.defaults.atPersonLength - 2);
            //     arr = arr.concat(otherUsers);
            // }
            return arr;
        },
        stopEvent(event) {
            if (event.keyCode == 13) {
                event.stopImmediatePropagation();
                event.stopPropagation();
                return false;
            }
            return true;
        },
        searchUsers(event) {
            var value = $.trim($(event.currentTarget).val()),
                result = [], reg, arr = [];
            if (!value) {
                arr = this.getUserData(this.userList);
            } else {
                arr = JSON.parse(JSON.stringify(this.userList));
            }
            reg = new RegExp(value);
            this.users = [];
            for (var i = 0; i < arr.length; i++) {
                if (this.users.length >= this.defaults.atPersonLength) {
                    break;
                }
                if (reg.test(arr[i].nickname) || reg.test(arr[i].realName)) {
                    if (value) {
                        arr[i].type = 0;
                    }
                    this.users.push(arr[i]);
                }
            }
            if (event.keyCode == 13 && this.users.length == 1) {
                this.selectPerson(this.users[0], event);
            }
        },
        selectPerson(item, event) {
            var sourceValue = this.evaluate;
            this.evaluate = sourceValue + "@" + item.nickname + " ";
            this.atListPopoverVisible = false;
        },

        // 点击星星修改评价
        updateStarRating(rating) {
            // console.log(rating);
            this.score = rating;
            for (let i = 0; i < 5; i++) {
                this.stars[i] = i < rating; // 根据评分更新星星状态
            }
        },
        // 获取评分列表
        getEvaluateList() {
            this.loading = true;
            let recommendationId = this.$route.params.recommendationid;
            _request({
                url: jobUrl.recommend_evalute_list.replace(/\%p/ig, recommendationId),
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
            }).then((res) => {
                // console.log(res);
                this.evaluateList = res.list;
                this.hasEvalute = this.evaluateList.some(el => {
                    return el.posterId == this.userInfo.id;
                })
                this.loading = false;

                this.$emit('is-evalute', this.hasEvalute)
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },

        computedParse(content, type) {
            if(type == 'score') {
                return JSON.parse(content).score;
            } else {
                return JSON.parse(content).text;
            }
        },

        // 编辑
        editEvaluate(item) {
            console.log('==========================');
            console.log(item);
            this.hasEvalute = false;
            this.commentId = item.id;
            this.evaluate = JSON.parse(item.content).text;
            this.score = JSON.parse(item.content).score;
            for(let i = 0; i < this.score; i++) {
                this.stars[i] = i < this.score;
            }
            this.$refs.evaluateTextarea.focus();
        },
        // 发表评分提交
        onSubmitEvalute(commentId) {
            // console.log(commentId);
            if(!this.score) {
                shortTips('请点击匹配度评分进行评分');
                return;
            } 
            if(!this.evaluate) {
                shortTips('评价内容不能为空');
                return;
            } 
            let recommendationId = this.$route.params.recommendationid;
            let commentparam = {
                score: this.score,
                text: this.evaluate
            };
            let params = {
                comment: JSON.stringify(commentparam),
                viewLink: location.href,
                // privacy: 0,
                privacy: this.privacy,
                isEmailNotice: this.isEmailNotic,
                isSendMessageToMyself: false,
                ids: [],
                file: '',
                atUserIds: '',
                keyword: '',
                atTeamIds: '',
                otherAtNicknames: '',
            }
            console.log(params);
            // _request({
            //     url: commentId ? jobUrl.recommend_evalute_update.replace(/\%p/ig, commentId) : jobUrl.recommend_evalute_create.replace(/\%p/ig, recommendationId),
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/x-www-form-urlencoded"
            //     },
            //     data: params
            // }).then((res) => {
            //     console.log(res);
            //     shortTips('操作成功');
            //     this.hasEvalute = true;
            //     this.stars = [false, false, false, false, false];
            //     this.score = '';
            //     this.evaluate = '';
            //     this.getEvaluateList();
            // }).catch((err) => {
            //     console.log(err);
            // });
        }
    },
};
</script>

<style lang="scss" scoped>
.recomand-evaluate {
    .recomand-evaluate-title {
        margin-bottom: 15px;
        color: #444;
    }
    .recomand-evaluate-content {
        padding: 25px 0 0;
        border: 1px solid #EEEEEE;

        .evaluate-wrap {
            .score-and-tip {
                padding: 0 12px;
                font-size: 14px;
                color: #333;
                display: flex;
                align-items: center;
                .star-wrap {
                    margin: 0 15px;
                    color: #FF7200;
                    display: flex;
                    align-items: center;
                    .star-item {
                        cursor: pointer;
                        i.el-icon-star-off {
                            font-size: 20px;
                            margin: 0 4px;
                        }
                        i.el-icon-star-on {
                            font-size: 25px;
                            margin: 0 4px;
                        }
                    }
                    .score-text {
                        margin-left: 8px;
                    }
                }
                .tip-text {
                    color: #B4B4B4;
                }
            }
            .evaluate-comment {
                padding: 0 12px;
                margin-bottom: 20px;
                .atWrap {
                    position: relative;
                    border: 1px solid #eee;
                    border-radius: 4px;
                    .el-textarea .el-textarea__inner {
                        border: none;
                    }

                    .at-tools {
                        padding: 5px 20px;
                        margin: 0;
                        position: relative;
                        background-color: #fff;
                    }

                    .team-tip {
                        margin: 0 2px;
                        font-size: 12px;
                        color: #3dbaa3;
                        line-height: 20px;
                    }
                }
                .submit-button {
                    margin-top: 10px;
                    // text-align: right;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }

        .evaluate-list {
            border-bottom: 1px solid #eee;
            padding: 20px 12px;
            &:last-child {
                border-bottom: none;
            }
            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top-info {
                    display: flex;
                    align-items: center;
                    .name-and-score {
                        margin-left: 20px;
                        .name {
                            color: #333;
                        }
                        .star-wrap {
                            margin: 0;
                            color: #FF7200;
                            display: flex;
                            align-items: center;
                            .star-item {
                                display: flex;
                                align-items: center;
                                i.el-icon-star-off {
                                    font-size: 15px;
                                    margin-right: 5px;
                                }
                                i.el-icon-star-on {
                                    font-size: 20px;
                                    margin-right: 5px;
                                }
                            }
                            .score-text {
                                margin-left: 8px;
                            }
                        }
                    }
                }
                .top-time {
                    color: #B4B4B4;
                }
            }
            .bottom {
                margin-top: 20px;
                color: #666;
            }
            .edit {
                cursor: pointer;
                color: $primary;
                text-align: right;
                margin-top: 4px;
            }
        }
    }
}
</style>