var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "recomand-evaluate",
    },
    [
      _c(
        "div",
        { staticClass: "recomand-evaluate-title" },
        [
          _c("font-icon", {
            staticClass: "candidate-icon",
            attrs: { href: "#icon-wechat" },
          }),
          _vm._v("推荐评价"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "recomand-evaluate-content" },
        [
          _c("div", { staticClass: "evaluate-wrap" }, [
            _c("p", { staticClass: "score-and-tip" }, [
              _c("span", [_vm._v("匹配度评分")]),
              _c(
                "span",
                { staticClass: "star-wrap" },
                [
                  _vm._l(_vm.stars, function (star, index) {
                    return _c(
                      "span",
                      {
                        key: "star_" + index,
                        staticClass: "star-item",
                        on: {
                          click: function ($event) {
                            return _vm.updateStarRating(index + 1)
                          },
                        },
                      },
                      [
                        star
                          ? _c("i", { staticClass: "el-icon-star-on" })
                          : _c("i", { staticClass: "el-icon-star-off" }),
                      ]
                    )
                  }),
                  _vm.score
                    ? _c("span", { staticClass: "score-text" }, [
                        _vm._v(_vm._s(_vm.score) + "分"),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _vm.score
                ? _c("span", { staticClass: "tip-text" }, [
                    _vm.score == "1"
                      ? _c("i", [_vm._v("非常不匹配，不建议推荐")])
                      : _vm._e(),
                    _vm.score == "2"
                      ? _c("i", [_vm._v("匹配度较低，推荐需慎重考虑")])
                      : _vm._e(),
                    _vm.score == "3"
                      ? _c("i", [_vm._v("基本匹配，缺乏亮点")])
                      : _vm._e(),
                    _vm.score == "4"
                      ? _c("i", [_vm._v("较为匹配，有一定竞争力")])
                      : _vm._e(),
                    _vm.score == "5"
                      ? _c("i", [_vm._v("非常匹配，很建议推荐给客户")])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "evaluate-comment" }, [
              _c(
                "div",
                { staticClass: "atWrap" },
                [
                  _c("el-input", {
                    ref: "evaluateTextarea",
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请对该条推荐做出你的评价",
                      disabled: _vm.hasEvalute,
                    },
                    model: {
                      value: _vm.evaluate,
                      callback: function ($$v) {
                        _vm.evaluate = $$v
                      },
                      expression: "evaluate",
                    },
                  }),
                  _c("div", { staticClass: "at-tools clearfix" }, [
                    _c(
                      "div",
                      { staticClass: "pull-right" },
                      [
                        _c(
                          "el-dropdown",
                          { on: { command: _vm.handlePrivacyCommand } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm.privacy == 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "privacy",
                                      attrs: {
                                        id: "privacy",
                                        title: "设置私密性",
                                        href: "javascript:;",
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-eye" }, [
                                        _c("span", [_vm._v("公开")]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.privacy == 1
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "privacy",
                                      attrs: {
                                        id: "privacy",
                                        title: "设置私密性",
                                        href: "javascript:;",
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-lock" }, [
                                        _c("span", [_vm._v("私密")]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content: "所有人能看到评论",
                                      placement: "left",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "0" } },
                                      [
                                        _c("i", { staticClass: "fa fa-eye" }),
                                        _vm._v(
                                          "公开\n                                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content: "只有被@的人才能看到评论",
                                      placement: "left",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "1" } },
                                      [
                                        _c("i", { staticClass: "fa fa-lock" }),
                                        _vm._v(
                                          "私密\n                                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "pull-right" },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              title: "",
                              width: "370",
                              trigger: "click",
                            },
                            model: {
                              value: _vm.atListPopoverVisible,
                              callback: function ($$v) {
                                _vm.atListPopoverVisible = $$v
                              },
                              expression: "atListPopoverVisible",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "atList",
                                attrs: { "aria-labelledby": "atlist" },
                              },
                              [
                                _c("div", { staticClass: "search" }, [
                                  _c("input", {
                                    attrs: {
                                      autocomplete: "off",
                                      type: "text",
                                      name: "keyword",
                                      placeholder: "查找更多好友",
                                    },
                                    on: {
                                      keyup: _vm.searchUsers,
                                      keydown: _vm.stopEvent,
                                    },
                                  }),
                                ]),
                                _c(
                                  "ul",
                                  [
                                    _vm._l(_vm.users, function (user, index) {
                                      return [
                                        user.type == 1 &&
                                        index == _vm.users.length - 2
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "bazaSelectMenuTipsBox",
                                              },
                                              [_vm._v("小猎或其他用户")]
                                            )
                                          : _vm._e(),
                                        _c("li", [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectPerson(
                                                    user,
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm._f("avatar")(
                                                    user.avatar
                                                  ),
                                                  width: "16",
                                                },
                                              }),
                                              user.isFirmTeam
                                                ? _c(
                                                    "span",
                                                    { staticClass: "team-tip" },
                                                    [_vm._v("团队")]
                                                  )
                                                : _vm._e(),
                                              _c("span", {
                                                staticClass: "realName",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    user.realName
                                                  ),
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "nickname" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      user.nickname
                                                        ? `@${user.nickname}`
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "at-list-icon",
                                attrs: {
                                  slot: "reference",
                                  id: "atlist",
                                  title: "对TA说",
                                  href: "javascript:;",
                                },
                                on: { click: _vm.getClickAtUserInfo },
                                slot: "reference",
                              },
                              [_vm._v("@")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "submit-button" },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEmail,
                          expression: "isEmail",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.isEmailNotic,
                            callback: function ($$v) {
                              _vm.isEmailNotic = $$v
                            },
                            expression: "isEmailNotic",
                          },
                        },
                        [_vm._v("发送邮件通知")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.hasEvalute },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmitEvalute(_vm.commentId)
                        },
                      },
                    },
                    [_vm._v("发表")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._l(_vm.evaluateList, function (item) {
            return _c("div", { key: item.id, staticClass: "evaluate-list" }, [
              _c("div", { staticClass: "top" }, [
                _c(
                  "div",
                  { staticClass: "top-info" },
                  [
                    _c("avatar", {
                      attrs: {
                        userId: item.posterId,
                        src: item.avatarUrl,
                        enableLink: true,
                        enableName: false,
                        realName: item.posterName,
                        nickName: item.posterNickname,
                        isRecommendation: true,
                        shareData: _vm.shareData,
                        size: "mg",
                      },
                    }),
                    _c("div", { staticClass: "name-and-score" }, [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(
                          _vm._s(item.posterName) +
                            "@" +
                            _vm._s(item.posterNickname)
                        ),
                      ]),
                      _c("p", { staticClass: "star-wrap" }, [
                        _c(
                          "span",
                          { staticClass: "star-item" },
                          [
                            _vm._l(
                              _vm.computedParse(item.content, "score"),
                              function (star, index) {
                                return _c("i", {
                                  key: "starOn_" + index,
                                  staticClass: "el-icon-star-on",
                                })
                              }
                            ),
                            _vm._l(
                              5 - _vm.computedParse(item.content, "score"),
                              function (star, index) {
                                return _c("i", {
                                  key: "starOff_" + index,
                                  staticClass: "el-icon-star-off",
                                })
                              }
                            ),
                          ],
                          2
                        ),
                        _c("span", { staticClass: "score-text" }, [
                          _vm._v(
                            _vm._s(_vm.computedParse(item.content, "score")) +
                              "分"
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "top-time" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.created) +
                      "\n                "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "bottom" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.computedParse(item.content, "text")) +
                    "\n            "
                ),
              ]),
              item.posterId === _vm.userInfo.id
                ? _c(
                    "div",
                    {
                      staticClass: "edit",
                      on: {
                        click: function ($event) {
                          return _vm.editEvaluate(item)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-edit-outline" }),
                      _c("span", [_vm._v("编辑")]),
                    ]
                  )
                : _vm._e(),
            ])
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }