var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-detail-content" },
    [
      _c("div", { staticClass: "main-content" }, [
        _c(
          "div",
          { staticClass: "main-content-left" },
          [
            _c("div", { staticClass: "infos-card" }, [
              _c(
                "div",
                { staticClass: "user-pic" },
                [
                  _c("avatar", {
                    staticClass: "pic",
                    attrs: {
                      size: "sm",
                      src:
                        _vm.currentJobType == 3
                          ? _vm.jobInfo.recruiter
                            ? _vm.jobInfo.recruiter.avatarUrl + "?size=60"
                            : ""
                          : _vm.jobInfo.recruiterAvatarUrl + "?size=60",
                      userId:
                        _vm.currentJobType == 3
                          ? _vm.jobInfo.recruiter
                            ? _vm.jobInfo.recruiter.userId
                            : ""
                          : _vm.jobInfo.recruiterId,
                      enableCard: true,
                      enableLink: true,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "user-info" }, [
                _c("p", { staticClass: "name" }, [
                  _vm._v(
                    _vm._s(
                      _vm.currentJobType == 3
                        ? _vm.jobInfo.recruiter
                          ? _vm.jobInfo.recruiter.realName
                          : ""
                        : _vm.jobInfo.recruiterRealName
                    ) +
                      "@" +
                      _vm._s(
                        _vm.currentJobType == 3
                          ? _vm.jobInfo.recruiter
                            ? _vm.jobInfo.recruiter.nickname
                            : ""
                          : _vm.jobInfo.recruiterNickname
                      )
                  ),
                ]),
                _c("p", { staticClass: "firm-info" }, [
                  _vm._v(
                    _vm._s(
                      _vm.currentJobType == 3
                        ? _vm.jobInfo.recruiter
                          ? _vm.jobInfo.recruiter.firmName
                          : ""
                        : _vm.jobInfo.recruiterFirmShortName
                    ) +
                      " " +
                      _vm._s(
                        _vm.currentJobType == 3
                          ? _vm.jobInfo.recruiter
                            ? _vm.jobInfo.recruiter.title
                            : ""
                          : _vm.jobInfo.recruiterTitle
                      )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "infos-right" }, [
                !_vm.jobInfo.isMyJob
                  ? _c(
                      "button",
                      {
                        staticClass: "contact-btn",
                        on: {
                          click: function ($event) {
                            _vm.currentJobType == 3
                              ? _vm.contactRecruiter(
                                  _vm.jobInfo.recruiter.unionId
                                )
                              : _vm.contactRecruiter(
                                  _vm.jobInfo.recruiterUnionId
                                )
                          },
                        },
                      },
                      [_vm._v("联系发布者")]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c(
              "el-tabs",
              {
                staticClass: "job-detail-tabs",
                on: { "tab-click": _vm.handleJobTabClick },
                model: {
                  value: _vm.jobActiveTabName,
                  callback: function ($$v) {
                    _vm.jobActiveTabName = $$v
                  },
                  expression: "jobActiveTabName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "职位", name: "jobIntro" } },
                  [
                    _c("div", { staticClass: "main-content-item" }, [
                      _c("h5", { staticClass: "job-intro-title" }, [
                        _vm._v("职位详情："),
                      ]),
                      !_vm.jobInfo.isMyJob &&
                      _vm.jobInfo.firmLabels &&
                      _vm.jobInfo.firmLabels.length > 0 &&
                      _vm.currentJobType != 3
                        ? _c(
                            "div",
                            { staticClass: "firm-label-list" },
                            _vm._l(
                              _vm.jobInfo.firmLabels,
                              function (label, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "firm-label-item",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(label) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      !_vm.isEditJobDescription
                        ? _c("pre", {
                            staticClass: "job-description-show",
                            domProps: {
                              textContent: _vm._s(_vm.jobInfo.description),
                            },
                          })
                        : _c(
                            "div",
                            { staticClass: "job-description-edit" },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.jobDescriptionLoading,
                                    expression: "jobDescriptionLoading",
                                  },
                                ],
                                class: _vm.descriptionError
                                  ? "error-input"
                                  : "",
                                attrs: {
                                  rows: 8,
                                  type: "textarea",
                                  maxlength: "8000",
                                  placeholder:
                                    "请输入8000字内的职位描述，寻访要点，职位卖点等信息",
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.jobDescriptionInput.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.descriptionInput,
                                  callback: function ($$v) {
                                    _vm.descriptionInput = $$v
                                  },
                                  expression: "descriptionInput",
                                },
                              }),
                              _vm.descriptionError
                                ? _c("div", { staticClass: "text-error" }, [
                                    _vm._v(
                                      "\n                                请填写职位要点\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  staticClass: "edit-btn edit-btn-confirm",
                                  on: { click: _vm.handleEditJobDescription },
                                },
                                [_vm._v("保存")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "edit-btn edit-btn-cancel",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelEditJobDetail(0)
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          ),
                      (_vm.jobInfo.isMyJob ||
                        _vm.jobInfo.isMyOrder ||
                        (_vm.jobInfo.isProjectManagerOfJob &&
                          _vm.currentJobType != 3)) &&
                      _vm.approvedStatus != 2 &&
                      _vm.jobInfo.status != 4
                        ? _c("div", { staticClass: "job-share-board" }, [
                            _c("p", { staticClass: "job-share-board-msg" }, [
                              _vm._v(
                                "\n                                手里有合适的候选人？试试“"
                              ),
                              _c("span", { staticClass: "text-primary" }, [
                                _vm._v("快捷发邮件"),
                              ]),
                              _vm._v(
                                "”，把职位详情一键发给 ta 吧！\n                            "
                              ),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "job-share-board-btn",
                                on: { click: _vm.showShareToCandidateDialog },
                              },
                              [
                                _c("font-icon", {
                                  staticClass: "email-icon",
                                  attrs: { href: "#icon-email-s" },
                                }),
                                _vm._v(
                                  "\n                                分享给候选人\n                            "
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    ![3, 5].includes(_vm.currentJobType)
                      ? _c("div", { staticClass: "main-content-item" }, [
                          _c("h5", { staticClass: "job-intro-title" }, [
                            _vm._v(
                              "\n                            职位分析：\n                            "
                            ),
                            !(
                              _vm.jobInfo.jobType == 2 &&
                              _vm.jobInfo.isPublic == false
                            ) &&
                            _vm.jobInfo.acceptedHeadhuntersCount != 0 &&
                            _vm.canEdit
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "text-operate-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.partlyEdit(1)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "job-detail-btn-icon edit-icon el-icon-edit-outline",
                                    }),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.isEditJobAnalysis
                                            ? "取消"
                                            : "编辑"
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm.jobInfo.isCooperativeJob && _vm.jobInfo.isMyJob
                            ? _c("div", { staticClass: "job-share-board" }, [
                                _c(
                                  "p",
                                  { staticClass: "job-share-board-msg" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-primary font-bold" },
                                      [_vm._v("【本职位由我公司发布】")]
                                    ),
                                    _vm._v(
                                      "\n                                内部佣金：" +
                                        _vm._s(
                                          _vm.jobInfo.internalCommissionValue
                                        ) +
                                        "，保证期：" +
                                        _vm._s(
                                          _vm.jobInfo.internalGuaranteePeriod
                                        ) +
                                        "，内部退款条款按客户合同约定。\n                            "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          !_vm.isEditJobAnalysis
                            ? _c("pre", {
                                staticClass: "job-description-show",
                                domProps: {
                                  textContent: _vm._s(_vm.jobInfo.analysis),
                                },
                              })
                            : _c(
                                "div",
                                { staticClass: "job-description-edit" },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.jobAnalysisLoading,
                                        expression: "jobAnalysisLoading",
                                      },
                                    ],
                                    attrs: {
                                      rows: 8,
                                      type: "textarea",
                                      maxlength: "2000",
                                      placeholder: "请输入2000字内的职位分析",
                                    },
                                    nativeOn: {
                                      input: function ($event) {
                                        return _vm.jobAnalysisInput.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.analysisInput,
                                      callback: function ($$v) {
                                        _vm.analysisInput = $$v
                                      },
                                      expression: "analysisInput",
                                    },
                                  }),
                                  _vm.analysisError
                                    ? _c("div", { staticClass: "text-error" }, [
                                        _vm._v(
                                          "\n                                请填写职位分析\n                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "edit-btn edit-btn-confirm",
                                      on: { click: _vm.handleEditJobAnalysis },
                                    },
                                    [_vm._v("保存")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "edit-btn edit-btn-cancel",
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelEditJobDetail(1)
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                ],
                                1
                              ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "main-content-item" }, [
                      _c("h5", { staticClass: "job-intro-title" }, [
                        _vm._v(
                          "\n                            工作地址：\n                        "
                        ),
                      ]),
                      _c("pre", {
                        staticClass: "job-description-show",
                        domProps: {
                          textContent: _vm._s(_vm.jobInfo.address || "暂无"),
                        },
                      }),
                    ]),
                    ![3, 5].includes(_vm.currentJobType)
                      ? _c("div", { staticClass: "main-content-item" }, [
                          _c(
                            "h5",
                            { staticClass: "job-intro-title" },
                            [
                              _vm.jobInfo.acceptedHeadhuntersCount > 0
                                ? [
                                    _c(
                                      "span",
                                      { staticClass: "text-primary" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.jobInfo.acceptedHeadhuntersCount
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                位猎头抢单：\n                            "
                                    ),
                                  ]
                                : [_vm._v(" 还没有猎头抢单 ")],
                              _vm.jobInfo.isMyJob
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "invite-grab-btn",
                                      on: {
                                        click: _vm.showInviteHeadhunterDialog,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                邀请抢单\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.jobInfo.acceptedHeadhuntersCount > 0
                            ? _c(
                                "div",
                                { staticClass: "accept-headhunters" },
                                _vm._l(
                                  _vm.jobInfo.acceptedHeadhunters,
                                  function (item) {
                                    return _c("avatar", {
                                      key: item.id,
                                      staticClass:
                                        "accepted-headhunters-avatar",
                                      attrs: {
                                        size: "md",
                                        src: item.avatarUrl,
                                        userId: item.id,
                                        enableCard: true,
                                        enableLink: true,
                                        shareData: _vm.shareData,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm.jobInfo.isMyJob
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "accept-headhunters empty invite-headhunters-btn",
                                  on: { click: _vm.showInviteHeadhunterDialog },
                                },
                                [
                                  _vm._v(
                                    "\n                            您可以邀请您的猎头朋友来抢单推荐，赚取佣金\n                        "
                                  ),
                                ]
                              )
                            : _vm.isHeadhunter
                            ? _c(
                                "p",
                                { staticClass: "accept-headhunters empty" },
                                [
                                  _vm._v(
                                    "\n                            想挑战本职位吗，赶快抢单吧！\n                        "
                                  ),
                                ]
                              )
                            : _c(
                                "p",
                                { staticClass: "accept-headhunters empty" },
                                [
                                  _vm._v(
                                    "\n                            还没有猎头抢单\n                        "
                                  ),
                                ]
                              ),
                        ])
                      : _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.acceptedHeadhuntersLoading,
                                expression: "acceptedHeadhuntersLoading",
                              },
                            ],
                            staticClass: "main-content-item",
                          },
                          [
                            _c(
                              "h5",
                              { staticClass: "job-intro-title" },
                              [
                                _vm.acceptedHeadhuntersCount > 0
                                  ? [
                                      _c(
                                        "span",
                                        { staticClass: "text-primary" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.acceptedHeadhuntersCount)
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                                位猎头抢单\n                            "
                                      ),
                                    ]
                                  : [_vm._v(" 还没有猎头抢单 ")],
                                _vm.jobInfo.isMyJob
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "text-operate-btn",
                                        on: {
                                          click: _vm.showInviteHeadhunterDialog,
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "detail-header-btn-icon el-icon-plus",
                                        }),
                                        _vm._v(
                                          "\n                                邀请抢单\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm.acceptedHeadhuntersCount > 0
                              ? _c(
                                  "div",
                                  { staticClass: "accept-headhunters" },
                                  _vm._l(
                                    _vm.acceptedHeadhunters,
                                    function (item) {
                                      return _c("avatar", {
                                        key: item.id,
                                        staticClass:
                                          "accepted-headhunters-avatar",
                                        attrs: {
                                          size: "md",
                                          src: item.avatarUrl,
                                          userId: item.id,
                                          enableCard: true,
                                          enableLink: true,
                                          shareData: _vm.shareData,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm.jobInfo.isMyJob
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "accept-headhunters empty invite-headhunters-btn",
                                    on: {
                                      click: _vm.showInviteHeadhunterDialog,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            您可以邀请您的猎头朋友来抢单推荐，赚取佣金\n                        "
                                    ),
                                  ]
                                )
                              : _vm.isHeadhunter
                              ? _c(
                                  "p",
                                  { staticClass: "accept-headhunters empty" },
                                  [
                                    _vm._v(
                                      "\n                            想挑战本职位吗，赶快抢单吧！\n                        "
                                    ),
                                  ]
                                )
                              : _c(
                                  "p",
                                  { staticClass: "accept-headhunters empty" },
                                  [
                                    _vm._v(
                                      "\n                            还没有猎头抢单\n                        "
                                    ),
                                  ]
                                ),
                          ]
                        ),
                  ]
                ),
                _vm.approvedStatus != 2
                  ? _c(
                      "el-tab-pane",
                      { attrs: { name: "recommend" } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(
                              " 推荐(" +
                                _vm._s(_vm.jobInfo.recommendationCount) +
                                ") "
                            ),
                          ]
                        ),
                        _c("job-recommend-info", {
                          ref: "jobRecommendInfo",
                          attrs: {
                            id: "job-recommend-info",
                            shareData: _vm.shareData,
                            jobInfo: _vm.jobInfo,
                          },
                          on: {
                            updateRecommendCount: _vm.updateRecommendCount,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.canMediaList &&
                ![3, 5].includes(_vm.currentJobType) &&
                !["2", "4"].includes(_vm.approvedStatus)
                  ? _c("el-tab-pane", { attrs: { name: "jobMedia" } }, [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v(
                          "\n                        讲解(" +
                            _vm._s(_vm.jobInfo.mediaNumber || 0) +
                            ")\n                        "
                        ),
                        !_vm.hasNewMediaViewed
                          ? _c("span", { staticClass: "new-video-tip" })
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.jobMediaLoading,
                              expression: "jobMediaLoading",
                            },
                          ],
                          staticClass: "job-video-list",
                        },
                        [
                          _vm._l(_vm.jobMediaList, function (item, index) {
                            return [
                              _c("job-media-item", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.enableShow,
                                    expression: "item.enableShow",
                                  },
                                ],
                                key: index,
                                ref: `jobMedia_${item.id}`,
                                refInFor: true,
                                attrs: {
                                  item: item,
                                  shareData: _vm.shareData,
                                  canDelete: _vm.canMediaDelete,
                                  index: index,
                                },
                                on: {
                                  "delete-media-item": _vm.deleteMediaItem,
                                },
                              }),
                            ]
                          }),
                          !_vm.isJobMediaCompleted &&
                          _vm.jobMediaList.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "show-more-job-video",
                                  on: {
                                    click: function ($event) {
                                      return _vm.getJobMedia(true)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            加载更多\n                            "
                                  ),
                                  _c("font-icon", {
                                    staticClass: "get-more-icon",
                                    attrs: { href: "#icon-spread-up-copy" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isJobMediaCompleted &&
                          _vm.jobMediaList.length === 0
                            ? _c("div", { staticClass: "empty-media-list" }, [
                                _c("span", { staticClass: "empty-img" }),
                                _c("p", { staticClass: "empty-data" }, [
                                  _vm._v("暂无数据"),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                !_vm.isFromAuthorization &&
                ![3, 5].includes(_vm.currentJobType) &&
                !["2", "4"].includes(_vm.approvedStatus) &&
                !_vm.jobInfo.isSleeping
                  ? _c("el-tab-pane", { attrs: { name: "jobComments" } }, [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v(" 评论(" + _vm._s(_vm.commentCount) + ") "),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "main-content-item",
                          staticStyle: { "margin-top": "20px" },
                        },
                        [
                          _c("comments", {
                            ref: "comments",
                            staticClass: "card",
                            attrs: {
                              commentType: "job",
                              placeholderText:
                                "补充职位要点，回复猎头咨询。还可以@他人昵称试试",
                              commentObjectInfo: _vm.jobInfo,
                              isEmail: true,
                              team: true,
                              prefetch: true,
                              shareData: _vm.shareData,
                              "max-limit": 1,
                            },
                            on: { updateCommentCount: _vm.updateCommentCount },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                !_vm.isFromAuthorization && _vm.jobActiveTabName === "jobIntro"
                  ? _c("span", { staticClass: "button-list" }, [
                      _vm.canEdit
                        ? _c(
                            "button",
                            {
                              staticClass: "job-detail-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.partlyEdit(0)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "job-detail-btn-icon edit-icon el-icon-edit-outline",
                              }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.isEditJobDescription ? "取消" : "编辑"
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "main-content-right" }, [
          _c("div", { staticClass: "content-right-top" }, [
            _c("span", { staticClass: "tit" }, [_vm._v("最新动态")]),
            _c("div", { staticClass: "header-tabs" }, [
              _c(
                "span",
                {
                  staticClass: "tab-item",
                  class: _vm.feedTab == 0 ? "activite" : "",
                  on: {
                    click: function ($event) {
                      return _vm.feedActivite(0)
                    },
                  },
                },
                [_vm._v("全部")]
              ),
              _c(
                "span",
                {
                  staticClass: "tab-item",
                  class: _vm.feedTab == 3 ? "activite" : "",
                  on: {
                    click: function ($event) {
                      return _vm.feedActivite(3)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _c(
                "span",
                {
                  staticClass: "tab-item",
                  class: _vm.feedTab == 8 ? "activite" : "",
                  on: {
                    click: function ($event) {
                      return _vm.feedActivite(8)
                    },
                  },
                },
                [_vm._v("面试")]
              ),
              _c(
                "span",
                {
                  staticClass: "tab-item",
                  class: _vm.feedTab == 11 ? "activite" : "",
                  on: {
                    click: function ($event) {
                      return _vm.feedActivite(11)
                    },
                  },
                },
                [_vm._v("淘汰")]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.feedLoading,
                  expression: "feedLoading",
                },
              ],
              staticClass: "feed-list",
            },
            [
              _vm.lastestFeeds.length > 0
                ? _c(
                    "ul",
                    [
                      _vm._l(_vm.lastestFeeds, function (feed, index) {
                        return [
                          feed
                            ? _c(
                                "li",
                                { key: index, staticClass: "feed-item" },
                                [
                                  _c("avatar", {
                                    staticClass: "post-avatar",
                                    attrs: {
                                      size: "md",
                                      src: feed.posterAvatarUrl,
                                      userId: feed.posterId,
                                      enableCard: true,
                                      enableLink: true,
                                      shareData: _vm.shareData,
                                    },
                                  }),
                                  _c("div", { staticClass: "right" }, [
                                    _c("div", { staticClass: "right-top" }, [
                                      feed.posterRealName == "系统内置用户"
                                        ? _c(
                                            "span",
                                            { staticClass: "post-name" },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(feed.posterRealName) +
                                                  "\n                                "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass: "post-name",
                                              attrs: {
                                                title:
                                                  feed.posterRealName +
                                                  "@" +
                                                  feed.posterNickName,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(feed.posterRealName) +
                                                  "\n                                    "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "text-grey" },
                                                [
                                                  _vm._v(
                                                    "@" +
                                                      _vm._s(
                                                        feed.posterNickName
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                      _c("span", { staticClass: "feed-time" }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm._f("dateFilter")(
                                                feed.createdString
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                    ]),
                                    _c("div", {
                                      staticClass: "right-bottom",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getFeedText(feed)
                                        ),
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("div", { staticClass: "page-empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@src/assets/images/jobList/empty-bg@2x.png"),
                      },
                    }),
                    _c("p", { staticClass: "des" }, [_vm._v("暂无数据")]),
                  ]),
            ]
          ),
          _vm.hasMoreFeed
            ? _c(
                "div",
                {
                  staticClass: "get-more",
                  on: {
                    click: function ($event) {
                      return _vm.getFeeds(true)
                    },
                  },
                },
                [
                  _vm._v("\n                加载更多\n                "),
                  _c("font-icon", {
                    staticClass: "get-more-icon",
                    attrs: { href: "#icon-spread-up-copy" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("transfer-job-dialog", {
        ref: "transferJobDialog",
        attrs: { isAllMembers: true, isAdminTransfer: true },
        on: { "update-list": _vm.refresh },
      }),
      _c("share-to-candidate-dialog", {
        ref: "shareToCandidateDialog",
        attrs: { jobInfo: _vm.jobInfo },
      }),
      _c("invite-headhunter-dialog", {
        ref: "inviteHeadhunterDialog",
        attrs: { jobInfo: _vm.jobInfo, isHeadhunter: _vm.isHeadhunter },
      }),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.hrContactLoading,
              expression: "hrContactLoading",
            },
          ],
          attrs: {
            title: "联系发布者",
            visible: _vm.dialogVisible,
            width: "480px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "contact-publisher-content" }, [
            _c("span", [_vm._v("联系电话：" + _vm._s(_vm.mobile))]),
            _c("div", { staticClass: "QR-code" }, [
              _c("img", { attrs: { src: _vm.wechatQRCodeUrl, alt: "" } }),
            ]),
            _c("span", [_vm._v("扫码添加微信")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }