var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c("div", { staticClass: "job-recommend-detail" }, [
        _c("div", { staticClass: "top-wrap" }, [
          _c("div", { staticClass: "jr-top" }, [
            _c(
              "button",
              {
                staticClass: "tab-btn",
                class: { active: _vm.currentTab == "baseInfo" },
                on: {
                  click: function ($event) {
                    return _vm.handleTopTap("baseInfo")
                  },
                },
              },
              [_vm._v("简历基本信息")]
            ),
            _c(
              "button",
              {
                staticClass: "tab-btn",
                class: { active: _vm.currentTab == "currentStatus" },
                on: {
                  click: function ($event) {
                    return _vm.handleTopTap("currentStatus")
                  },
                },
              },
              [_vm._v("当前状态")]
            ),
            _c(
              "button",
              {
                staticClass: "tab-btn",
                class: { active: _vm.currentTab == "recommendEvaluate" },
                on: {
                  click: function ($event) {
                    return _vm.handleTopTap("recommendEvaluate")
                  },
                },
              },
              [_vm._v("推荐评价")]
            ),
            _c(
              "button",
              {
                staticClass: "tab-btn",
                class: { active: _vm.currentTab == "timeline" },
                on: {
                  click: function ($event) {
                    return _vm.handleTopTap("timeline")
                  },
                },
              },
              [_vm._v("时间线")]
            ),
          ]),
          _c("div", { staticClass: "user-infos", attrs: { id: "baseInfo" } }, [
            _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "ir-l" }, [
                _c(
                  "span",
                  { staticClass: "pic" },
                  [
                    _c("avatar", {
                      attrs: {
                        userId: _vm.callee.id
                          ? _vm.callee.id
                          : _vm.defaultCreatorId,
                        src: _vm.callee.avatarUrl
                          ? _vm.callee.avatarUrl
                          : _vm.formatAvatarUrl(
                              `/Data/Avatar/${_vm.callee.avatarId}`
                            ),
                        enableLink: true,
                        enableName: true,
                        isRecommendation: true,
                        shareData: _vm.shareData,
                        size: "sm",
                      },
                    }),
                  ],
                  1
                ),
                _c("span", { staticClass: "name" }, [
                  _vm._v(
                    _vm._s(_vm.callee.name) + "\n                            "
                  ),
                  _c("span", { staticClass: "nick" }, [
                    _vm._v(" " + _vm._s(_vm.callee.nickname)),
                  ]),
                ]),
                _c("span", { staticClass: "spacer" }),
                _c("span", { staticClass: "time" }, [
                  _vm._v(
                    "推荐时间：" +
                      _vm._s(_vm._f("dateFilter")(_vm.recommendDate))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "ir-r" }, [
                _c(
                  "span",
                  {
                    staticClass: "back",
                    on: { click: _vm.handleRouterNavigate },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "back-icon" },
                      [_c("font-icon", { attrs: { href: "#icon-chexiao" } })],
                      1
                    ),
                    _vm._v(
                      "\n                            返回全部推荐\n                        "
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasRecommendAttachment,
                    expression: "hasRecommendAttachment",
                  },
                ],
                staticClass: "recommend-attachment",
              },
              [
                _c("attachment-list", {
                  ref: "attachmentList",
                  attrs: {
                    recommendationId: _vm.recommendationId,
                    candidateName: _vm.shareData
                      ? _vm.shareData.candidateName
                      : "",
                    torecommends: false,
                    type: "recommend",
                  },
                  on: { "has-attachment": _vm.showRecommendAttachment },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "blank-block" }, [
          _c("div", { staticClass: "tit-cell" }, [
            _c("span", { staticClass: "tit" }, [_vm._v("简历详情")]),
            _c("span", { staticClass: "line" }),
            _c(
              "span",
              { staticClass: "expand", on: { click: _vm.handleToggleFold } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.unFold ? "收起全部" : "展开全部") +
                    "\n                    "
                ),
                _c(
                  "span",
                  { staticClass: "expand-icon" },
                  [_c("font-icon", { attrs: { href: "#icon-right-copy" } })],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("resume-info", {
                ref: "resumeInfo",
                attrs: { "resume-id": _vm.resumeId },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "blank-block", attrs: { id: "currentStatus" } },
          [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("recommend-process", {
                  ref: "recommendProcess",
                  staticClass: "card",
                  attrs: {
                    recommendationId: _vm.recommendationId,
                    isShowStep: true,
                  },
                  on: {
                    "recommend-process-success": _vm.handleProcessSuccess,
                    timelineRefresh: _vm.timelineRefresh,
                    "comment-at-and-tip": _vm.AtandTip,
                    "similar-resume": _vm.handelSimilarResume,
                    transfer: _vm.handleEmailShare,
                    successEditOffer: _vm.offerEditSuccess,
                    attachmentRefresh: _vm.attachmentRefresh,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "blank-block", attrs: { id: "recommendEvaluate" } },
          [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("recomand-comment", {
                  ref: "recomandComment",
                  staticClass: "card",
                  attrs: {
                    commentType: "job",
                    placeholderText: "请对该条推荐做出你的评价",
                    commentObjectInfo: _vm.jobInfo,
                    isEmail: true,
                    team: true,
                    prefetch: true,
                    shareData: _vm.shareData,
                    canEvalute: _vm.canEvalute,
                  },
                  on: { "is-evalute": _vm.isEvalute },
                }),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "blank-block", attrs: { id: "timeline" } }, [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("candidate-recommendation-timeline", {
                ref: "candidateRecommendationTimeline",
                attrs: {
                  recommendationId: _vm.recommendationId,
                  isPublisher: _vm.isPublisher,
                },
              }),
            ],
            1
          ),
        ]),
        false
          ? _c("div", { staticClass: "job-recommend-left" }, [
              _c("div", { staticClass: "menu-panel" }, [
                _c("ul", { staticClass: "menu-resume" }, [
                  _c(
                    "li",
                    {
                      class: { active: _vm.currentActive == "status-process" },
                      on: {
                        click: function ($event) {
                          return _vm.selectMenu("status-process")
                        },
                      },
                    },
                    [
                      _c("font-icon", { attrs: { href: "#icon-job-statues" } }),
                      _c("span", { staticClass: "menu-text" }, [
                        _vm._v("当前状态"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.currentActive == "resume-info" },
                      on: {
                        click: function ($event) {
                          return _vm.selectMenu("resume-info")
                        },
                      },
                    },
                    [
                      _c("font-icon", { attrs: { href: "#icon-baocun" } }),
                      _c("span", { staticClass: "menu-text" }, [
                        _vm._v("简历详情"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      class: {
                        active: _vm.currentActive == "recomand-evalute",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectMenu("recomand-evalute")
                        },
                      },
                    },
                    [
                      _c("font-icon", { attrs: { href: "#icon-wechat" } }),
                      _c("span", { staticStyle: { position: "relative" } }, [
                        _c("span", { staticClass: "menu-text" }, [
                          _vm._v("推荐评价"),
                        ]),
                        _vm.canEvalute && !_vm.hasEvalute
                          ? _c("i", { staticClass: "dot" })
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.currentActive == "time-line" },
                      on: {
                        click: function ($event) {
                          return _vm.selectMenu("time-line")
                        },
                      },
                    },
                    [
                      _c("font-icon", {
                        attrs: { href: "#icon-job-timeline" },
                      }),
                      _c("span", { staticClass: "menu-text" }, [
                        _vm._v("时间线"),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        false
          ? _c("div", { staticClass: "job-recommend-right" }, [
              _c("div", { staticClass: "job-recommend-header" }, [
                _c("span", { staticClass: "title" }, [
                  _c("a", { on: { click: _vm.handleRouterNavigate } }, [
                    _c("i", { staticClass: "el-icon-d-arrow-left" }),
                    _c("span", { staticClass: "title-font-color" }, [
                      _vm._v("全部推荐记录"),
                    ]),
                  ]),
                ]),
                _c(
                  "span",
                  { staticClass: "recommend-user recommend-user-overflow" },
                  [
                    _c("avatar", {
                      attrs: {
                        userId: _vm.callee.id
                          ? _vm.callee.id
                          : _vm.defaultCreatorId,
                        src: _vm.callee.avatarUrl
                          ? _vm.callee.avatarUrl
                          : _vm.formatAvatarUrl(
                              `/Data/Avatar/${_vm.callee.avatarId}`
                            ),
                        enableLink: true,
                        enableName: true,
                        realName: _vm.callee.name,
                        nickName: _vm.callee.nickname,
                        isRecommendation: true,
                        shareData: _vm.shareData,
                        size: "sm",
                      },
                    }),
                  ],
                  1
                ),
                _c("span", { staticClass: "recommend-time" }, [
                  _vm._v(_vm._s(_vm._f("dateFilter")(_vm.recommendDate))),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasRecommendAttachment,
                      expression: "hasRecommendAttachment",
                    },
                  ],
                  staticClass: "recommend-attachment",
                },
                [
                  _c("span", { staticClass: "recommend-attachment-title" }, [
                    _vm._v(
                      "\n                    推荐上传附件：\n                "
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "recommendation-detail-content" }),
            ])
          : _vm._e(),
      ]),
      _c("candidate-recommendation-similar-resume", {
        ref: "candidateRecommendationSimilarResume",
      }),
      _c("candidate-email-share", {
        ref: "candidateEmailShare",
        attrs: { recommendationId: _vm.recommendationId },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit-cell" }, [
      _c("span", { staticClass: "tit" }, [_vm._v("当前状态")]),
      _c("span", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit-cell" }, [
      _c("span", { staticClass: "tit" }, [_vm._v("推荐评价")]),
      _c("span", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit-cell" }, [
      _c("span", { staticClass: "tit" }, [_vm._v("时间线")]),
      _c("span", { staticClass: "line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }