var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-recommend-info" },
    [
      _c("job-recommend-record", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.viewType == "list",
            expression: "viewType == 'list'",
          },
        ],
        ref: "jobRecommendRecord",
        attrs: { shareData: _vm.shareData },
        on: {
          "update-state": _vm.toggleState,
          updateRecommendCount: _vm.updateRecommendCount,
        },
      }),
      _c("job-recommend-detail", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.viewType == "detail",
            expression: "viewType == 'detail'",
          },
        ],
        ref: "jobRecommendDetail",
        attrs: {
          shareData: _vm.shareData,
          jobInfo: _vm.jobInfo,
          recommendation: _vm.recommendation,
        },
        on: { "update-state": _vm.toggleState },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }