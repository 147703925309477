<template>
    <div class="job-recommend-info">
        <job-recommend-record
            v-show="viewType == 'list'"
            ref="jobRecommendRecord"
            :shareData="shareData"
            @update-state="toggleState"
            @updateRecommendCount="updateRecommendCount"
        ></job-recommend-record>
        <job-recommend-detail
            v-show="viewType == 'detail'"
            ref="jobRecommendDetail"
            :shareData="shareData"
            :jobInfo="jobInfo"
            :recommendation="recommendation"
            @update-state="toggleState"
        ></job-recommend-detail>
    </div>
</template>

<script>
import JobRecommendRecord from './jobRecommendRecord.vue';
import JobRecommendDetail from './jobRecommendDetail.vue';

// TODO 如何减少提前变量的定义
let defaultRecomendationKeys = ["jobId", "jobName", "firmName", "recommendStatus", "headhunterId", "headhunterAvatarUrl", "headhunterRealName", "headhunterNickname", "recommendDate", "salary", "commisionValue", "guaranteePeriod", "location", "isFromCloud", "orderAmount", "isCooperativeJob", "isCooperativeRecommendation", "recommendFirmLogoUrl", "employerLogoUrl", "isProjectManagerOfJob", "hasProjectManager", "recommendationUrl", "recruitingCount", "id", "candidateId", "realName", "recommendationCount", "recommendStatusStatistic"];
let defaultRecomendationObj = {};
defaultRecomendationKeys.forEach(key => {
    defaultRecomendationObj[key] = '';
})
defaultRecomendationObj.publisherInfo = {
    avatarUrl: '',
    firmId: '',
    firmShortName: '',
    id: '',
    mobile: '',
    name: '',
    nickname: '',
    postDate: '',
    recommendReason: '',
    title: ''
}

export default {
    components: {
        JobRecommendRecord,
        JobRecommendDetail,
    },
    props: {
        jobInfo: Object,
        shareData: Object
    },
    data() {
        return {
            viewType: '',
            recommendation: Object.assign({}, defaultRecomendationObj),
        };
    },

    mounted() {

    },

    methods: {
        toggleState(type){
            this.viewType = type; //type: list, detail
            if(type == 'list') {
                let jobId = this.$route.params.jobId;
                let rootURL = '#/Job/' + jobId + '/recommendationList';
                location.href = rootURL;
            } else if(type == 'detail') {
                this.$nextTick(() => {
                    this.$refs.jobRecommendDetail.getProcess();
                })
            }
        },
        updateRecommendCount(count) {
            this.$emit('updateRecommendCount', count);
        }
    },
};
</script>

<style lang="scss" scoped>
.job-recommend-info {

}
</style>